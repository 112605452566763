import React, {useContext, useState} from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import Navbar from "../shared/Navbar"

import bgImg from "../../media/home/main-bg.svg"
import img from "../../media/home/tilted-home-main-image.png"
import {IslandContext} from "../../hooks/wrappers/IslandWrapper";


const  MainSection = () => {
  const { t } = useTranslation();
  const history = useHistory()
  const { island: currIsland, islands, changeIsland } = useContext(IslandContext)

  const [values, setValues] = useState({
    island: currIsland || "",
    city: ""
  })
  const { island, city } = values

  const onSubmit = (e) => {
    e.preventDefault();
    const { island, city } = values

    const path = `/rentals${city ? `?city=${city}` :  ""}`
    if(island !== (currIsland || "")) changeIsland(island, path)
    else history.push(path)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues(values => ({
      ...values,
      [name]: value
    }))
  }

  return (
    <Container fluid className="pb-5 mb-8 mb-lg-0 px-0" style={styles.bg}>
      <Navbar className="mainSection" hideIslandsLogos fixTop />
      <Row className="pt-5 pt-lg-3 mx-auto" style={{maxWidth: 1920}}>

        <Col xs="12" lg="6" className="p-0 mb-n9 mb-lg-0 order-last order-lg-first flex-center">
          <img src={img} width="100%" alt="terrace" title="terrace"/>
        </Col>

        <Col xs="12" lg="6" className="flex-center flex-column mx-auto pl-lg-4 pr-lg-5 mb-5 mb-lg-0 py-5" style={{maxWidth: 900}}>
          <h1 className="text-white text-uppercase text-center mb-5" style={{fontSize: '2.8rem'}}>
            {t("All-inclusive monthly rentals")}
          </h1>
          <form onSubmit={onSubmit} className="px-3 w-100">
            <CustomIslandSelect
              value={island}
              islands={islands}
              handleChange={handleInputChange}
              t={t}
            />
            <CustomCityInput
              value={city}
              handleChange={handleInputChange}
              t={t}
            />
            <Button
              type="submit"
              color="primary"
              className="mt-2 py-2"
              style={styles.inputBox}
            >
              {t("Search")}
            </Button>
          </form>
        </Col>

      </Row>
    </Container>
  );
}

const CustomIslandSelect = ({ value, islands, handleChange, t }) =>
  <Input
    type="select"
    name="island"
    value={value}
    onChange={handleChange}
    style={styles.inputBox}
    className="mb-4"
  >
    <option value="">{t("All islands")}</option>
    {islands.map((island, i) =>
      <option key={i} value={island}>
        {t(island.charAt(0).toUpperCase() + island.slice(1))}
      </option>)
    }
  </Input>

const CustomCityInput = ({ value, handleChange, t }) =>
  <Input
    type="text"
    name="city"
    className="mb-4"
    style={styles.inputBox}
    value={value}
    onChange={handleChange}
    placeholder={t("Search by location")}
  />




const styles = {
  bg: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right'
  },
  inputBox: {
    cursor: 'pointer',
    borderRadius: 9999,
    width: '100%',
    border: 'none',
    fontSize: '1.2rem',
    padding: '0 1rem'
  }
}

export default MainSection;
