import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";
import * as actions from "actions";
import BwmInput from "../shared/form/BwmInput";
import { withTranslation  } from "react-i18next";
import { Redirect } from "react-router-dom";

class RestorePassw extends PureComponent {

  state = {
    newPass: "",
    newPassConf: "",
    token: ""
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  componentWillMount() {
    const { location } = this.props;
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    const token = searchParams.get("token");
    this.setState({ token });
  }

  submit = (event) => {
    event.preventDefault();
    const { t, history } = this.props;
    const { newPass, newPassConf, token } = this.state;
    if (newPassConf === newPass) {
      actions.changePassword(newPass, token).then(() => {
        history.push("/logout");
        alert(t("your password has been successfully changed"))
      })
    } else {
      alert(t("password does not match"))
    }
  };

  render() {
    const { newPass, newPassConf, token } = this.state;
    console.log(token)
    if (token.length <= 0) {
      return <Redirect to="/"/>
    }
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-5">
          <form onSubmit={this.submit}>
            <BwmInput
              input={{
                value: newPass,
                onChange: this.handleChange,
                name: "newPass",
                required: true
              }}
              type="password"
              label={"Password"}
              className="form-control"
            />
            <BwmInput
              input={{
                value: newPassConf,
                onChange: this.handleChange,
                name: "newPassConf",
                required: true
              }}
              type="password"
              label={"Password Confirm"}
              className="form-control"
            />
            <button className="btn btn-primary mt-2 px-5">Change</button>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation ()(withRouter(RestorePassw));
