import React from "react";
import { Container, Row, Col } from "reactstrap";

import separatorV from "../../media/home/line-separator-v.svg"
import separatorH from "../../media/home/line-separator-h.svg"


const UserTypes = ({first, second, flipSeparators}) => {
  return (
    <Container fluid className="py-5 position-relative overflow-hidden">

      <img
        src={separatorV}
        className="position-absolute h-100 d-none d-xl-block"
        style={{right: '50%', top: 0, transform: `translateX(50%) ${flipSeparators ? 'scaleX(-1)' : ''}`}}
        alt=""
      />

      <Row className="px-3 px-md-8 py-lg-5 justify-content-around flex-xl-nowrap" style={{fontSize: '1.4rem', lineHeight: 1.1}}>

        <Col xs="12" xl="6" className="px-3 pr-xl-7 py-8" style={{maxWidth: 800}}>
          {first}
        </Col>

        <Col xs="12" className="d-xl-none my-5">
          <img
            src={separatorH}
            className="position-absolute"
            style={{ minWidth: 1920, width: '150vw', left: 0, transform: `translate(-25%, -50%) ${!flipSeparators ? 'scaleX(-1)' : ''}`}}
            alt=""
          />
        </Col>

        <Col xs="12" xl="6" className="px-3 pl-xl-7 py-8" style={{maxWidth: 800}}>
          {second}
        </Col>

      </Row>
    </Container>
  );
}


export default UserTypes;
