

const getRentalDescription = (description, lang) => {
  if(description && typeof description === 'object') {
    if(description?.[lang]) return description?.[lang]
    else {
      const keys = Object.keys(description)
      if(keys.length <= 0) return ""
      else return description[Object.keys(description)[0]]
    }
  } else return description
}

export default getRentalDescription
