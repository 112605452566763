import React, {useMemo} from "react";
import { Container } from "reactstrap";

import PageContainer from "../components/shared/PageContainer";
import ProfileForm from "../components/profile/ProfileForm";
import {useParams} from "react-router-dom";
import authService from "../services/auth-service";

const UserDetails = () => {
  let { id } = useParams();
  const { role } = useMemo(() => authService.getUser(), [])
  return (
    <PageContainer>
      <Container className="py-5 px-5 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>
        <ProfileForm
          userId={id}
          canDeleteUser={false}
          canChangePass={false}
          editable={(role === "super" || role === "admin")}
          showRentals
        />
      </Container>
    </PageContainer>
  );
}

export default UserDetails;
