// import axios from "axios";
import axiosService from "../services/axios-service";

const request = axiosService.getInstance();


// Rentals
// export const getRental = (id) => axios.get(`/api/v1rentals/${id}`)
export const getRental = id => request.get(`/rentals/${id}`)
export const getRentalByCode = code => request.get(`/rentals/code/${code}`)
export const getRentals = params => request.get(`/rentals`, {params})
export const getUserRentals = params => request.get(`/rentals/manage`, {params})
export const createRental = rental => request.post(`/rentals`, rental)
export const updateRental = (id, rental) => request.patch(`/rentals/${id}`, rental)
export const deleteRental = id => request.delete(`/rentals/${id}`)
export const pauseRental = (id, fakeBooking) => request.post(`/rentals/${id}/block`, fakeBooking)
export const disblockAllRental = id => request.post(`/rentals/${id}/disblock`)
export const disblockMonthRental = (id,month) => request.post(`/rentals/${id}/disblock/${month}`)


// Users
export const getUser = id => request.get(`/users/${id}`)
export const getMe = () => request.get(`/users/me`)
export const getUsers = () => request.get(`/users`)


// Places
export const getPlaces = () => request.get(`/places`)

// Bookings
export const getRentalBookings = id => request.get(`/bookings/rental/${id}`)


// Payments
export const getPendingPayments = () => request.get(`/payments`)
export const updatePayment = payment => request.post(`/payments/accept`, payment)
export const cancelPayment = (idPayment, refound) => request.post(`/payments/cancel`, { idPayment, refound })
export const declinePayment = payment => request.post(`/payments/decline`, payment)
export const hidePayment = payment => request.post(`/payments/hide`, payment)
export const showPayment = payment => request.post(`/payments/show`, payment)


// Contact
export const sendContact = (body) => request.post(`/users/contact`, body)


// Messages
export const getMessages = () => request.get('/messages')
export const sendMessage = body => request.post('/messages', body)
