import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import register, { registerValidSW, unregister } from "./registerServiceWorker";
// import * as serviceWorkerRegistration  from "./serviceWorkerRegistration";
import * as serviceWorker from './serviceWorker';

import "./i18n";

import "font-awesome/css/font-awesome.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css"
import '@splidejs/react-splide/dist/css/splide.min.css';
import 'react-range-slider-input/dist/style.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
