import React from "react";
import { Container, Row } from "reactstrap";

import Navbar from "./Navbar";
import Footer from "./Footer";

const PageContainer = ({children, title}) => {
  return (
    <Container fluid className="p-0 d-flex flex-column" style={{minHeight: '100vh'}}>
      <Navbar />

      <div style={{flex: 1}}>
        {title &&
          <Row className="mb-5">
            <h1 className="mb-0 h1 text-uppercase" style={{lineHeight: 1}}>{title}</h1>
          </Row>
        }
        {children}
      </div>

      <Footer />
    </Container>
  );
}

export default PageContainer;
