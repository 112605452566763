import { useState, useEffect } from 'react'
import { getMessages } from "../api/requests";

const useMessages = () => {
  const [messages, setMessages] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadMessages()
  }, [])

  const loadMessages = () => {
    setIsLoading(true)
    getMessages()
      .then(({data}) => setMessages(data))
      .catch(e => {
        setMessages(null)
      })
      .finally(() => setIsLoading(false))
  }

  return {
    messages,
    loadMessages,
    isLoading
  }
}

export default useMessages;
