import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';

import { IslandContext, islands } from "../hooks/wrappers/IslandWrapper";
import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";
import useRentals from "../hooks/useRentals";

// import home from "../icons/home.svg";// TODO: eliminar
// import no from "../icons/prohibido.svg"; // TODO: eliminar

import Loading from "../components/shared/Loading";
import RentalCard from "../components/shared/RentalCard";
import PageContainer from "../components/shared/PageContainer";

import rentalCategories from "../componentsOld/utils/rentalCateogries";
import LabeledTwoThumbs from "../componentsOld/shared/form/LabeledTwoThumbs";


const pageSize = 12

const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()

const RentalsSearch = () => {
  const { t } = useContext(LanguageContext);
  const { island } = useContext(IslandContext)
  const { rentals, isLoading } = useRentals({ island: (island !== null ? island : "all") })
  const [page, setPage] = useState(0)

  const { search } = useLocation(); // city, category, guests, bedrooms, minPrice, maxPrice
  const [filters, maxPrice] = useMemo(() => {
    if(!rentals) return []
    const { minPrice, maxPrice, ...other } = qs.parse(search)
    const maxRentalsPrice = rentals.length ? Math.max(...rentals.map(({dailyRate}) => dailyRate ? dailyRate*30 : 0)) : 1000
    return [
      { island, priceRange: [minPrice ? parseFloat(minPrice) : 0, maxPrice ? parseFloat(maxPrice) : maxRentalsPrice], ...other },
      Math.max(...[maxPrice || 0, maxRentalsPrice])
    ]
  }, [search, rentals, island])
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)

  const filteredRentals = useMemo(() => {
    if(isLoading || !rentals || !filters) return []

    const { city, category, guests, bedrooms, priceRange } = filters
    return [...rentals].filter(rental => {
      if(city) {
        const fixedCity = normalize(city)
        if(!rental.city.name || !normalize(rental.city.name).includes(fixedCity)) return false
      }
      if(category && (rental.category !== category)) return false
      if(guests && (parseInt(rental.guests) < parseInt(guests))) return false
      if(bedrooms && (parseInt(rental.bedrooms) < parseInt(bedrooms))) return false
      if(priceRange[0] > 0 || priceRange[1] < maxPrice){
        const monthlyRate = rental.dailyRate*30
        if(monthlyRate < priceRange[0] || monthlyRate > priceRange[1]) return false
      }

      return true
    })
  }, [filters, rentals, isLoading])

  const totalPages = useMemo(() => filteredRentals ? Math.ceil(filteredRentals.length / pageSize) : null , [pageSize, filteredRentals])

  const pageRentals = useMemo(() => (filteredRentals || []).slice(page * pageSize, (page+1) * pageSize), [page, pageSize, filteredRentals])

  return (
    <PageContainer>
      <Container fluid className="py-5 px-5 px-md-6" style={{maxWidth: 1500}}>

        <Row className="justify-content-between align-items-center mb-5 position-relative">
          <Col tag="h1" className="mb-0 p-0 text-uppercase" style={{lineHeight: 1}}>
            {island ?
              t("Rentals in") + " " + t(island)
            :
              t("Rentals")
            }
          </Col>
          <Col xs="auto" className="text-primary p-2 cursor-pointer" style={{fontSize: '1.5rem'}} onClick={() => setIsFiltersModalOpen(v => !v)}>
            {t('filter')} <i className="fas fa-filter"/>
          </Col>

          {isFiltersModalOpen &&
            <FiltersModal
              close={() => setIsFiltersModalOpen(false)}
              filters={filters}
              maxPrice={maxPrice}
            />
          }
        </Row>

        <Row className="justify-content-center flex-wrap mb-4" style={{gap: '2rem'}}>
          {isLoading ?
            <Loading />
            :
            <React.Fragment>
              {pageRentals?.length ?
                pageRentals.map((rental, i) => <RentalCard key={i} rental={rental}/>)
                :
                <span>{t('No apartments with these characteristics were found')}</span>
              }
            </React.Fragment>
          }
        </Row>

        {totalPages !== null &&
          <Row className="justify-content-end">
            <CustomPagination {...{page, setPage, totalPages}} />
          </Row>
        }

        {island &&
          <Row className="pt-7">
            <h2 className="text-primary mb-4 text-uppercase" style={{fontSize: '2.5rem'}}>{t(
              island === "mallorca" ? "Rent apartments in Mallorca" :
              island === "menorca" ? "Rent apartments in Menorca" :
              island === "ibiza" && "Rent apartments in Ibiza"
            )}</h2>
            <div className="w-100 translatedText" style={{fontSize: '1.3rem', lineHeight: 1.1}}>
              {t(`rentals search section - ${island}`)}
            </div>

          </Row>
        }

      </Container>
    </PageContainer>
  );
}

const FiltersModal = ({ close, filters, maxPrice }) => {
  const { t } = useContext(LanguageContext);
  const { island, changeIsland } = useContext(IslandContext)
  const history = useHistory()
  const [newFilters, setNewFilters] = useState(filters)

  const { island: newIsland, city, category, guests, bedrooms, priceRange } = newFilters

  const handleChange = (name, value) =>
    setNewFilters(f => ({...f, [name]: value}))
  const handleInputChange = (e) => {
    const { name, value } = e.target
    handleChange(name, value)
  }

  const handleSubmit = () => {
    const appliedFilters = {}
    if(city) appliedFilters.city = city
    if(category) appliedFilters.category = category
    if(guests && parseInt(guests)) appliedFilters.guests = guests
    if(bedrooms && parseInt(bedrooms)) appliedFilters.bedrooms = bedrooms
    const [newMinPrice, newMaxPrice] = priceRange
    if(newMinPrice) appliedFilters.minPrice = newMinPrice
    if(newMaxPrice && newMaxPrice !== maxPrice)
      appliedFilters.maxPrice = newMaxPrice

    if(newIsland !== island)
      changeIsland(newIsland, "/rentals?" + new URLSearchParams(appliedFilters).toString())
    else
      history.push({
        pathname: '',
        search: "?" + new URLSearchParams(appliedFilters).toString()
      })
  }

  return <>
    <div
      className="position-fixed vh-100 vw-100"
      style={{top: 0, left: 0, zIndex: 5}}
      onClick={close}
    />
    <div className="position-absolute shadow bg-white p-3" style={styles.modalContainer}>

      <FormGroup className="mb-3">
        <Label for="category">{t("Island")}</Label>
        <Input
          type="select"
          className="customInput"
          name="island"
          value={newIsland}
          onChange={handleInputChange}
        >
          <option value="" selected>{t("Any island")}</option>
          {islands.map((island, i) =>
            <option value={island} key={i} className="text-capitalize">{island}</option>
          )}
        </Input>
      </FormGroup>

      <FormGroup className="mb-3">
        <Label for="city">{t("Location")}</Label>
        <Input
          type="text"
          id="city"
          name="city"
          className="customInput"
          value={city}
          onChange={handleInputChange}
          placeholder={t("Search by location")}
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <Label for="category">{t("Category")}</Label>
        <Input
          type="select"
          className="customInput"
          name="category"
          value={category}
          onChange={handleInputChange}
        >
          <option value="" selected>{t("Any category")}</option>
          {rentalCategories.map((c, i) =>
            <option value={c} key={i}>{t(c)}</option>
          )}
        </Input>
      </FormGroup>

      <FormGroup className="mb-3">
        <Label for="price">{t("Precio mensual")}</Label>
        <LabeledTwoThumbs
          values={priceRange}
          max={maxPrice}
          setValues={v => handleChange('priceRange', v)}
        />
      </FormGroup>

      <Row>
        <Col xs={6}>
          <FormGroup className="mb-3">
            <Label for="guests">{t("No. people")}</Label>
            <Input
              type="select"
              className="customInput"
              name="guests"
              value={guests}
              onChange={handleInputChange}
              placeholder="0"
            >
              {[...Array(20)].map((c, i) =>
                <option value={i} key={i}>{i}</option>
              )}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup className="mb-3">
            <Label for="bedrooms">{t("No. rooms")}</Label>
            <Input
              type="select"
              className="customInput"
              name="bedrooms"
              value={bedrooms}
              onChange={handleInputChange}
              placeholder="0"
            >
              {[...Array(20)].map((c, i) =>
                <option value={i} key={i}>{i}</option>
              )}
            </Input>
          </FormGroup>
        </Col>
      </Row>

      <Button color="primary" className="py-2 mt-2 customInput text-uppercase" onClick={handleSubmit}>
        {t("filter")}
      </Button>

    </div>
  </>
}

const CustomPagination = ({page, setPage, totalPages}) => (
  <Pagination>
    <PaginationItem>
      <PaginationLink
        previous
        href=""
        disabled={page <= 0}
        onClick={e => {
          e.preventDefault()
          setPage(page-1)
          window.scrollTo(0, 0)
        }}
      />
    </PaginationItem>
    {[...Array(totalPages)].map((_, i) =>
      <PaginationItem active={page === i}>
        <PaginationLink
          href=""
          onClick={e => {
            e.preventDefault()
            setPage(i)
            window.scrollTo(0, 0)
          }}
        >
          {i+1}
        </PaginationLink>
      </PaginationItem>
    )}
    <PaginationLink
      next
      href=""
      disabled={page >= totalPages-1}
      onClick={e => {
        e.preventDefault()
        setPage(page+1)
        window.scrollTo(0, 0)
      }}
    />
  </Pagination>
)


const styles = {
  modalContainer: {
    zIndex: 5,
    top: '85%',
    right: 0,
    transform: 'translateX(-2vw)',
    maxWidth: 'calc(100% - 2vw)',
    width: 400
  }
}


export default RentalsSearch;
