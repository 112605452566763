import React, { PureComponent } from 'react';
import { withTranslation  } from "react-i18next";

class Cookies extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <h3 className="my-4">
          {t("Cookies")}
        </h3>
        <p>
          {t("We use Google Analytics cookies to measure and provide statistical analysis of browsing by our" +
            " visitors, and thus improve our supply of products and services. When browsing our website," +
            " we understand that you accept its installation")}
        </p>
        <h5>
          {t("What is a cookie?")}
        </h5>
        <p>
          {t("A cookie is a file that is stored in  your computer when you access given websites." +
            " Cookies allow the website, among other things, to store and recover information on" +
            " user browsing habits or their equipment and, depending upon the information they may" +
            " contain and the way they use their equipment, may be utilised to recognise the user.")}
        </p>
        <h5>
          {t("Which cookies are used by our website?")}
        </h5>
        <p>
          {t("Its own")}
        </p>
        <p>
          {t("Session cookies, automatically generated by our website server for session maintenance among" +
            " various requests from one same browser.")}
        </p>
        <p>
          {t("Third party")}
        </p>
        <p>
          {t("Browsing through our website entails the installation of Google Analytics cookies. Google" +
            " Analytics uses its cookies to measure and provide statistical analysis by users of our" +
            " website, and thus improve our supply of products and services. You may obtain more" +
            " information here.")}
        </p>
        <h5>
          {t("Can I browse without using cookies?")}
        </h5>
        <p>
          {t("Yes, although your user experience will be worse." +
            " You may disable cookies by changing the options configuration of your browser.")}
        </p>
      </div>
    );
  }
}

export default withTranslation ()(Cookies);
