import React, { useContext, useState, useMemo } from "react";
import {Container, Input, Table} from "reactstrap";
import Loading from "react-loading";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";
import usePagination from "../hooks/usePagination";
import useUsers from "../hooks/useUsers";

import PageContainer from "../components/shared/PageContainer";
import normalize from "../utils/normalize";


const UserList = () => {
  const { t } = useContext(LanguageContext);
  const { users, isLoading } = useUsers()
  const [search, setSearch] = useState()

  const filteredUsers = useMemo(() => {
    if(!search) return users
    const normalizedSearch = normalize(search)
    return (users || []).filter(u => {
      const searchableFields = [u.name, u.firstSurname, u.secondSurname, u.username, u.dni, u.email, u.phone].map(f => normalize(f)).join(' ')
      return searchableFields.includes(normalizedSearch)
    })
  }, [users, search])

  const {
    pageItems,
    PageSelector
  } = usePagination(filteredUsers)

  return (
    <PageContainer>
      <Container fluid className="py-5 px-0 px-lg-5 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>

        <div className="mb-3 px-4 mx-1 d-flex justify-content-between">
          <p className="h2">
            {t("Users")} <span className="font-weight-light">({users?.length})</span>
          </p>
          <div>
            <Input
              type="text"
              className="customInput"
              value={search}
              onChange={e => setSearch(e?.target?.value || '')}
              placeholder={t('Search') + '...'}
            />
          </div>
        </div>


        <div className="overflow-auto">
          <Table>
            <thead style={{fontSize: '1.5rem'}}>
              <tr>
                <th/>
                <th>{t('Email')+" / "+t('Phone')}</th>
                <th>NIF</th>
                <th>{t('Rentals')}</th>
                <th>{t('Registration')/*TODO: TRANSLATE*/}</th>
              </tr>
            </thead>
            <tbody style={{fontSize: '1.2rem'}}>
            {isLoading ? <Loading /> :
              !filteredUsers?.length ? '...' :
                pageItems.map((user, i) => <UserRow key={i} user={user}/>)
            }
            </tbody>
          </Table>
        </div>


        <PageSelector />

      </Container>
    </PageContainer>
  )
}


const UserRow = ({user}) => {
  const history = useHistory()
  console.log(user)
  return (
    <tr style={{cursor: 'pointer'}} onClick={() => history.push(`/user/${user._id}`)}>
      <td className="font-weight-bold">{user?.name} {user?.firstSurname}</td>
      <td>
        <div className="mb-2">{user?.email}</div>
        <div>{user?.phone}</div>
      </td>
      <td>{user?.dni}</td>
      <td>{user?.rentals?.length || ''}</td>
      <td>{!!user?.createdAt && new Date(user?.createdAt).toLocaleString().split(',')[0]}</td>
    </tr>
  )
}


export default UserList;
