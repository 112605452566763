import titleize from "titleize";
import * as moment from "moment";

export const toUpperCase = value => (value ? titleize(value) : "");

export const pretifyDate = (date, dateFormat = "DD/MM/YYYY") =>
  moment(date).format(dateFormat);

export const getRangeOfDates = (startAt, endAt, dateFormat = "Y/MM/DD") => {
  const tempDates = [];
  let mStartAt = moment(startAt);
  const mEndAt = moment(endAt);

  while (mStartAt < mEndAt) {
    tempDates.push(mStartAt.format(dateFormat));
    mStartAt = mStartAt.add(1, "day");
  }

  tempDates.push(mEndAt.format(dateFormat));

  return tempDates;
};
