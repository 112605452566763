import React, {Component, createContext, useEffect, useState} from 'react';

import mallorcaLogo from "../../media/brand/Logo_Mallorca.svg"
import ibizaLogo from "../../media/brand/Logo_Ibiza.svg"
import menorcaLogo from "../../media/brand/Logo_Menorca.svg"
import balearicLogo from "../../media/brand/Logo_Balearic.svg"

export const islandsProps = {
  mallorca: {
    text: "Mallorca Apartments Rentals",
    logo: mallorcaLogo,
    email: "info@mallorcaapartments-rentals.com",
    color: "#056edc"
  },
  menorca: {
    text: "Menorca Apartments Rentals",
    logo: menorcaLogo,
    email: "info@menorcaapartments-rentals.com",
    color: "#b69d6f"
  },
  ibiza: {
    text: "Ibiza Apartments Rentals",
    logo: ibizaLogo,
    email: "info@ibizaapartments-rentals.com",
    color: "#d7b41e"
  },
  default: {
    text: "Balearic Apartments Rentals",
    logo: balearicLogo,
    email: "info@balearicapartments-rentals.com",
    color: "#0593b9"
  }
}

export const islands = Object.keys(islandsProps).slice(0, -1)

const getPathnameIsland = () => {
  const island = window.location.pathname.split("/")[1];
  return islands.includes(island) ? island : null
}



export const IslandContext = createContext();

const IslandWrapper = ({ children }) => {
  const [island, setIsland] = useState(getPathnameIsland())


  useEffect(() => {
    setIsland(getPathnameIsland())
  }, [getPathnameIsland()])


  const changeIsland = (newIsland, newPathname) => {
    if(
      (newIsland && !islands.includes(newIsland)) ||
      (!island && !newIsland) ||
      (island && island === newIsland)
    ) return

    const pathname = window.location.pathname
    if(island && !newPathname)
      window.location.pathname = pathname.replace(
        `/${island}`,
        newIsland ? `/${newIsland}` : ''
      )
    else
      window.location = (newIsland ? `/${newIsland}` : "") + (newPathname || pathname)
  }

  const islandProps = island ? islandsProps[island] : islandsProps.default

  return (
    <IslandContext.Provider value={{ island, changeIsland, islands, islandProps }}>
      {children}
    </IslandContext.Provider>
  )
}


export function islandConsControl(WhatComponentNeedsIslandsConsumer) {
  const hoc = class extends Component {
    render() {
      return (
        <IslandContext.Consumer>
          {({ island }) => {
            const islandProps = island ? islandsProps[island] : islandsProps.default
            return <WhatComponentNeedsIslandsConsumer {...islandProps} island={island} {...this.props}/>
          }}
        </IslandContext.Consumer>
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsIslandsConsumer.displayName || WhatComponentNeedsIslandsConsumer.name);
  return hoc;
}


export default IslandWrapper;
