import React from "react";
import { Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import useArticles from "../../hooks/useArticles";

import ArticleCard from "../shared/ArticleCard"
import Loading from "../shared/Loading";


const params = {_limit: 4}


const LatestArticles = () => {
  const { t } = useTranslation();
  const { articles, isLoading } = useArticles(params)

  return (
    <Container fluid className="px-5 py-7">
      <Row className="mx-auto" style={{maxWidth: 'calc(1200px + (2rem * 3))'}}>
        <p className="h2 text-center text-xl-left text-primary w-100 mb-4 text-uppercase" style={{fontSize: '2.4rem'}}>
          {t('Latest news')}
        </p>
        {isLoading ?
          <Loading />
        :
          <div className="d-flex justify-content-center flex-wrap w-100" style={{gap: '2rem'}}>
            {articles.map((article, i) => <ArticleCard key={i} article={article}/>)}
          </div>
        }
      </Row>
    </Container>
  );
}

export default LatestArticles;
