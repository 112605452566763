import React from "react";
import LoginForm from "./LoginForm";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import * as actions from "actions";
import { withTranslation  } from "react-i18next";
import BwmInput from "../shared/form/BwmInput";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      changePass: false,
      email: ""
    };
    this.loginUser = this.loginUser.bind(this);
  }

  loginUser(userData) {
    const { action, t } = this.props;
    const cb = (bool) => {
      bool && action();
      !bool && toast.error(t("There is no user with that email and that password"));
    };
    this.props.dispatch(actions.login(userData, cb));
  }

  changePass = () => {
    this.setState({ changePass: true });
    console.log("cambiar");
    console.log()
  };

  submitChangePass = (event) => {
    const { t } = this.props;
    event.preventDefault();
    actions.sendEmailRestorePassword(this.state.email).then(() => {
      alert(t("An email has been sent with the necessary redirection to change the password"));
      this.setState({ changePass: false })
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  static defaultProps = {
    full: true,
    action: () => {
    },
    redirect: true
  };

  render() {
    const { t, full, redirect } = this.props;
    const { changePass, email } = this.state;
    const { isAuth, errors } = this.props.auth;
    // const { successRegister } = this.props.location.state || false;

    if (isAuth && redirect) {
      return <Redirect to={{ pathname: "/" }}/>;
    }
    if (changePass) {
      return <form onSubmit={this.submitChangePass} className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-5">
          <BwmInput
            input={{
              value: email,
              onChange: this.handleChange,
              name: "email",
              required: true
            }}
            type="email"
            label={"email"}
            className="form-control"
          />
          <button className="btn btn-primary mt-2 px-5">Send email</button>
        </div>
      </form>
    }

    return (
      <section id="login">
        <div className="bwm-form">
          <div className="row justify-content-center">
            <div className={full ? "col-md-5" : "col-12"}>
              <div className="bg-white w-100 py-3">
                <h1>{t("Login")}</h1>
                {/*{successRegister && (*/}
                {/*  <div className="alert alert-success">*/}
                {/*    <p>*/}
                {/*      {t(*/}
                {/*        "You will receive an account activation email shortly"*/}
                {/*      )}*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*)}*/}
                <LoginForm submitCb={this.loginUser} errors={errors} changePass={this.changePass}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default withTranslation ()(connect(mapStateToProps)(Login));
