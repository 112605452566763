import { useEffect, useCallback, useMemo, useState } from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";

export const defaultPagination = {
  page: 1,
  pageSize: 15,
  pageCount: 1,
  total: 0
}

const usePagination = (items, initialPagination = defaultPagination) => {
  const [pagination, setPagination] = useState({...defaultPagination, ...initialPagination})
  const {
    page,
    pageSize,
    pageCount,
    total
  } = pagination

  const goToPage = useCallback(page => {
    if(page > 0 && page <= pageCount) {
      window.scrollTo(0, 0)
      setPagination(p => ({...p, page}))
    }
  }, [pageCount])

  const setPageSize = useCallback(pageSize => {
    if(pageSize > 0)
      setPagination(p =>
        ({...p, pageSize, page: 1})
      )
  }, [page, pageCount, total])

  const resetPagination = () => setPagination(defaultPagination)

  useEffect(() => items && setPagination(({pageSize, page}) => {
    const pageCount = Math.ceil(items.length / pageSize) || 1
    const newPage = page > pageCount ? 1 : page
    return {
      page: newPage,
      pageSize,
      pageCount,
      total: items?.length || 0,
    }
  }), [items, pagination.pageSize])

  const pageItems = useMemo(() => {
    const { page, pageSize } = pagination
    return items ? items.slice((page-1)*pageSize, page*pageSize) : []
  }, [items, pagination])


  return {
    pageItems,
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    resetPagination,
    PageSelector: () => PageSelector({pagination, goToPage})
  }

}


const PageSelector = ({pagination, goToPage}) =>
  <Pagination className="d-flex justify-content-end mt-5">
    <PaginationItem disabled={pagination?.page === 1} onClick={() => goToPage(pagination?.page-1)}>
      <PaginationLink previous/>
    </PaginationItem>
    {Array.from({length: pagination?.pageCount || 0}, (_, i) => i + 1).map(n =>
      <PaginationItem key={n} active={n === pagination?.page}>
        <PaginationLink onClick={() => goToPage(n)}>
          {n}
        </PaginationLink>
      </PaginationItem>
    )}
    <PaginationItem disabled={pagination?.page === pagination?.pageCount} onClick={() => goToPage(pagination?.page+1)}>
      <PaginationLink next />
    </PaginationItem>
  </Pagination>


export default usePagination;
