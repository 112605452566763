import React from "react";
import i18n from "i18n";
import { withTranslation  } from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import getModalPrivacityPolitic from "../shared/getModalPrivacityPolitic";
import moment from "moment";
import axiosService from "services/axios-service";
import { toast } from "react-toastify";
import Profile from "../profile/Profile";
import Payment from "../payment/Payment";
import * as actions from "actions";
import BookingGuest from "./BookingGuest";
import renderHTML from "react-render-html";
import Modal from 'react-modal';
import Login from "../login/Login";
import Register from "../register/Register";
import {Button, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import calculateRentalPrice from "../../utils/calculateRentalPrice";
import PageContainer from "../../components/shared/PageContainer";
import personIcon from "../../media/rentalPage/person.svg";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)'

  }
};

const axiosInstance = axiosService.getInstance();

class BookingConfirmation extends React.Component {
  state = {
    booking: {
      guestsInformation: [],
      reasonVisit: "",
      wantsInvoice: false
    },
    rental: {},
    profile: { all: true, booking: true, sesion: true },
    login: true,
    isGuestsInformationCompleted: false,
    isVisibleTermsAndConditions: false,
    isAcceptedTermsAndConditions: false,
    isVisiblePrivacity: false,
    isAcceptedPrivacity: false,
    imgGuests: [],
    client_secret: null,
    needsConfirmation: true
  };

  setReason = (event) => {
    const value = event.target.value;
    this.setState({ booking: { ...this.state.booking, reasonVisit: value } })
  };

  componentDidMount() {
    const { search } = this.props.location;
    const { id } = this.props.match.params;
    const searchParams = new URLSearchParams(search);
    const booking = JSON.parse(decodeURI(searchParams.get("booking")));
    const days = moment(booking.endAt).diff(booking.startAt, "days") + 1;
    axiosInstance
      .get("rentals/" + id)
      .then(({ data }) => data)
      .then(rental => {
        this.setState({
          rental,
          booking: { ...booking, rental, days, guestsInformation: [], reasonVisit: "" },
          isGuestsInformationCompleted: parseInt(booking.guests) === 1
        });
      });
  }

  togleAcceptTermsAndConditions = () => {
    this.setState({
      isAcceptedTermsAndConditions: !this.state.isAcceptedTermsAndConditions
    });
  };

  toggleVisibleOfTermsAndConditions = () => {
    this.setState({
      isVisibleTermsAndConditions: !this.state.isVisibleTermsAndConditions
    });
  };

  togleAcceptPrivacity = () => {
    this.setState({
      isAcceptedPrivacity: !this.state.isAcceptedPrivacity
    });
  };

  toggleVisibleOfPrivacity = () => {
    this.setState({
      isVisiblePrivacity: !this.state.isVisiblePrivacity
    });
  };

  onProfileSave = profile => {
    const { t } = this.props;
    if (profile.booking) {
      toast.success(t("We have all the necessary information for the reservation"))
    } else if (profile.sesion) {
      toast.error(t("Oops! We need information about you to proceed with the reservation. Complete the fields and click on update profile."))
    } else {
      toast.error(t("Login or register to continue"))
    }
    this.setState({ profile });
  };

  setPaymentToken = paymentToken => {
    const { booking } = this.state;
    this.setState({ booking: { ...booking, paymentToken } });
  };

  reserveRental = () => {
    const { t } = this.props;
    const { isAcceptedTermsAndConditions, isAcceptedPrivacity } = this.state;
    if (isAcceptedTermsAndConditions && isAcceptedPrivacity) {
      actions.createBooking(this.state.booking).then(
        ({ client_secret, needsConfirmation }) => {
          this.setState({client_secret, needsConfirmation})
        },
        errors => {
          toast.error(t("Oops! unable to complete reservation"));
          this.setState({ errors });
        }
      )
    } else {
      alert(t("Please accept the terms and conditions"))
    }
  };

  onGuestChange = (name, value, index) => {
    const { booking, imgGuests } = this.state;
    const guestsInformation = [...booking.guestsInformation];
    imgGuests[index] = value;

    guestsInformation[index] = {
      ...guestsInformation[index],
      [name]: value
    };

    this.setState({
      booking: { ...booking, guestsInformation },
      isGuestsInformationCompleted: this.isGuestsInformationCompleted(
        guestsInformation
      ),
      imgGuests
    });
  };

  loginUser(userData) {
    this.props.dispatch(actions.login(userData));
  }

  isGuestsInformationCompleted = guestsInformation => {
    return guestsInformation.reduce((reducer, { name, imageURL }) => {
      return (
        reducer &&
        typeof name !== "undefined" &&
        name !== "" &&
        typeof imageURL !== "undefined" &&
        imageURL !== ""
      );
    }, true);
  };

  change = () => {
    this.setState({ profile: { all: true, booking: true, sesion: true } })
  };

  turnRegLog = (event) => {
    event.preventDefault();
    this.setState({ login: !this.state.login })
  };

  render() {
    const { t, location } = this.props;
    const change = this.change;
    const {
      booking,
      rental,
      profile,
      login,
      isGuestsInformationCompleted,
      isAcceptedTermsAndConditions,
      isAcceptedPrivacity,
      client_secret,
      needsConfirmation
    } = this.state;
    const dateFormat = i18n.language === "es" ? "DD/MM/YYYY" : "YYYY/MM/DD";


    const getModalTermsAndConditions = (t, isVisibleTermsAndConditions, toggleVisibleOfTermsAndConditions) =>
      <Modal
        isOpen={isVisibleTermsAndConditions}
        style={customStyles}
        contentLabel="Example Modal"
        className="col-12 col-md-9 col-lg-5 bg-white rounded border border-info p-3">
        <div style={{ overflowY: "scroll", maxHeight: "50vh" }}>
          <p>
            {renderHTML(t("The parties to this agreement, duly identified in the " +
              "reservation made via the web Balearicapartments-rentals.com,"))}
          </p>
          <p className="d-flex justify-content-center">
            <b className="text-center">{renderHTML(t("AGREE"))}</b>
          </p>
          <p>
            {renderHTML(t("ONE (purpose of the agreement)"))}
          </p>
          <div>
            {renderHTML(t("TWO (validity period)"))}
          </div>
          <div>
            {renderHTML(t("THREE (payment)"))}
          </div>
          <div>
            {renderHTML(t("FOUR (Deposit)"))}
          </div>
          <p>
            {renderHTML(t("FIVE (applicable regime)"))}
          </p>
          <div>
            {renderHTML(t("SIX (breakdown of expenses)"))}
          </div>
          <div>
            {renderHTML(t("SEVEN (works)"))}
          </div>
          <div>
            {renderHTML(t("EIGHT (specific conditions)"))}
          </div>
          <div>
            {renderHTML(t("NINE (grounds for termination)"))}
          </div>
          <div>
            {renderHTML(t("TEN (applicable law)"))}
          </div>
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button className="btn btn-bwm btn-form"
                  onClick={toggleVisibleOfTermsAndConditions}>{t("Close")}
          </button>
        </div>
      </Modal>
    ;

    const basePrice = calculateRentalPrice(rental, booking?.startAt, booking?.endAt)

    console.log(!booking.paymentToken,
      !profile.booking,
      !isGuestsInformationCompleted)

    return (
      <PageContainer>
        <Container className="py-5 px-5 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>
          <Row className="mb-5 px-4 mx-1 h2 flex-center">
            {t("Confirm Booking")}
          </Row>

          <Row className="mb-4">
            <Col className="mb-4">

              <h3 className="text-primary mb-1 font-weight-bold">
                {rental.title}
                <span>{`, ${t(rental.category)}`}</span>
              </h3>
              <p className="font-weight-bold">{rental?.city?.name}</p>
              <div className="mb-4 d-flex">
                <p>
                  {`${moment(booking.startAt).format(dateFormat)} - ${moment(booking.endAt).format(dateFormat)} `}
                  <b>
                    ({booking.days} {t("days")})
                  </b>
                </p>

                <p className="ml-3">
                  <img src={personIcon} height="25rem" className="mr-2" alt="maxPeopleIcon"/>
                  {booking.guests}
                </p>
              </div>

              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <p className="w-100">{t("Stay price")}</p>
                  <b>{basePrice}€</b>
                </div>


                <div className="d-flex justify-content-between">
                  <p className="flex-center">
                    {t("DoesNeedAnInvoice")}
                    <Label check className="mb-3 ml-4">
                      <Input
                        type="checkbox"
                        checked={Boolean(booking.wantsInvoice)}
                        onChange={e => {
                          this.setState({booking: {
                              ...booking,
                              wantsInvoice: e.target.checked
                            }})
                        }}
                      />
                    </Label>
                  </p>

                  {booking.wantsInvoice ? <b>({(basePrice * 1.21).toFixed(2)}€ {t('incl. VAT')} 21%)</b> : <b>-</b>}
                </div>

                <div className="d-flex justify-content-between">
                  <p className="w-100">{t("Deposit")}</p>
                  <b>{rental.deposit}€</b>
                </div>

                <div className="d-flex align-items-end justify-content-between">
                  <p className="w-100 mb-1 font-weight-bold">{t("Total price, all included (electricity, water, wifi ...)")}</p>
                  <b className="text-primary" style={{fontSize: '2rem'}}>{basePrice + rental.deposit}€</b>
                </div>
                <div className="w-100 text-right">{booking.wantsInvoice && <b>({(basePrice * 1.21 + rental.deposit).toFixed(2)}€ {t('incl. VAT')})</b>}</div>

              </div>

              <div className="my-5">
                <div className="alert alert-warning mb-4" role="alert">
                  {t("Please, check if your profile is complete and up to date")}
                </div>

                {profile.sesion ?
                  <Profile
                    onSave={this.onProfileSave}
                    header={() => <h2>{t("Profile")}</h2>}
                    containerClassName="col-md-12"
                    bancData={false}
                    deleteUser={false}
                    changePass={false}
                  />
                  :
                  login ?
                    <Login full={false} action={change} redirect={false}/>
                    :
                    <Register
                      full={false}
                      to={location.pathname + location.search}
                      action={(event) => {this.turnRegLog(event)}}
                      redirectTo={false}
                    />
                }
                {!profile.sesion &&
                  <div className="text-right">
                    <Button
                      color="primary"
                      className="py-2 px-4"
                      style={{
                        borderRadius: 9999,
                        fontSize: '1.2rem'
                      }}
                      onClick={this.turnRegLog}
                    >
                      {login ? t("Register") : t("Login")}
                    </Button>
                  </div>
                }
              </div>


            </Col>


            <Col xs={12} md={5} lg={4}>
              <div className="sticky-top" style={{top: 180}}>
                {booking.guests > 1 && (
                  <React.Fragment>
                    <h3 className="mb-3 mt-4">{t("Guests Information")}</h3>
                    {[...new Array(booking.guests - 1)].map((guest, index) => {
                      let img = null
                      return (
                        <React.Fragment key={index}>
                          <BookingGuest
                            onNameChange={event =>
                              this.onGuestChange(
                                event.target.name,
                                event.target.value,
                                index
                              )
                            }
                            onImageUpload={imageURL => {
                              img = imageURL;
                              return this.onGuestChange("imageURL", imageURL, index)
                            }}
                          />
                          <div className="w-100">
                            {this.state.imgGuests[index] &&
                              <img style={{ maxHeight: "300px" }} src={this.state.imgGuests[index]} alt="" loading="lazy"/>}
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )}
                <div>
                  <label htmlFor="reason" className="font-weight-bold">{t("The reason of the visit")}</label>
                  <textarea name="reason" className="form-control" value={booking.reasonVisit} onChange={this.setReason}>
                  </textarea>
                </div>
                <h3 className="mb-3 mt-5">{t("Payment Information")}</h3>
                {getModalTermsAndConditions(t, this.state.isVisibleTermsAndConditions, this.toggleVisibleOfTermsAndConditions)}
                {getModalPrivacityPolitic(t, this.state.isVisiblePrivacity, this.toggleVisibleOfPrivacity)}
                <Payment setPaymentToken={this.setPaymentToken} client_secret={client_secret} needsConfirmation={needsConfirmation}/>





                <FormGroup check className="mt-3 mb-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="doesWantAds"
                      checked={isAcceptedTermsAndConditions}
                      onChange={this.togleAcceptTermsAndConditions}
                      required
                    />
                    <span
                      onClick={this.toggleVisibleOfTermsAndConditions}
                      style={{ cursor: "pointer" }}
                    >
                      {renderHTML(t("Accept terms and conditions"))}
                    </span>
                  </Label>
                </FormGroup>

                <FormGroup check className="mb-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="doesWantAds"
                      checked={isAcceptedPrivacity}
                      onChange={this.togleAcceptPrivacity}
                      required
                    />
                    <span
                      onClick={this.toggleVisibleOfPrivacity}
                      style={{ cursor: "pointer" }}
                    >
                      {t("I have read and accept the privacy policy")}
                    </span>
                  </Label>
                </FormGroup>



                {/*<div className="form-check mt-3">*/}
                {/*  <input onChange={this.togleAcceptTermsAndConditions}*/}
                {/*         checked={isAcceptedTermsAndConditions}*/}
                {/*         type="checkbox" className="form-check-input"/>*/}
                {/*  <label onClick={this.toggleVisibleOfTermsAndConditions}*/}
                {/*         style={{ cursor: "pointer" }}*/}
                {/*         className="form-check-label"*/}
                {/*  >{renderHTML(t("Accept terms and conditions"))}</label>*/}
                {/*</div>*/}
                {/*<div className="form-check mt-3">*/}

                {/*  <input onChange={this.togleAcceptPrivacity}*/}
                {/*         checked={isAcceptedPrivacity}*/}
                {/*         type="checkbox" className="form-check-input"/>*/}
                {/*  <label onClick={this.toggleVisibleOfPrivacity}*/}
                {/*         style={{ cursor: "pointer" }}*/}
                {/*         className="form-check-label"*/}
                {/*  >{t("I have read and accept the privacy policy")}</label>*/}
                {/*</div>*/}

                <div className="text-center mt-4 mb-3">
                  <Button
                    color="success"
                    className="py-2 px-4"
                    style={{
                      borderRadius: 9999,
                      fontSize: '1.2rem'
                    }}
                    disabled={
                      !booking.paymentToken ||
                      !profile.booking ||
                      !isGuestsInformationCompleted
                    }
                    onClick={this.reserveRental}
                  >
                    {t("Confirm Booking")}
                  </Button>
                </div>

                <div className="alert alert-warning" role="alert">
                  {t("We will not save your payment information")}.
                </div>

                <div className="alert alert-warning" role="alert">
                  {t("The charge will only be made after the booking is accepted. If it is denied no money will be charged")}.
                </div>

              </div>
            </Col>

          </Row>
        </Container>
      </PageContainer>
    );

  }
}

export default withTranslation ()(withRouter(BookingConfirmation));
