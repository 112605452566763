import axios from "axios";
import authService from "services/auth-service";
import axiosService from "services/axios-service";

import {
  FETCH_RENTAL_BY_ID_SUCCESS,
  FETCH_RENTAL_BY_ID_INIT,
  FETCH_RENTALS_SUCCESS,
  FETCH_RENTALS_INIT,
  FETCH_RENTALS_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_USER_BOOKINGS_SUCCESS,
  FETCH_USER_BOOKINGS_FAIL,
  FETCH_USER_BOOKINGS_INIT,
  UPDATE_RENTAL_SUCCESS,
  UPDATE_RENTAL_FAIL,
  RESET_RENTAL_ERRORS,
  RELOAD_MAP,
  RELOAD_MAP_FINISH
} from "./types";

const axiosInstance = axiosService.getInstance();

export const reloadMap = () => {
  return {
    type: RELOAD_MAP
  };
};

export const reloadMapFinish = () => {
  return {
    type: RELOAD_MAP_FINISH
  };
};

// RENTALS ATIONS ---------------------------

const fetchRentalByIdInit = () => {
  return {
    type: FETCH_RENTAL_BY_ID_INIT
  };
};

const fetchRentalByIdSuccess = rental => {
  return {
    type: FETCH_RENTAL_BY_ID_SUCCESS,
    rental
  };
};

const fetchRentalsSuccess = rentals => {
  return {
    type: FETCH_RENTALS_SUCCESS,
    rentals
  };
};

const fetchRentalsInit = () => {
  return {
    type: FETCH_RENTALS_INIT
  };
};

const fetchRentalsFail = errors => {
  return {
    type: FETCH_RENTALS_FAIL,
    errors
  };
};

export const fetchRentals = ({ city = "all", island = "all" }) => {
  const url = `/rentals?city=${city}&island=${island}`;

  return dispatch => {
    dispatch(fetchRentalsInit());

    axiosInstance
      .get(url)
      .then(res => res.data)
      .then(rentals => dispatch(fetchRentalsSuccess(rentals)))
      .catch(({ response }) =>
        dispatch(fetchRentalsFail(response.data.errors))
      );
  };
};

export const fetchRentalById = rentalId => {
  return function (dispatch) {
    dispatch(fetchRentalByIdInit());

    axios
      .get(`/api/v1/rentals/${rentalId}`)
      .then(res => res.data)
      .then(rental => {
        const description = rental?.description
        if(typeof description === 'string')
          rental.description = { es: description }

        dispatch(fetchRentalByIdSuccess(rental))
      })
      .catch(err => dispatch(fetchRentalByIdSuccess(err)));
  };
};

export const contact = info => {
  return axiosInstance
    .post("/users/contact", info)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const getUsers = () => {
  return axiosInstance
    .get("/users")
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const deleteUser = (id) => {
  return axiosInstance
    .delete("/users/deleteOne/" + id,)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const createRental = rentalData => {
  return axiosInstance
    .post("/rentals", rentalData)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const resetRentalErrors = () => {
  return {
    type: RESET_RENTAL_ERRORS
  };
};

const updateRentalSuccess = updatedRental => {
  return {
    type: UPDATE_RENTAL_SUCCESS,
    rental: updatedRental
  };
};

const updateRentalFail = errors => {
  return {
    type: UPDATE_RENTAL_FAIL,
    errors
  };
};

export const changePassword = (newPass, token) => {
  return axiosInstance
    .patch(`/users/restorePass`, { newPass, token: ("Bearer " + token) })
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const sendEmailRestorePassword = (email) => {
  return axiosInstance
    .patch(`/users/emailRestore`, { email })
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const updateblock = (id, fakeBooking) => {

  return axiosInstance
    .post(`/rentals/${id}/block`, fakeBooking)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const updateDisblockAll = (id) => {

  return axiosInstance
    .post(`/rentals/${id}/disblock`)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const updateRental = (id, rentalData, fallBack = () => {}) => dispatch => {
  return axiosInstance
    .patch(`/rentals/${id}`, rentalData)
    .then(res => res.data)
    .then(updatedRental => {
      dispatch(updateRentalSuccess(updatedRental));
      dispatch(resetRentalErrors());
      fallBack();
    })
    .catch(({ response }) => dispatch(updateRentalFail(response.data.errors)));
};

// USER BOOKINGS ACTIONS ---------------------------

const fetchUserBookingsInit = () => {
  return {
    type: FETCH_USER_BOOKINGS_INIT
  };
};

const fetchUserBookingsSuccess = userBookings => {
  return {
    type: FETCH_USER_BOOKINGS_SUCCESS,
    userBookings
  };
};

const fetchUserBookingsFail = errors => {
  return {
    type: FETCH_USER_BOOKINGS_FAIL,
    errors
  };
};

export const fetchUserBookings = () => {
  return dispatch => {
    dispatch(fetchUserBookingsInit());

    axiosInstance
      .get("/bookings/manage")
      .then(res => res.data)
      .then(userBookings => dispatch(fetchUserBookingsSuccess(userBookings)))
      .catch(({ response }) =>
        dispatch(fetchUserBookingsFail(response.data.errors))
      );
  };
};

// USER RENTALS ACTIONS ---------------------------

export const getUserRentals = () => {
  return axiosInstance
    .get("/rentals/manage")
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const getUnpublishedRentalsCount = () => {
  return axiosInstance
    .get(
      `/rentals/count?filter=${encodeURI(JSON.stringify({ published: false }))}`
    )
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const deleteRental = rentalId => {
  return axiosInstance
    .delete(`/rentals/${rentalId}`)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

// AUTH ACTIONS ---------------------------

const loginSuccess = () => {
  const { username, role } = authService.getUser();

  return {
    type: LOGIN_SUCCESS,
    username,
    role
  };
};

const loginFailure = errors => {
  return {
    type: LOGIN_FAILURE,
    errors
  };
};

export const register = userData => {
  return axios
    .post("/api/v1/users/register", userData)
    .then(res => res.data, err => Promise.reject(err.response.data.errors));
};

export const checkAuthState = () => {
  return dispatch => {
    if (authService.isAuthenticated()) {
      dispatch(loginSuccess());
    }
  };
};

export const login = (userData, cb = () => {
}) => {
  return dispatch => {
    return axios
      .post("/api/v1/users/auth", userData)
      .then(res => res.data)
      .then(token => {
        authService.saveToken(token);
        dispatch(loginSuccess());
        cb(true)
      })
      .catch(({ response }) => {
        dispatch(loginFailure(response.data.errors));
        cb(false)
      });
  };
};

export const logout = () => {
  authService.invalidateUser();

  return {
    type: LOGOUT
  };
};

export const createBooking = booking => {
  return axiosInstance
    .post("/bookings", booking)
    .then(res => res.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};

export const uploadImage = image => {
  const formData = new FormData();
  formData.append("image", image);

  return axiosInstance
    .post("/image-upload", formData)
    .then(json => {
      return json.data.imageUrl;
    })
    .catch(({ response }) => Promise.reject(response.data.errors[0]));
};

export const getPendingPayments = () => {
  return axiosInstance
    .get("/payments")
    .then(res => res.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};

export const updatePayment = payment => {
  return axiosInstance
    .post("/payments/accept", payment)
    .then(res => res.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};

export const cancelPayment = (idPayment, refound) => {
  return axiosInstance
    .post("/payments/cancel", { idPayment, refound })
    .then(res => res.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};

export const declinePayment = payment => {
  return axiosInstance
    .post("/payments/decline", payment)
    .then(res => res.data)
    .catch(({ response }) => Promise.reject(response.data.errors));
};
