import moment from "moment";

const getDatesInRange = (from, to, format) => {
  let dates = [];
  let currDate = moment(from);
  while(currDate.diff(moment(to)) < 0) {
    dates.push(currDate.format(format));
    currDate.add(1, 'days')
  }
  return dates
}

export default  getDatesInRange
