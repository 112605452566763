import React from "react";
import { withTranslation  } from "react-i18next";

const BwmInput = ({
  input,
  label,
  type,
  symbol,
  className,
  meta: { touched, error } = { touched: false, error: false },
  t
}) => (
  <div className="form-group w-100">
    <label>{label}</label>
    <div className="input-group">
      <input {...input} type={type} className={className} />
      {symbol && (
        <div className="input-group-prepend">
          <div className="input-group-text">{symbol}</div>
        </div>
      )}
    </div>
    {touched && (error && <div className="alert alert-danger">{t(error)}</div>)}
  </div>
);

export default withTranslation ()(BwmInput);
