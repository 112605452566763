import React, {useContext} from "react";
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";

import keysIcon from "../../media/home/icons/keys.svg"
import usersIcon from "../../media/home/icons/users.svg"
import SeparatedSection from "../shared/SeparatedSection";


const ProductExplanation = ({altTexts}) => {
  const { t } = useContext(LanguageContext);

  return (
    <SeparatedSection flipSeparators
      first={<>
        <div className="d-flex align-items-center mb-5 text-primary flex-wrap flex-md-nowrap" style={{gap: '2rem'}}>
          <img height="110rem" src={usersIcon} alt="users_icon" loading="lazy"/>
          <p className="h2 m-0 text-uppercase" style={{fontSize: '2.7rem'}}>{t('Our target market')}</p>
        </div>
        {altTexts ?
          t('Our target market - text 2')
        :
          t('Our target market - text')
        }
      </>}
      second={<>
        <div className="d-flex align-items-center mb-5 text-primary flex-wrap flex-md-nowrap" style={{gap: '2rem'}}>
          <img height="110rem" src={keysIcon} alt="keys_icon" loading="lazy"/>
          <p className="h2 m-0 text-uppercase" style={{fontSize: '2.7rem'}}>{t('Our offer')}</p>
        </div>
        {altTexts ?
          t('Our offer - text 2')
        :
          t('Our offer - text')
        }
      </>}
    />
  );
}


export default ProductExplanation;
