import { useState, useEffect } from 'react'
import { getUserRentals } from "../api/requests";

const useUserRentals = () => {
  const [userRentals, setUserRentals] = useState()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    setLoading(true)
    getUserRentals()
      .then(({data}) => setUserRentals(data))
      .catch(e => {
        setUserRentals(null)
        console.log(e.message)
      })
      .finally(() => setLoading(false))
  }

  return {
    userRentals,
    refresh,
    isLoading
  }
}

export default useUserRentals;
