import React, {useContext} from "react";
import {Col, Container, Row} from "reactstrap";

import bgImg from "../../media/home/cancelation-policy-bg.svg"
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";

const listItems = [
  'Notification within 30 days, without penalty.',
  'Notification within 15 days, only 50% of the reservation amount will be forfeited as a penalty.',
  'Notification within 5 days will result in the loss of 100% of the amount of the reservation as a penalty.'
]

const CancelationPolicy = () => {
  const { t } = useContext(LanguageContext);
  return (
    <Container fluid>
      <Row className="d-flex justify-content-center" style={styles.bg}>
        <Col xs="12" lg="5" className="order-lg-last">
          <h3 className="h2 text-primary w-100 px-lg-3 text-uppercase" style={{fontSize: '2.4rem'}}>
            {t('Cancellation policies')}
          </h3>
        </Col>
        <Col xs="12" lg="5">
          <p>{t('A cancellation regime with its respective penalties is established as follows')}:</p>
          <p className="mb-0">
            <ul className="p-0" style={{listStyle: 'none'}}>
              {listItems.map((text, i) =>
                <li key={i} className="d-flex mb-2">
                  <span className="mr-3">·</span>
                  <p className="mb-0">{t(text)}</p>
                </li>
              )}
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

const styles = {
  bg: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    gap: '2rem',
    fontSize: '1.3rem',
    padding: '5rem 3rem'
  },
}

export default CancelationPolicy;
