import React, { useContext, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import {IslandContext} from "../../hooks/wrappers/IslandWrapper";
import useRentals from "../../hooks/useRentals";

import RentalCard from "../shared/RentalCard"
import Loading from "../shared/Loading";


const shownRandomRentals = 4


const HighlitedRentals = () => {
  const { t } = useTranslation();
  const { island } = useContext(IslandContext)
  const { rentals, isLoading } = useRentals({ island: (island !== null ? island : "all") })

  const randomRentals = useMemo(() => {
    if(isLoading || !rentals) return []
    if(rentals?.length <= shownRandomRentals) return rentals
    else {
      const usedIndexes = []
      const newRentals = []
      while(newRentals.length < shownRandomRentals) {
        const rIndex = Math.floor(Math.random() * rentals?.length)
        if(usedIndexes.includes(rIndex)) return []
        else {
          newRentals.push(rentals[rIndex])
          usedIndexes.push(rIndex)
        }
      }
      return newRentals
    }
  }, [rentals, isLoading])


  const stats = [
    {
      value: rentals?.length || '+50',
      text: t('Properties'),
      className: 'text-ibiza'
    },
    {
      value: '+5.200',
      text: t('Satisfied customers'),
      className: 'text-menorca'
    },
    {
      value: '+200',
      text: t('Annual reservations'),
      className: 'text-mallorca'
    },
    // {
    //   value: '34',
    //   text: 'Lorem ipsum',
    //   className: 'text-primary bg-danger'
    // }
  ]

  return (
    <Container fluid className="py-5">
      <Row className="py-3 flex-center" style={{gap: '5rem'}}>
        {stats.map(({value, text, className}, i) =>
          <div key={i} className="text-center">
            <span
              className={className}
              style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                lineHeight: 1.1
              }}
            >{value}</span>
            <p className="text-gray" style={{fontSize: '1.4rem', letterSpacing: '.03rem' }}>{text}</p>
          </div>
        )}
      </Row>
      <Row className="flex-center py-4">
        <h2 className="text-primary w-100 text-center px-3 text-uppercase">{t('Featured properties')}</h2>
        {isLoading ?
          <Loading />
        :
          <div className="d-flex justify-content-center flex-wrap w-100" style={{gap: '2rem'}}>
            {randomRentals.map((rental, i) => <RentalCard key={i} rental={rental}/>)}
          </div>
        }
      </Row>
    </Container>
  );
}

export default HighlitedRentals;
