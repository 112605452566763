import { useState, useEffect } from 'react'
import { getUsers } from "../api/requests";
import {toast} from "react-toastify";

const useUsers = () => {
  const [users, setUsers] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getUsers()
      .then(({data}) => setUsers(data?.users))
      .catch(e => {
        setUsers(null)
        toast.error("Ha ocurrido un problema, recargue la página")
      })
      .finally(() => setIsLoading(false))
  }, [])

  return {
    users,
    isLoading
  }
}

export default useUsers;
