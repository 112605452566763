import React from "react";
import { useTranslation } from "react-i18next";
import assetsIcons from '../../resources/assets'

const Assets = ({ assets = [], onAssetClick }) => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-12">
        {onAssetClick &&
          <h3>{t("Please indicate the commodities")}:</h3>
        }
      </div>
      {assets.map((asset, index) => {
        const name = asset?.name || asset
        return (
          <div
            key={index}
            onClick={() => onAssetClick && onAssetClick(name)}
            className="col-6 col-lg-3 d-flex align-items-center py-4"
            style={{ cursor: "pointer" }}
          >
            <img
              src={assetsIcons[name]}
              className="mr-2"
              style={{height: '2rem'}}
              title={name}
              alt={name+'-icon'}
              loading="lazy"
            />
            {t(name)}
          </div>
        )
      })}
    </div>
  );
}

export default Assets;
