import React from "react";
import { Field, reduxForm } from "redux-form";
import BwmInput from "../shared/form/BwmInput";
// import BwmResError from "componentsOld/shared/form/BwmResError";
import { required, minLength4 } from "../shared/form/validators";
import { withTranslation  } from "react-i18next";
import { Button } from "reactstrap";

const LoginForm = ({
  handleSubmit,
  pristine,
  submitting,
  submitCb,
  valid,
  errors,
  changePass,
  t
}) => {
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field
        name="email"
        type="email"
        label={t("Email")}
        className="form-control"
        component={BwmInput}
        validate={[required, minLength4]}
      />
      <Field
        name="password"
        type="password"
        label={t("Password")}
        className="form-control"
        component={BwmInput}
        validate={[required]}
      />
      <div className="row px-3 mt-3 align-items-center justify-content-between" style={{gap: 10}}>
        {changePass !== null &&
          <Button
            color="primary" outline
            className="py-2 px-4"
            onClick={changePass}
            style={{
              borderRadius: 9999,
              fontSize: '1.2rem'
            }}
          >
            {t("Recover password")}
          </Button>
        }

        <Button
          type="submit"
          color="primary"
          className="py-2 px-4"
          style={{
            borderRadius: 9999,
            fontSize: '1.2rem'
          }}
          disabled={!valid || pristine || submitting}
        >
          {t("Login")}
        </Button>
      </div>
      {/*<BwmResError errors={errors} />*/}
    </form>
  );
};

export default withTranslation ()(
  reduxForm({
    form: "loginForm"
  })(LoginForm)
);
