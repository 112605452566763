import React, { useState } from "react";
import { Container, Input, Button } from "reactstrap";
import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom";

import * as actions from "actions";


const LoginForm = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation();
  const history = useHistory()
  const [values, setValues] = useState({
    email: '',
    password: ''
  })
  const { email, password } = values
  const [isLoading, setLoading] = useState(false)


  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    dispatch(actions.login(values, worked => {
      setLoading(false)
      if(worked) history.push('/')
      else toast.error(t("There is no user with that email and that password"))
    }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues(values => ({
      ...values,
      [name]: value
    }))
  }

  return (
    <Container fluid className="p-0">
      <p className="h1 text-primary text-uppercase mb-4">
        {t("Login")}
      </p>
      <form onSubmit={onSubmit} className="w-100">

        <Input
          type="text"
          name="email"
          className="mb-4 customInput"
          value={email}
          onChange={handleInputChange}
          placeholder={t("Email")}
          required
        />

        <Input
          type="password"
          name="password"
          className="mb-4 customInput"
          value={password}
          onChange={handleInputChange}
          placeholder={t("Password")}
          required
        />

        <div className="d-flex justify-content-between align-items-center">
          <NavLink to={'/change-password'} className="text-dark">
            <u>{t('Forgot your password?')}</u>
          </NavLink>
          <Button
            type="submit"
            color="primary"
            className="py-2 px-4"
            style={{
              borderRadius: 9999,
              fontSize: '1.2rem'
            }}
            disabled={isLoading}
          >
            {t("Login")}
          </Button>
        </div>


      </form>
    </Container>
  );
}


export default LoginForm;
