import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import houseImg from '../../media/rentalCard/house.svg'
import facebookIcon from '../../media/rentalCard/facebook.svg'
import copyIcon from '../../media/rentalCard/copy.svg'


export const textColorFromIsland = island => island ? `text-${island.toLowerCase()}` : 'text-primary'

const RentalCard = ({ rental }) => {
  const { t } = useTranslation();

  const {
    // '_id': id,
    code,
    title,
    city,
    category,
    image,
    bedrooms = 0,
    shared: isShared,
    dailyRate,
    minDays,
  } = (rental || {});
  const img = image[0];
  const fullUrl = `${window.location.origin}/rentals/${code}`
  const minPrice = dailyRate * minDays

  const handleCopy = () => {
    navigator.clipboard.writeText(fullUrl).then(() => alert(t("Copied to clipboard")))
  }

  return (
    <div className="card position-relative" style={styles.cardContainer} title={title}>

      <div className="position-absolute d-flex" style={{ top: 7, right: 7 }}>
        <div style={{cursor: "pointer"}} onClick={handleCopy}>
          <img src={copyIcon} alt="copy-icon" style={styles.icons}/>
        </div>
        {/*<a href={`https://api.whatsapp.com/send?text=${fullUrl}`} />*/}
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${fullUrl}`}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img src={facebookIcon} alt="facebook-icon" style={styles.icons} />
        </a>
      </div>

      <Link rel="canonical" to={`/rentals/${code}`} className="d-flex flex-column h-100" style={{textDecoration: 'none'}}>

        <img src={img?.image || img || houseImg} style={styles.cardImg} title={img?.name || ""} alt={img?.name || ""} />
        <div className={`w-100 py-2 px-3 bg-dark ${textColorFromIsland(city?.island)}`}>
          <h2 className="m-0 text-uppercase font-weight-normal" style={{fontSize: '.9rem'}}>
            {/*{t(`${rentalType(isShared)} ${category}`)} &#183;&nbsp; {city?.name}*/}
            {t(`${isShared ? "shared " : ""}${category}`)} &#183;&nbsp; {city?.name}
          </h2>
        </div>
        <div className="w-100 h-100 p-3 bg-white d-flex flex-column justify-content-between border border-top-0 border-light" style={{flexGrow: 1}}>
          <h3 className="text-uppercase font-weight-normal text-dark" style={{fontSize: '1.2rem'}}>
            {title}
          </h3>
          <p className="text-light font-weight-light mb-0" style={{fontSize: '.9rem'}}>
            {`${bedrooms} ${t("bedrooms")} ${t("from")} ${minPrice}€`}<br/>
            <b>sin IVA</b>
          </p>
        </div>

      </Link>
    </div>
  );
};

const styles = {
  cardContainer: {
    width: 300,
    maxWidth: '100%'
  },
  cardImg: {
    borderRadius: '5px 5px 0 0',
    height: 170
  },
  cardBody: {
    borderRadius: '0 0 5px 5px'
  },
  icons: {
    height: 35,
    marginLeft: 7
  }
}

export default RentalCard;
