import React, {useContext, useEffect, useMemo, useState} from "react";
import {Container, Col, Row, FormGroup, Label, Alert, Input, Button} from "reactstrap";
import {useParams} from "react-router-dom";
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { toast } from "react-toastify";
import RangeSlider from 'react-range-slider-input';
import ReactSelect from "react-select";
import Calendar from "react-calendar";
import moment from "moment";
import _ from 'lodash'

import {LanguageContext} from "../hooks/wrappers/LanguageWrapper";

import {disblockAllRental, disblockMonthRental, pauseRental} from "../api/requests";
import useRental from "../hooks/useRental";
import usePlaces from "../hooks/usePlaces";
import useRentalBookings from "../hooks/useRentalBookings";

import {CustomSwitch, CustomInput, CustomSelect} from "../components/shared/FormInput";
import BwmFileUpload from "../componentsOld/shared/form/BwmFileUpload"; // TODO
import rentalCategories from "../componentsOld/utils/rentalCateogries"; // TODO
import PageContainer from "../components/shared/PageContainer";
import authService from "../services/auth-service";
import allAssets from "../resources/assets"
import TermsAndCondsModal from "../components/shared/TermsAndCondsModal";


const MIN_STAY = 30
const MAX_STAY = 360



const RentalUpdate = () => {
  const { t, languages, lang: webLang } = useContext(LanguageContext);

  let { id: rentalId } = useParams();
  const role = useMemo(() => authService.getUser().role, [])
  const [modal, setModal] = useState(false)

  const { rental = [], setRental, refresh, update, isLoading } = useRental({id: rentalId, defaultRental: {minDays: MIN_STAY, maxDays: MAX_STAY}})
  const {
    _id, code, title,
    createdAt, city,
    published, bookings,
    user: rentalUser,
    paused, description,
    street, category,
    bedrooms, guests,
    minDays, maxDays,
    dailyRate, deposit,
    comissionOption,
    assets, datePrices,
    image: images = []
  } = rental

  const [lang, setLang] = useState(webLang)


  const { places, isLoading: isLoadingPlaces } = usePlaces()
  const placeOptions = useMemo(() => {
    return (places || []).map(p => ({ value: p._id, label: (p.cp + " - " + p.name), data: p }))
  }, [places])


  const assetsOptions = useMemo(() => Object.entries(allAssets).map(([name, icon]) => ({
    value: name,
    label: <div className="d-flex align-items-center">
      <img src={icon} alt={name} style={{height: '1.3rem', width: '2.2rem', objectFit: 'contain'}}/>
      <span>{t(name)}</span>
    </div>
  })), [])



  const handleInputChange = (name, value) =>
    setRental(rental => {
      const clone = _.cloneDeep(rental)
      _.set(clone, name, value)
      return clone
    })

  const handleInputEvent = e => {
    const {name, value, checked, type} = e.target
    handleInputChange(name, type === "checkbox" ? checked : value)
  }
  const handleInputIntEvent = e => {
    const {name, value} = e.target
    handleInputChange(name, parseInt(value))
  }


  const handleSubmit = e => {
    e.preventDefault()
    update().then(() => _id && refresh())
  }

  console.log('bookings2', bookings)


  const imagesSlide =
    <FormGroup>
      <Label>{t("Image")}</Label>
      {!!images?.length &&
        <Splide hasTrack={ false }>
          <SplideTrack className="bg-dark">
            {images.map((img, i) =>
              <ImageSlide
                key={i}
                image={img}
                setAsCover={!!i && (() => {
                  const clone = _.cloneDeep(images)
                  const [img] = clone.splice(i, 1)
                  clone.unshift(img)
                  handleInputChange('image', clone)
                })}
                setName={name => {
                  const clone = _.cloneDeep(images)
                  const img = clone[i]
                  clone[i] = { image: img?.image || img, name }
                  handleInputChange('image', clone)
                }}
                remove={() => {
                  const clone = _.cloneDeep(images)
                  clone.splice(i, 1)
                  handleInputChange('image', clone)
                }}
              />
            )}
          </SplideTrack>
        </Splide>
      }
      <BwmFileUpload
        onChange={img => {
          const clone = _.cloneDeep(images)
          clone.push(img)
          handleInputChange('image', clone)
        }}
      />
    </FormGroup>


  return (
    <PageContainer>
      <Container className="py-5 px-5 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>
        <Row className="mb-5 px-4 mx-1 h2 flex-center">
          {t(rental._id ? "Update Rental" : "Create Rental")}
        </Row>

        <Container fluid tag="form" onSubmit={handleSubmit}>

          {_id &&
            <Button
              type="submit"
              color="success"
              className="w-100 mb-4 font-weight-bold"
              disabled={isLoading}
              style={{fontSize: '1.7rem'}}
            >
              {t("Update Rental")}
            </Button>
          }

          {role !== "photo" ? <>

            <Row>
              <Col xs={12} md={8}>
                <CustomInput
                  label={t("Title")}
                  name="title"
                  value={title}
                  onChange={handleInputEvent}
                  disabled={isLoading}
                  required
                />
              </Col>


              <Col xs={12} md={4}>
                {role === "super" &&
                  <CustomSwitch
                    label={t("Published")}
                    checked={published}
                    onChange={v => handleInputChange('published', v)}
                    disabled={isLoading}
                  />
                }
                {["super", "admin", "customer"].includes(role) &&
                  <CustomSwitch
                    label={t("Paused")}
                    checked={paused}
                    onChange={v => handleInputChange('paused', v)}
                    disabled={isLoading}
                  />
                }
              </Col>

              <Col xs={12} md={3}>
                <CustomSelect
                  label={t("Category")}
                  value={category}
                  options={rentalCategories.map(c => ({value: c, label: t(c)}))}
                  onChange={({value}) => handleInputChange('category', value)}
                  disabled={isLoading}
                  required
                />
              </Col>

              <Col xs={12} md={4}>
                <CustomSelect
                  label={t("Town")}
                  value={city?._id}
                  options={placeOptions}
                  onChange={({data}) => handleInputChange('city', data)}
                  disabled={isLoading || isLoadingPlaces}
                  required
                />
              </Col>

              <Col xs={12} md={5}>
                <CustomInput
                  label={t("Street")}
                  name="street"
                  value={street}
                  onChange={handleInputEvent}
                  disabled={isLoading}
                  required
                />
              </Col>

              <Col xs={12} md={6}>
                <div className="ml-auto mb-n5" style={{width: 80}}>
                  <ReactSelect
                    value={{value: lang, label: lang}}
                    options={languages.map(l=>({value: l, label: l}))}
                    onChange={({value}) => setLang(value)}
                  />
                </div>
                <CustomInput
                  label={t("Description")}
                  type="textarea"
                  name={`description.${lang}`}
                  onChange={handleInputEvent}
                  value={description?.[lang] || ""}
                  rows="5"
                  disabled={isLoading}
                  required
                />
              </Col>

              <Col xs={12} md={6} style={{zIndex: 4}}>
                <CustomSelect
                  label={t("Services")}
                  value={(assets || []).map(a => a?.name || a)}
                  options={assetsOptions}
                  style={{zIndex: 9999}}
                  onChange={opts => handleInputChange('assets', (opts || []).map(o => o.value))}
                  closeMenuOnSelect={false}
                  disabled={isLoading}
                  isMulti
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                {imagesSlide}
              </Col>

              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={6}>
                    <CustomInput
                      label={t("Bedrooms")}
                      name="bedrooms"
                      type="number"
                      min={0}
                      value={bedrooms}
                      onChange={handleInputEvent}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col xs={6}>
                    <CustomInput
                      label={t("Guests")}
                      name="guests"
                      type="number"
                      min={1}
                      value={guests}
                      onChange={handleInputEvent}
                      disabled={isLoading}
                      required
                    />
                  </Col>

                  <Col xs={12}>
                    <FormGroup className="my-4 w-100">
                      <Label className="d-flex justify-content-between mb-3">
                        <div>{t("Minimum stay")}</div>
                        <div>{t("Maximum stay")}</div>
                      </Label>
                      <RangeSlider
                        min={MIN_STAY}
                        max={MAX_STAY}
                        step={1}
                        value={[minDays, maxDays]}
                        onInput={([minDays, maxDays]) => setRental(r => ({...r, minDays, maxDays}))}
                        disabled={isLoading}
                      />
                      <div className="d-flex justify-content-between mt-2 px-2">
                        <div>{minDays}</div>
                        <div>{maxDays}</div>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={6}>
                    <CustomInput
                      label={t("Daily Rate")}
                      name="dailyRate"
                      type="number"
                      symbol="€"
                      min={0}
                      value={dailyRate}
                      onChange={handleInputIntEvent}
                      description={!!guests && `${t("Recommended Daily Rate")}: ${guests * 25} €`}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <CustomInput
                      label={t("Deposit")}
                      name="deposit"
                      type="number"
                      symbol="€"
                      min={0}
                      value={deposit}
                      onChange={handleInputIntEvent}
                      disabled={isLoading}
                      required
                    />
                  </Col>

                  <Col xs={12}>
                    <FormGroup tag="fieldset" className="my-4 w-100" style={{fontSize: '1.1rem'}}>
                      <legend className="mb-3" style={{fontSize: '1.3rem'}}>
                        {t("Type of management, select to see applicable rates")}
                      </legend>
                      <FormGroup className="ml-4 font-weight-bold">
                        <Label>
                          <Input
                            checked={comissionOption === 'a'}
                            name="comissionOption"
                            type="radio"
                            onChange={handleInputEvent}
                            value="a"
                            required
                          />{' '}
                          {t("Option A. I prefer to derive the complete management of my property to Balearic Apartments Rentals")}
                        </Label>
                      </FormGroup>
                      <FormGroup className="ml-4 font-weight-bold">
                        <Label>
                          <Input
                            checked={comissionOption === 'b'}
                            name="comissionOption"
                            type="radio"
                            onChange={handleInputEvent}
                            value="b"
                            required
                          />{' '}
                          {t("Option B. I prefer to use the Balearic Apartments Rentals platform to find guests")}
                        </Label>
                      </FormGroup>
                    </FormGroup>
                    {comissionOption &&
                      <Alert color="warning" className="mt-n4 cap" style={{fontSize: '1rem'}}>
                        {comissionOption === "a" ?
                          t("The cost of integral management of the property will be 30% + VAT of the income generated from the property")
                        :
                          t("The cost will be 9% + € 20 deposit management with IBABI + VAT of the total income generated from the property")
                        }
                      </Alert>
                    }
                  </Col>


                </Row>
              </Col>
            </Row>

            {_id &&
              <Row>
                <Col>
                  <RentalCalendar
                    rental={rental}
                    datePrices={datePrices}
                    basePrice={dailyRate}
                    setDatePrices={dPrices => handleInputChange('datePrices', dPrices)}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
            }

          </> : imagesSlide }


          {!!modal && <TermsAndCondsModal content={modal} close={() => setModal(false)} />}
          <Row className="d-block pl-5 mt-4">
            <FormGroup className="mb-0">
              <Label style={{fontSize: '1rem'}}>
                <Input type="checkbox" defaultChecked={_id} disabled={isLoading} required/>{" "}
                <span
                  onClick={() => setModal('t&c')}
                  dangerouslySetInnerHTML={{__html: t("Accept terms and conditions")}}
                />
              </Label>
            </FormGroup>

            <FormGroup>
              <Label style={{fontSize: '1rem'}}>
                <Input type="checkbox" defaultChecked={_id} disabled={isLoading} required/>{" "}
                <span
                  onClick={() => setModal('pp')}
                  dangerouslySetInnerHTML={{__html: t("I have read and accept the privacy policy")}}
                />
              </Label>
            </FormGroup>
          </Row>



          <Button
            type="submit"
            color="success"
            className="w-100 font-weight-bold"
            disabled={isLoading}
            style={{fontSize: '1.7rem'}}
          >
            {_id ? t("Update Rental") : t("Create Rental")}
          </Button>



        </Container>
      </Container>
    </PageContainer>
  );
}



const ImageSlide = ({ image: rawImage, setAsCover, setName, remove }) => {
  const { t } = useContext(LanguageContext);
  const { image: url , name } = rawImage
  const image = url || rawImage

  return (
    <SplideSlide>
      <div style={{height: 600}} className="position-relative">
        <img src={image} className="w-100 h-100" style={{objectFit: 'contain'}} alt={name}/>

        <div className="position-absolute w-100 d-flex justify-content-end p-3" style={{top: 0, gap: 10}}>

          {setAsCover &&
            <Button color="primary" onClick={() => setAsCover()}>
              {t("Set cover")}
            </Button>
          }

          <Button color="danger" onClick={() => remove()}>
            {/*{t("Delete")}*/}
            <i className="fas fa-trash"/>
          </Button>

          <div style={{width: 200}}>
            <Input
              type="text"
              name="name"
              className="px-2"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </div>

        </div>

      </div>
    </SplideSlide>
  )
}


const DATE_FORMAT = 'YYYY/MM/DD'
const RentalCalendar = ({ rental, datePrices = [], basePrice, setDatePrices, isLoading })  => {
  const { t } = useContext(LanguageContext);
  const [value, setValue] = useState()
  const { rentalBookings: bookings = [], refresh, isLoading: isLoadingBookings } = useRentalBookings(rental?._id)

  console.log('bookings', bookings)


  const datesNoValid = useMemo(() =>
    bookings.reduce((r, booking) => {
      if (booking.status !== "inactive" && booking.status !== "declined") {
        let curDate = moment.utc(booking.startAt).startOf('day');
        const stopDate = moment.utc(booking.endAt);
        while (curDate <= stopDate) {
          r.add(moment(curDate).format(DATE_FORMAT));
          curDate = moment(curDate).add(1, 'days');
        }
      }
      return r
    }, new Set())
  , [bookings.length])

  const tileDisabled = ({ date }) => {
    const formattedDate = moment(date).startOf('day').format(DATE_FORMAT);
    return datesNoValid.has(formattedDate);
  }

  const tileClassName = ({ date }) => {
    const formattedDate = moment(date);
    const matchingBooking = (bookings || []).find(booking =>
      booking.status !== "inactive" &&
      booking.status !== "declined" &&
      formattedDate.isBetween(booking.startAt, booking.endAt, 'day', '[]')
    )
    if(matchingBooking) {
      if(matchingBooking.status === 'pending') return 'bg-success text-white disabled'
      if(matchingBooking.status === 'accepted') return 'bg-danger text-white disabled'
      if(matchingBooking.status === 'active') return 'bg-lighter'
    }
  }

  const dailyRate = rental?.dailyRate;
  const paintTile = ({ date }) => {
    const price = datePrices?.[moment(date).format(DATE_FORMAT)]

    return <div style={{fontSize: '1rem', lineHeight: 1}}>
      <div className="mt-2">{price || dailyRate || " - "}€</div>
      {(dailyRate && price) &&
        <small className="text-light"><s>{dailyRate}€</s></small>
      }
    </div>
  }


  const blockRange = e => {
    e.preventDefault();
    const [startDate, endDate] = value;
    pauseRental(rental._id, { "startAt":  moment(startDate).utc(true).toISOString(), "endAt": moment(endDate).utc(true).toISOString() })
      .then(() => {
        toast.success(t("El alquiler se ha pausado en las fechas solicitadas"))
        refresh()
      })
      .catch(e => toast.error(e.response.data.errors[0].detail))
  }

  const unblockRental = e => {
    e.preventDefault();
    disblockAllRental(rental._id)
      .then(() => {
        toast.success(t("Se han eliminado todas las pausas"))
        refresh()
      })
      .catch(e => toast.error(e.response.data.errors[0].detail))
  }

  const monthFormat = 'YYYY-MM'
  const [openMonth, setOpenMonth] = useState(moment().format(monthFormat))
  const unblockMonth = e => {
    e.preventDefault();
    const month = moment("15-"+openMonth, 'DD-'+monthFormat).utc()
    disblockMonthRental(rental._id, month.format('YYYY-MM'))
      .then(() => {
        toast.success(t("Month unblocked successfully"))
        refresh()
      })
      .catch(e => toast.error(e.response.data.errors[0].detail))
  }



  const getDates = () => {
    const [startDate, endDate] = value
    let dates = [];
    let currDate = moment(startDate);
    while(currDate.diff(moment(endDate)) < 0) {
      dates.push(currDate.format(DATE_FORMAT));
      currDate.add(1, 'days')
    }
    return dates
  }

  const askPrice = e => {
    e.preventDefault();
    const dates = getDates()

    const curPrices = dates.reduce((r, date) => {
      if(datePrices?.[date]) r.push(datePrices[date])
      return r
    }, [])

    const max = Math.max(...curPrices, basePrice)
    const newPrice = parseFloat(prompt(t("Set a new price. The highest price for the selected range is") + ` ${max}€.`))

    if(isNaN(newPrice) || newPrice < 0)
      toast.error(t('Invalid price'))
    else
      setDatePrices(dates.reduce((r, date) => ({...r, [date]: newPrice}), {...datePrices}))
  }

  const clearPrices = e => {
    e.preventDefault();
    const dates = getDates()
    setDatePrices(dates.reduce((r, date) => {
      delete r[date]
      return r
    }, {...datePrices}))
  }

  const onMonthChange = ({activeStartDate, view}) => view === 'month' && setOpenMonth(moment(activeStartDate).format(monthFormat))

  const areLoading = isLoading || isLoadingBookings
  return <div>
    <Calendar
      activeStartDate={null}
      tileDisabled={tileDisabled}
      selectRange={true}
      tileContent={paintTile}
      tileClassName={tileClassName}
      value={value}
      onChange={v => setValue(v)}
      minDate={new Date()}
      locale={t("ietf")}
      onActiveDateChange={onMonthChange}
      onDrillDown={onMonthChange}
      className="w-100 rounded-top"
    />
    <div className="d-flex justify-content-between align-items-end flex-wrap pt-2" style={{ gap: "1rem 10rem" }}>

      <div className="d-flex" style={{gap: '.5rem'}}>
        <Button color="ibiza" onClick={unblockMonth} className="px-3 py-1 mt-2" style={{maxWidth: 400}}
                disabled={areLoading || !openMonth}>
          {t("Unlock month")}
        </Button>
        <Button color="danger" onClick={unblockRental} className="px-3 py-1 mt-2" style={{maxWidth: 400}}
                disabled={areLoading}>
          {t("Unlock everything")}
        </Button>
      </div>

      <div className="text-center">
        <small className="font-weight-bold">{t("Selected period")}</small>
        <div className="d-flex" style={{gap: '.5rem'}}>
          <Button color="mallorca" onClick={blockRange} className="px-3 py-1 mt-2" style={{maxWidth: 400}}
                  disabled={areLoading || !value}>
            {t("Block")}
          </Button>
          <Button color="primary" onClick={askPrice} className="px-3 py-1 mt-2" style={{maxWidth: 400}}
                  disabled={areLoading || !value}>
            {t("Price")}
          </Button>
          <Button color="danger" onClick={clearPrices} className="px-3 py-1 mt-2" style={{maxWidth: 400}}
                  disabled={areLoading || !value}>
            {t("Reset price")}
          </Button>
        </div>
      </div>

    </div>
  </div>
}

export default RentalUpdate;
