import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import translate from "../utils/translateStrapi";

const blogUrl = process.env.REACT_APP_BLOG_API_URL


const ArticleCard = ({article}) => {
  const { i18n } = useTranslation();
  const lang = i18n.language
  const { id, image, category, title } = (article || {});
  const langTitle = translate(title, lang)

  return (
    <div className="card" style={styles.cardContainer} title={langTitle}>
      <Link rel="canonical" to={`/blog/article/${id}`} className="d-flex flex-column h-100" style={{textDecoration: 'none'}}>
        <img src={blogUrl + image.formats.small.url} style={styles.cardImg} alt={`${langTitle} card image`} />
        <div className={`w-100 py-2 px-3 bg-dark text-primary`}>
          <h2 className="m-0 text-uppercase font-weight-normal" style={{fontSize: '.9rem'}}>
            {category?.name && translate(category.name, lang)}
          </h2>
        </div>
        <div className="w-100 h-100 p-3 bg-white d-flex flex-column justify-content-between border border-top-0 border-light" style={{flexGrow: 1}}>
          <h3 className="text-uppercase font-weight-normal text-dark" style={{fontSize: '1.2rem'}}>
            {langTitle}
          </h3>
        </div>

      </Link>
    </div>
  );
};

const styles = {
  cardContainer: {
    width: 300,
    maxWidth: '100%'
  },
  cardImg: {
    borderRadius: '5px 5px 0 0',
    height: 170
  },
  cardBody: {
    borderRadius: '0 0 5px 5px'
  },
  icons: {
    height: 35,
    marginLeft: 7
  }
}

export default ArticleCard;
