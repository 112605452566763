import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Col, Collapse, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react'

import {useDispatch, useSelector} from "react-redux";
import * as actions from "actions";

import { IslandContext, islandsProps } from "../../hooks/wrappers/IslandWrapper";
import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";

import russia from "../../media/flags/russia.png"
import uk from "../../media/flags/united-kingdom.png"
import germany from "../../media/flags/germany.png"
import spain from "../../media/flags/spain.png"


const islandsLogos = Object.entries(islandsProps).slice(0, -1).map(([island, { logo }]) => ({island, logo}))


const Navbar = ({ hideIslandsLogos, fixTop, className }) => {
  const history = useHistory()
  const { island, changeIsland } = useContext(IslandContext)
  const { username, role, isAuth } = useSelector(state => state.auth)

  const [count, setCount] = useState(0)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (role === "super")
      actions.getUnpublishedRentalsCount()
        .then(({ count }) => setCount(count));
  }, [role])

  return (
    <Row
      tag="nav"
      style={{ top: 0, zIndex: 4000, fontWeight: 400, lineHeight: 1 }}
      className={"customNavbar m-0 w-100 px-2 px-lg-5 josefine shadow " + (isOpen ? 'isOpen ' : '') + (!fixTop ? 'position-sticky ' : '') + className}
    >

      <Col xs="12" xl="auto" className="d-flex align-items-center mt-2 mb-3">
        <div style={{padding: '8px 0', cursor: 'pointer'}} onClick={() => window.location = "/"}>
          <img
            style={{ height: 80 }}
            src={islandsProps.default.logo}
            alt="balearic apartment rentals - logo"
            loading="lazy"
          />
        </div>
        <span className="ml-auto d-xl-none customNavLink">
          <Hamburger toggled={isOpen} toggle={setOpen} size={20} aria-label="menu"/>
        </span>
      </Col>

      <Col className="align-items-center justify-content-end p-0 d-none d-xl-flex" style={{gap: 15}}>

        {!hideIslandsLogos && <>
          {islandsLogos.map(({logo, island: islandName}, i) =>
            <div key={i} style={{cursor: 'pointer', opacity: islandName === island ? 1 : .4 }} onClick={() => changeIsland(islandName)}>
              <img style={{ height: 60 }} src={logo} alt={`balearic ${islandName} rentals - logo`} loading="lazy" />
            </div>
          )}
          <div className="border-left border-white h-50 mx-2"/>
        </>}

        {isAuth &&
          <div
            style={{cursor: 'pointer'}}
            className="text-right customNavText"
            onClick={() => history.push("/profile")}
          >
            {username}
          </div>
        }

        {isAuth &&
          <ManagementSection role={role} unpublishedRentalsCount={count}/>}
        <AuthButtons isAuth={isAuth}/>

        <div className="border-left border-white h-50 mx-2"/>

        <LanguageSelector />
      </Col>


      <Collapse isOpen={isOpen} className="mx-auto d-xl-none p-0">
        <div className="d-flex flex-column text-center pt-1 pb-3">

          {islandsLogos.map(({logo, island: islandName}, i) =>
            <div key={i} className="py-3 my-3" style={{cursor: 'pointer', opacity: islandName === island ? 1 : .4 }} onClick={() => changeIsland(islandName)}>
              <img style={{height: 70, maxWidth: '100%'}} src={logo} alt={`balearic ${islandName} rentals - logo`} loading="lazy" />
            </div>
          )}

          <div className="flex-center mt-4" style={{gap: 10}}>
            {isAuth &&
              <ManagementSection role={role} unpublishedRentalsCount={count}/>}
            <AuthButtons isAuth={isAuth}/>

            <div className="border-left border-white h-100 mx-2"/>

            <LanguageSelector />
          </div>

        </div>
      </Collapse>

    </Row>
  );
}


const languages = [
  {
    code: 'es',
    flag: spain,
    name: "Español"
  },
  {
    code: 'en',
    flag: uk,
    name: "English"
  },
  {
    code: 'de',
    flag: germany,
    name: "Deutsch"
  },
  {
    code: 'ru',
    flag: russia,
    name: "Русский"
  }
]

const LanguageSelector = () => {
  const { lang, _languages, changeLang } = useContext(LanguageContext)
  const [isOpen, setOpen] = useState(false)
  const selectedLang = useMemo(() => languages.find(l => l.code === lang) || languages[0], [lang])
  return (
    <Dropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle
        className="bg-transparent border-0"
        style={{ cursor: "pointer" }}
      >
        <img
          src={selectedLang.flag}
          height={35}
          alt={selectedLang.name}
        />
      </DropdownToggle>
      <DropdownMenu right>
        {languages.map(({ code, flag, name }, i) =>
          <DropdownItem key={i} active={lang === code} onClick={() => changeLang(code)}>
            <img src={flag} height={35} alt={name}/> {name}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}


const ManagementSection = ({role, unpublishedRentalsCount}) => {
  const {t} = useContext(LanguageContext)
  const history = useHistory()
  const [isOpen, setOpen] = useState(false)

  const adminSections = [
    {
      roles: ["super", "admin", "customer"],
      onClick: () => history.push("/rentals/new"),
      content: t("Create Rental")
    },
    {
      roles: ["super", "admin"],
      onClick: () => history.push("/users"),
      content: t("Users")
    },
    {
      roles: ["super", "admin", "photo", "customer"],
      onClick: () => history.push("/rentals/manage"),
      content: <>{t("Manage Rentals")} {unpublishedRentalsCount > 0 && <span className="badge badge-warning">{unpublishedRentalsCount}</span>}</>
    },
    {
      roles: ["super", "admin", "customer"],
      onClick: () => history.push("/bookings/manage"),
      content: t("Manage Bookings")
    },
    {
      roles: ["super", "admin"],
      onClick: () => history.push("/manage-emails"),
      content: t("Manage Emails")
    },
    {
      roles: ["super", "admin", "photo", "customer"],
      onClick: () => history.push("/profile"),
      content: t("Profile")
    }
  ];

  return (
    <Dropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle className="dropdown-toggle border-0 bg-transparent customNavLink">
        {t("Management")} {unpublishedRentalsCount > 0 && <span className="badge badge-warning">{unpublishedRentalsCount}</span>}
      </DropdownToggle>
      <DropdownMenu right>
        {adminSections.map(({ roles, onClick, content }, index) =>
          roles.includes(role) &&
            <DropdownItem key={index} onClick={onClick} className="py-2">
              {content}
            </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}


const AuthButtons = ({isAuth}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useContext(LanguageContext)

  const handleLogout = () => {
    dispatch(actions.logout())
    history.push("/");
  };

  return (
    isAuth ?
        <a className="text-right customNavLink" style={{cursor: 'pointer'}} onClick={handleLogout} rel="canonical">
          {t("Logout")}
        </a>
      :
        <Link to="/login" className="text-right customNavLink" style={{maxWidth: '6rem'}} rel="canonical">
          {t("Login or SignUp")}
        </Link>
  )
}


export default Navbar;
