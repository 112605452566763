import React, { useContext } from "react";
import { Container, Col, Row } from "reactstrap";

import {LanguageContext} from "../hooks/wrappers/LanguageWrapper";


import PageContainer from "../components/shared/PageContainer";
import useMessages from "../hooks/useMessages";
import MessagesList from "../components/manageEmails/MessagesList";
import SendEmailForm from "../components/manageEmails/SendEmailForm";




const ManageEmails = () => {
  const { t } = useContext(LanguageContext);
  const { messages, loadMessages, isLoading} = useMessages()

  return (
    <PageContainer>
      <Container fluid className="py-5 px-4 px-md-7 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>
        <div className="mb-5 h2 flex-center text-uppercase">
          {t("Manage Emails")}
        </div>

        <Container fluid className="p-0">

          <SendEmailForm afterSend={loadMessages} />

          <div className="mb-7 mb-md-9"/>

          <MessagesList {...{messages, isLoading}} />


        </Container>
      </Container>
    </PageContainer>
  );
}



export default ManageEmails;
