import React, { useState } from "react";
import {Container, Input, Button, Label, FormGroup, Alert, Col} from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import { register } from "../../actions";


const RegisterForm = () => {
  const { t } = useTranslation();
  const history = useHistory()
  const [data, setData] = useState({
    username: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    doesWantAds: false
  })
  const { username, email, password, passwordConfirmation, doesWantAds } = data

  const [error, setError] = useState()


  const validate = () => {
    if (password !== passwordConfirmation)
      return "Passwords must match";
  };


  const onSubmit = (e) => {
    e.preventDefault();
    const foundError = validate()
    if(foundError) setError(t(foundError))
    else {
      // toast.info(t("Sending confirmation email"));
      register({ ...data, role: "customer" })
        .then(() => {
          history.push("/after-signup")
        })
        .catch((err) => {
          console.log(err);
          toast.error(t("This email already exists"));
        });


    }
  }

  const handleInputChange = e => {
    if(error) setError(null)
    const { type, name, value, checked } = e.target
    setData(values => ({
      ...values,
      [name]: type === "checkbox" ? checked : value
    }))
  }

  return (
    <Container fluid className="p-0">
      <p className="h1 text-primary text-uppercase mb-4">
        {t("Register")}
      </p>
      <form onSubmit={onSubmit} className="w-100">

        <Input
          type="text"
          name="username"
          className="mb-4 customInput"
          value={username}
          onChange={handleInputChange}
          placeholder={t("Username")}
          required
        />

        <Input
          type="text"
          name="email"
          className="mb-4 customInput"
          value={email}
          onChange={handleInputChange}
          placeholder={t("Email")}
          required
        />

        <Input
          type="password"
          name="password"
          className="mb-4 customInput"
          value={password}
          onChange={handleInputChange}
          placeholder={t("Password")}
          required
        />

        <Input
          type="password"
          name="passwordConfirmation"
          className="mb-4 customInput"
          value={passwordConfirmation}
          onChange={handleInputChange}
          placeholder={t("Password Confirmation")}
          required
        />

        <FormGroup check>
          <Label check>
            <Input type="checkbox" required/>{' '}
            <Link to="/privacy" className="text-dark">
              <u>{t("I have read and accept the privacy policy")}</u>
            </Link>
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check className="mb-4">
            <Input
              type="checkbox"
              name="doesWantAds"
              value={doesWantAds}
              onChange={handleInputChange}
              required
            /> <span>{t("I would like to receive e-mails with web updates")}</span>
          </Label>
        </FormGroup>

        {error &&
          <Alert color="danger">
            {error}
          </Alert>
        }

        <div className="text-right">
          <Button
            type="submit"
            color="primary"
            className="py-2 px-4"
            style={{
              borderRadius: 9999,
              fontSize: '1.2rem'
            }}
          >
            {t("Register Me")}
          </Button>
        </div>

      </form>
    </Container>
  );
}


export default RegisterForm;
