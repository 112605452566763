import React, { useEffect, useCallback, useContext } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { StripeProvider } from "react-stripe-elements";
import { withTranslation  } from "react-i18next";
import { ToastContainer } from "react-toastify";
import ReactGA from 'react-ga4';

import IslandWrapper, {IslandContext} from "./hooks/wrappers/IslandWrapper";
import LanguageWrapper from "./hooks/wrappers/LanguageWrapper";

//  Redesign ---------
import NewHome from "./pages/Home";
import Profile from "./pages/Profile";
import UserList from "./pages/UserList";
import UserDetails from "./pages/UserDetails";
import AboutUs from "./pages/AboutUs";
import LoginRegister from "./pages/LoginRegister";
import AfterSignup from "./pages/AfterSignup";
import VerifiedEmail from "./pages/VerifiedEmail";
import ForgotPassword from "./pages/ForgotPassword";
import Contact from "./pages/Contact";
import SuccessfulBooking from "./pages/SuccessfulBooking";

import RentalsSearch from "./pages/RentalsSearch";
import RentalDetails from "./pages/RentalDetails";
import MyRentalsList from "./pages/MyRentalsList";
import RentalUpdate from "./pages/RentalUpdate";
import ManageEmails from "./pages/ManageEmails";


import Navbar from "./components/shared/Navbar"
import Footer from "./components/shared/Footer"


//  Old --------------
// import Home from "./componentsOld/rental/rental-listing/Home";
// import RentalSearchListing from "./componentsOld/rental/rental-listing/RentalSearchListing";
// import RentalDetail from "./componentsOld/rental/rental-detail/RentalDetail";
// import RentalCreate from "./componentsOld/rental/rental-create/RentalCreate";
// import Profile from "./componentsOld/profile/Profile";
// import ProfileOtherUser from "./componentsOld/profile/ProfileOtherUser";
// import Login from "./componentsOld/login/Login";
// import Register from "./componentsOld/register/Register";
import Legal from './componentsOld/legal/Legal'
import Cookies from './componentsOld/legal/Cookies'
import PrivacyPolitics from './componentsOld/legal/PrivacityPolitic'
// import Company from './componentsOld/legal/Company'
// import Contact from './componentsOld/contact/Contact'
import Blog from './componentsOld/blog/Blog'
import BlogArticle from './componentsOld/blog/Article'
// import RentalManage from "./componentsOld/rental/rental-manage/RentalManage";
import BookingManage from "./componentsOld/booking/booking-manage/BookingManage";
import BookingConfirmation from "./componentsOld/booking/BookingConfirmation";
import { ProtectedRoute } from "./componentsOld/shared/auth/ProtectedRoute";
// import { LoggedInRoute } from "./componentsOld/shared/auth/LoggedInRoute";
import ScrollToTop from "./componentsOld/utils/ScrollToTop";
// import Header from "./componentsOld/shared/Header";
// import Footer from "./componentsOld/shared/Footer";
import RestorePassw from "./componentsOld/profile/RestorePassw";
import ActiveAccount from "./componentsOld/profile/ActiveAccount";
// import Users from "./componentsOld/profile/Users";
import ModalCookies from "./componentsOld/legal/modalCookies/ModalCookies";


import * as actions from "actions";
import "./styles/index.css";


const store = require("./reducers").init();


ReactGA.initialize('G-HT17SM3GBP');



const App = () => {
  useEffect(() => {
    store.dispatch(actions.checkAuthState());
  }, [])

  return (
    <LanguageWrapper>
      <IslandWrapper>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
          <Provider store={store}>
            <Router />
          </Provider>
        </StripeProvider>
      </IslandWrapper>
    </LanguageWrapper>
  );
}


const Router = () => {
  const { island } = useContext(IslandContext)

  useEffect(() => {
    const path = window.location.pathname + window.location.search
    ReactGA.send({ hitType: "pageview", page: path, title: path });
  }, [window.location.href]);

  // SEO stuff
  const { protocol, hostname } = window.location
  if(
    window.location &&
    hostname !== 'localhost' &&
    window.location.hostname.indexOf('www.') < 0
  ) {
    window.location.href = window.location.href.replace(
      `${protocol}//`,
      `${protocol}//www.`
    )
  }

  const logout = () => store.dispatch(actions.logout());


  const oldHF = useCallback(Component => {
    return props => <>
      {/*<Header logout={logout}/>*/}
      <Navbar />
      <div className="container-fluid py-5">
        <Component {...props} />
      </div>
      <Footer />
    </>
  }, [])

  return (
    <BrowserRouter basename={island} forceRefresh={true}>
      <ScrollToTop>
        <section className="App" style={{ minHeight: "90vh" }}>
          <ToastContainer/>
          <ModalCookies/>
          <Switch>
            {/*<Route*/}
            {/*  exact*/}
            {/*  path="/rentals"*/}
            {/*  render={() => <Redirect to="/"/>}*/}
            {/*/>*/}

            {/* Redesigned pages */}
            <Route exact path="/" component={NewHome}/>
            <Route exact path="/profile" component={Profile}/>
            <Route exact path="/users" component={UserList}/>
            <Route path="/user/:id" component={UserDetails}/>
            <Route exact path="/about-us" component={AboutUs}/>
            <Route exact path="/login" component={LoginRegister}/>
            <Route exact path="/after-signup" component={AfterSignup}/>
            <Route exact path="/activeAccount" component={VerifiedEmail}/>
            <Route exact path="/change-password" component={ForgotPassword}/>
            <Route exact path="/logout" render={() => {
              logout();
              return <Redirect to="/login"/>
            }}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path="/rentals" component={RentalsSearch} />

            <ProtectedRoute exact path="/rentals/manage" component={MyRentalsList} />
            <ProtectedRoute exact path="/rentals/:id/edit" component={RentalUpdate}/>
            <ProtectedRoute exact path="/rentals/new" component={RentalUpdate}/>
            <Route exact path="/manage-emails" component={ManageEmails}/>

            <Route exact path="/rentals/:code" component={RentalDetails}/>


            <Route exact path="/congrats" component={SuccessfulBooking}/>



            {/* Old pages ---------------------------- */}
            {/*<Route exact path="/" component={oldHF(Home)}/>*/}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path="/search/:city?"*/}
            {/*  component={oldHF(RentalSearchListing)}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="/search"*/}
            {/*  component={oldHF(RentalSearchListing)}*/}
            {/*/>*/}
            {/*<Route exact path="/login" component={oldHF(Login)}/>*/}
            {/*<Route exact path="/logout" render={() => {*/}
            {/*  logout();*/}
            {/*  return <Redirect to="/login"/>*/}
            {/*}}/>*/}

            {/*<ProtectedRoute*/}
            {/*  exact*/}
            {/*  path="/rentals/manage"*/}
            {/*  component={oldHF(RentalManage)}*/}
            {/*/>*/}
            <ProtectedRoute
              exact
              path="/bookings/manage"
              component={oldHF(BookingManage)}
            />
            {/*<ProtectedRoute*/}
            {/*  exact*/}
            {/*  path="/rentals/new"*/}
            {/*  component={oldHF(RentalCreate)}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  exact*/}
            {/*  path="/rentals/:id"*/}
            {/*  component={oldHF(RentalDetail)}*/}
            {/*/>*/}

            <Route
              exact
              path="/rentals/:id/confirm"
              component={BookingConfirmation}
            />
            {/*<ProtectedRoute*/}
            {/*  exact*/}
            {/*  path="/rentals/:id/edit"*/}
            {/*  component={oldHF(RentalCreate)}*/}
            {/*/>*/}

            <Route exact path="/restorePassword" component={oldHF(RestorePassw)}/>
            {/*<Route exact path="/activeAccount" component={oldHF(ActiveAccount)}/>*/}
            {/*<Route path="/user/:id" component={oldHF(ProfileOtherUser)}/>*/}

            <Route exact path="/legal" component={oldHF(Legal)}/>
            <Route exact path="/privacy" component={oldHF(PrivacyPolitics)}/>
            {/*<Route exact path="/company" component={oldHF(Company)}/>*/}
            <Route exact path="/cookies" component={oldHF(Cookies)}/>
            {/*<Route exact path="/contact" component={oldHF(Contact)}/>*/}
            {/*<Route exact path="/users" component={oldHF(Users)}/>*/}

            <Route exact path="/blog" component={oldHF(Blog)}/>
            <Route exact path="/blog/article/:id" component={oldHF(BlogArticle)}/>

            {/*<LoggedInRoute*/}
            {/*  exact*/}
            {/*  path="/register"*/}
            {/*  component={oldHF(Register)}*/}
            {/*/>*/}
            {/*<ProtectedRoute path="/profile" logout={logout} component={oldHF(Profile)}/>*/}
            {(window.location.pathname !== "/blog/") && <Redirect to="/"/>}

          </Switch>


        </section>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export { Router }
export default withTranslation ()(App);
