import { useState, useEffect } from 'react'
import { getPlaces } from "../api/requests";

const usePlaces = () => {
  const [places, setPlaces] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getPlaces()
      .then(({data}) => setPlaces(data))
      .catch(e => {
        setPlaces(null)
        console.log(e.message)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return {
    places,
    isLoading
  }
}

export default usePlaces;
