import React, {useContext} from "react";
import {Container} from "reactstrap";

import {LanguageContext} from "../hooks/wrappers/LanguageWrapper";

import PageContainer from "../components/shared/PageContainer";
import ProductExplanation from "../components/home/ProductExplanation";
import RentalMap from "../components/rentalDetails/RentalMap";
import ContactForm from "../components/shared/ContactForm";
import balearicLogo from "../media/brand/Logo_Balearic.svg";

import { islandsProps } from "../hooks/wrappers/IslandWrapper"


const AboutUs = () => {
  const { t } = useContext(LanguageContext);
  const {email} = islandsProps.default
  return (
    <PageContainer>
      <Container fluid className="px-0">

        <div className="mx-auto py-5 py-lg-7 px-5 px-md-9" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1700}}>
          <h2 className="mb-3 text-uppercase">{t('About us')}</h2>
          {t('About us - text')}
        </div>

        <div className="px-0 mx-auto" style={{maxWidth: 1700}}>
          <ProductExplanation altTexts />
        </div>

        <div className="mx-auto py-7 py-lg-9 px-5 px-md-9" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1700}}>
          <h2 className="text-primary mb-4 text-uppercase">{t('What makes us different and unique?')}</h2>
          {t('What makes us different and unique? - text')}

          <div className="d-flex flex-wrap py-5" style={{gap: '4rem'}}>
            <div style={{width: 450, maxWidth: '100%'}}>
              <img
                style={{ width: '100%', objectFit: 'cover' }}
                src={balearicLogo}
                alt="balearic apartment rentals - logo"
                loading="lazy"
              />
            </div>
            <div>
              <p className="mb-3">
                <b className="h5">Balearic Apartments Rentals S.L.</b><br/>
                C/ De Saridakis, 24 -Apart.506<br/>
                07015, Palma de Mallorca<br/>
                Illes Balears<br/>
              </p>
              <p className="mb-3">
                CIF: B56502446
              </p>
              <a href={"mailto:" + email} className="text-dark mb-3 d-block" rel="noopener noreferrer">
                <u>{email}</u>
              </a>
              <a href="tel:+34640208483" className="text-dark mb-3 d-block" rel="noopener noreferrer">
                Tlf: <b>+34 <span style={{fontSize: '1.8rem'}}>640 20 84 83</span></b>
              </a>
            </div>
          </div>

        </div>

        <RentalMap location="spain, mallorca, palma" />
        <Container>
          <ContactForm />
        </Container>
      </Container>
    </PageContainer>
  );
}

export default AboutUs;
