import React, { PureComponent } from 'react';
import { withTranslation  } from "react-i18next";
import { NavLink } from "react-router-dom";

class ModalCookies extends PureComponent {

  state = {
    wantSee: false
  };

  componentDidMount() {
    const save = localStorage.getItem("cookiesBalearic");
    if (save === null || save === false) {
      this.setState({ wantSee: true })
    }
  }

  render() {
    const { t } = this.props;
    const { wantSee } = this.state;
    if (wantSee) {
      return (
        <div style={{ bottom: 0, zIndex: 300 }} className="container-fluid position-fixed bg-dark text-white">
          <div style={{ height: "40px" }} className="row p-4">
            {t("We use Google Analytics cookies to measure and provide statistical analysis of browsing by our" +
              " visitors, and thus improve our supply of products and services. When browsing our website," +
              " we understand that you accept its installation")}
          </div>
          <div className="row justify-content-end p-4">
            <button onClick={() => {
              localStorage.setItem("cookiesBalearic", true);
              this.setState({ wantSee: false })
            }} className="btn btn-success mr-3">
              {t("Accept")}
            </button>
            <NavLink to="/cookies">
              <button className="btn btn-info">
                {t("More info")}
              </button>
            </NavLink>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withTranslation ()(ModalCookies);
