import React, {useMemo, useState} from "react";
import Calendar from "react-calendar";
import {Button, Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import 'moment/locale/es';

import {getRangeOfDates} from "../../utils/stuff";
import {toast, ToastContainer} from "react-toastify";
import calculateRentalPrice from "../../utils/calculateRentalPrice";
moment.locale('es')


const AvailabilityForm = ({ rental }) => {
  const { t } = useTranslation();
  const history = useHistory()
  const {
    _id,
    minDays,
    dailyRate,
    guests: maxGuests,
    bookings
  } = (rental || {})

  const [booking, setBooking] = useState({ startAt: new Date() })
  const { guests, startAt, endAt } = booking


  const bookedOutDates = useMemo(() => {
    let bookedOutDates = []
    if (bookings && bookings.length > 0) {
      bookings.forEach(booking => {
        if(moment(booking.endAt).isBefore(moment())) return
        if (booking.status !== "inactive" && booking.status !== "declined") {
          const dateRange = getRangeOfDates(
            booking.startAt,
            booking.endAt
          );
          bookedOutDates.push(...dateRange);
        }
      });
    }
    return bookedOutDates
  }, [bookings])


  const handleSubmit = e => {
    e.preventDefault()

    const min = Math.max(rental.minDays, 30)

    if(moment(endAt).diff(startAt, 'days')+1 >= min)
      history.push(`${_id}/confirm?booking=${encodeURI(JSON.stringify(booking))}`);
    else
      toast.error(t("You must book for at least X days")(min));

  }

  const shownPrice = useMemo(() => {
    const { startAt, endAt } = booking
    if(!startAt || !endAt) return dailyRate * minDays
    return calculateRentalPrice(rental, startAt, endAt)
  }, [booking])

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-primary text-center text-uppercase mb-2" style={{fontSize: '1.4rem'}}>
        {t('Check availability')}
      </p>
      <Calendar
        onChange={([startAt, endAt]) => {
          const min = Math.max(rental.minDays, 30)
          if((moment(endAt).diff(startAt, 'days')+1 >= min))
            setBooking({
              ...booking,
              startAt: moment(startAt).format('Y/MM/DD'),
              endAt: moment(endAt).format('Y/MM/DD')
            })
          else
            toast.error(t("You must book for at least X days")(min));

        }}
        tileDisabled={({ date }) =>
          bookedOutDates.includes(moment(date).format('Y/MM/DD')
        )}
        value={[startAt && new Date(startAt), endAt && new Date(endAt)]}
        minDate={new Date()}
        className="mb-3 mx-auto"
        selectRange
        navigationLabel={({ date }) => moment(date).format('MMMM YYYY')}
        nextLabel="→"
        prevLabel="←"
        formatShortWeekday={(locale, date) => moment(date).format('dd')}
      />

      <div>
        {t("from")}{" "}
        <span className="text-primary" style={{fontSize: '2.5rem', fontWeight: 500}}>
          {shownPrice}
        </span><span style={{fontSize: '1.5rem'}}>€</span>{" "}
        <b>{t('excluding VAT')}</b>
      </div>


      <Input
        type="number"
        // name="people"
        className="my-3 customInput"
        value={guests || ""}
        min={1}
        max={maxGuests}
        onChange={e => setBooking({...booking, guests: e.target.value})}
        placeholder={t('No. people')}
        required
      />

      <Button
        type="submit"
        color="primary"
        className="py-2 px-4 customInput"
        disabled={!rental || !guests || !startAt || !endAt}
      >
        {t("Reserve place now")}
      </Button>

    </form>
  )
}

export default AvailabilityForm
