import React, { useContext } from "react";
import { Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import PageContainer from "../components/shared/PageContainer";
import { LanguageContext } from "../hooks/wrappers/LanguageWrapper";

const Contact = () => {
  const history = useHistory()
  const { t } = useContext(LanguageContext);
  return (
    <PageContainer>
      <Container fluid className="flex-center text-center" style={{height: '50vh', maxWidth: 800 }}>

        <div>
          <div className="mb-4">
            <i className="fas fa-check-circle text-success mb-3" style={{fontSize: '5rem'}}/><br/>
            <div className="h2 text-uppercase">
              {t("Booking has been succesfuly created!")}
            </div>
            <p style={{fontSize: '1.4rem'}}>
              {t("The charge will only be made after the booking is accepted. If it is denied no money will be charged")}.
            </p>

            <p style={{fontSize: '1.4rem'}}>
              {t("We will not save your payment information")}.
            </p>
          </div>

          <Button
            color="primary"
            className="py-2 px-4"
            style={{
              borderRadius: 9999,
              fontSize: '1.2rem'
            }}
            onClick={() => history.push("/bookings/manage")}
          >
            {t("My Bookings")}
          </Button>
        </div>

      </Container>
    </PageContainer>
  );
}

export default Contact;
