import React, {useState, useEffect, useContext} from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import moment from "moment"
import ReactMarkdown from "react-markdown";

import { getArticle } from "../../api/blogRequests"

import Loading from "../shared/Loading"
import translate from "../utils/translateStrapi"
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";

const blogUrl = process.env.REACT_APP_BLOG_API_URL



const Article = ({ match }) => {
  const { lang } = useContext(LanguageContext)
  const { id } = match.params
  const [loading, setLoading] = useState(true)
  const [article, setArticle] = useState()

  useEffect(() => {
    setLoading(true)
    if(id !== null && id !== undefined) {
      getArticle(id)
        .then(({ data }) => setArticle(data || null))
        .catch(() => setArticle(null))
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line
  }, [id])


  if (loading)
    return <Loading/>
  else if (!article)
    return <Redirect to="/"/>


  const { title, content, image, published_at } = article

  console.log({title, content, lang})

  return (
    <div className="row" style={{ marginTop: -53 }}>
      <div className="container-fluid p-0 article">
        <div style={{...styles.banner, backgroundImage:"url(" + (blogUrl + image.url) + ")"}}>
          <h1 className="text-primary" style={{ fontSize: 'calc(3vh + 3vw)', fontWeight: 800 }}>
            {translate(title, lang)}
          </h1>
        </div>
        <div className="p-4 p-lg-5 pt-4 mx-auto" style={{ maxWidth: 1200 }}>
          <div className="text-grey">
            <ReactMarkdown>
              {translate(content, lang)}
            </ReactMarkdown>
          </div>
          {published_at &&
            <p><time>{moment(published_at).format('MM-DD-yyyy')}</time></p>
          }
        </div>
      </div>

    </div>
  )
}

const styles = {
  banner: {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "600px",
    display: "flex",
    padding: 30,
    alignItems: "end",
    justifyContent: "center"
  }
}


const mapStateToProps = () => {};


export default connect(mapStateToProps)(withRouter(Article));
