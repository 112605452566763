const translation = {
  "ietf": "en",
  "Close": "Close",
  "FirstSurname": "First Surname",
  "SecondSurname": "Second Surname",
  "Unlock everything": "Unlock all",
  "DoesNeedAnInvoice": "Do You need an Invoice?",
  "doesNeedAnInvoice": "Do You want us to send emails with offers?",
  "Accept terms and conditions": "Accept <b>terms and conditions</b>",
  "The parties to this agreement, duly identified in the reservation made via the web Balearicapartments-rentals.com,": "The parties to this agreement, duly identified in the reservation made via the <b>web Balearicapartments-rentals.com</b>,",
  "ONE (purpose of the agreement)": "<b>ONE (purpose of the agreement).</b> The lessor grants the dwelling in lease covered by this agreement, described in the reservation made via the web.",
  "TWO (validity period)": "<p><b>TWO (validity period). </b>The term of the agreement is established for the period indicated in the web reservation (to be confirmed via email), always providing there is a minimum term of one month or 30 days, and any possible extension should be stipulated in writing between the parties.</p><p>For the possible cancellation of the reservation, a cancellation schedule is hereby established with its respective penalties, and which will be as follows:</p><p>a) Notice within the period of 30 days, no penalty.<br/>b) Notice within the period of 15 days, 50% of the amount of the reservation will be lost as penalty.<br/>c) Notice within the period of 5 days, will entail the loss of 100% of the amount of the reservation as penalty.</p><p>Any notice by email via the reservation web will be deemed as made.</p><p>The calculation of the aforementioned deadlines will always be understood as of the day of entry to the property, counting backwards.</p>",
  "THREE (payment)": "<p><b>THREE (payment). </b>The rent set for the season will be stipulated in the reservation made via the web (to be confirmed via email) and will be paid prior to entry and delivery of the keys. </p><p>Any delay in the payment of any quantity whose enforcement results from this agreement will mean that the lessee is automatically in default, without the need for any inquiry or request from the lessor and, consequently, will accrue interest in favour of the lessor party at the rate of 8% per annum, from the date on which those debts are due through the date on which their payment takes place, without prejudice to any legal or out-of-court actions that may be appropriate to obtain the fulfilment of the obligation and/or eviction, the expense of which will be covered by the lessee. </p>",
  "FOUR (Deposit)": "<p><b>FOUR (Deposit). </b>The lessee will provide the lessor at the moment of the reservation with the minimum amount of five hundred Euros (€500.00), since such amount may be increased depending upon the property to be reserved.  This quantity will be used to cover the payment of any losses for the breach of contractual obligations and for any cause giving rise to damages to the property. </p><p>If this quantity is not paid at the moment of the reservation, it will be deemed that the agreement and all of its terms is not accepted, whereby the process for the reservation and contracting of the property may terminate.  The amount of the deposit will be returned within a maximum period of 30 days of the departure, in the bank account of the lessee.</p>",
  "FIVE (applicable regime)": "<b>FIVE (applicable regime). </b>Pursuant to the term of the agreement, the provisions of Spanish Urban Lease Act 29/1994, 24 November [Ley 29/1994, de 24 de noviembre, de Arrendamientos Urbanos] are excluded, and will only be alternatively applied in the absence of the provisions of the agreement itself, and will likewise abide by the provisions of the Spanish Civil Code [Código Civil]. ",
  "SIX (breakdown of expenses)": "<p><b>SIX (breakdown of expenses). </b>The lessee will not withstand any maintenance expense concerning the leased dwelling, providing correct use has been made thereof and that there are no damages, theft or flaws of any type (such as broken glass, locks, mechanisms and switches, tools and similar or comparable utensils of the installations).</p><p>Furthermore, with respect to consumptions of the supply of electricity, water and internet, no amount whatsoever will be paid by the lessee providing a suitable and responsible use thereof is made that does not lead to disproportionate consumptions or damages.</p><p>In principle, the lessee will not withstand any expenses for annual taxes deriving from the property, which will be the expense of the lessor, and should only pay those taxes or charges deriving from the use of the property or those corresponding to the type of dwelling.\nAny expense or taxes that should be paid by the lessee will be detailed on the reservation made on the web; whatever is disproportionate will be invoiced separately according to the information available.</p>",
  "SEVEN (works)": "<p><b>SEVEN (works). </b>No works, renovations or alterations may be undertaken to the exterior and interior elements of the dwelling, as well as to their elements and utensils, without the prior written consent of the lessor, in which case they may be claimed by the latter and must remain on the property. </p><p>The lessee states that he/she is aware of the condition of the property, and that following its inspection he/she is in conformity with it, that it is inhabitable and is delivered in a perfect condition of use and cleanliness, and that he has a maximum period of 6 hours from entry and delivery of the keys to bring any incident or circumstance preventing the correct use of the property to the attention of the owner.</p><p>In turn, the lessee states to have received the dwelling covered by the lease and various furnishings and equipment therein in perfect condition, which should be returned to ownership at the end of the lease in the same condition.  Any repairs, painting, cleaning, restorations, replacements and/or substitutions that must be made for any reason, when caused by abnormal use or by negligence or for damages caused by him/her or dependent individuals, or that occur to the furniture, belongings and equipment by action, omission, wilful misconduct or negligence, will be the expense of the lessee. </p>",
  "EIGHT (specific conditions)": "<p><b>EIGHT (specific conditions). </b>Both parties expressly agree:</p><p>1)\tThat the lessee is directly and exclusive liable, and releases ownership from all liability for any damages, nuisances or any harm that could be caused to persons, animals, objects and to the property deriving from the action or omission thereof, in the leased dwelling as well as the premises in which it is located, whether caused by the lessee or any companions thereof.</p><p>2)\tAt the conclusion of the contractual period stipulated, the lessee should provide ownership with the free disposal of the leased flat, delivering possession and the keys to it. If such delivery does not take place, it will be considered that the lessee has become a tenant at sufferance, whereby the lessor is hereby expressly authorised, as of that moment, to take possession of the aforementioned flat. In any event, for each day that the lessee fails to vacate the flat or apartment following the end of the agreement, he/she will be obligated to pay the lessor the quantity of one hundred Euros per day (€100.00) as compensation. </p><p>3)\tThe lessee should respect and comply at all times with the rules and agreements governing the Owners Association of which the leased flat forms a part, where appropriate.</p><p>4)\tThe lessee undertakes to allow access to the flat by the owner and individuals and/or professionals appointed by him to inspect or verify the condition of the dwelling and its elements, as well as to undertake any type of work or repair that may be necessary in the leased dwelling as well as in the building of which it forms part.</p><p>5)\tInventory: The photo dossier detailed on the web indicates the current condition of the dwelling and its inventory is attached as Appendix I and forms part of this agreement.</p>",
  "NINE (grounds for termination)": "<p><b>NINE (grounds for termination). </b>The party in compliance with its obligations may request the party in breach to terminate the agreement or comply with the obligation as set forth in art. 1124 of the Spanish Civil Code [Código Civil]. Likewise, it may be terminated with full rights for:</p><p>1)\tLack of payment of any quantity by the lessee.<br/>2)\tOccupancy or use of the property by individuals not covered by the agreement.<br/>3)\tAssignment or sublease that has not been expressly authorised.<br/>4)\tDamages caused in the dwelling.<br/>5)\tUndertaking of unplanned works without the consent of the lessor.<br/>6)\tUndertaking activities that are bothersome, harmful, unhealthy or unlawful, or that are not permitted by the By-laws of the Owners Association.<br/>7)\tWhen the primary use of the dwelling ceases to be the temporary lodging of individuals.<br/>8)\tFor de facto or de jure disturbances to the use of the dwelling.<br/>9)\tFor any breach of the stipulations of this agreement.</p>",
  "TEN (applicable law)": "<p><b>TEN (applicable law). </b>The contracting parties submit any questions that may derive from this lease agreement to the jurisdiction of the Courts and Tribunals of Palma de Mallorca. </p><p>For the purpose of notices, the data and address of the lessor and email provided is hereby established, and the address of the lessee is the leased dwelling and his email or telephone number is as indicated in the web reservation.</p><p>After both parties read this document and in conformity therewith, they accept and execute it, in the place and on the date indicated at the time of the web reservation.</p>",








  "--------- ------------------------------------------- ---------": "",
  "------------------------ REDESIGN -----------------------------": "",
  "---------------------------- ----------------------------------": "",


  "------- Rental assets -------": "",
  "wifi": "WiFi",
  "air conditioner": "A.A.",
  "microwave": "Microwave",
  "oven": "Oven",
  "fridge": "Fridge",
  "dryer": "Dryer",
  "pool": "Pool",
  "sauna": "Sauna",
  "terrace": "Terrace",
  "bathtub": "Bathtub",
  "parking": "Parking",
  "barbecue": "Barbecue",
  "towel": "Towels",
  "sheets": "Sheets",
  "cutlery": "Cutlery",
  "washing machine": "Washing M.",
  "animals": "Animals",
  "fans": "Fans",
  "heating": "Heating",

  "tv": "TV",
  "working space": "Working space",
  "fireplace": "Fireplace",
  "outdoor dining": "Outdoor dining",
  "billiard table": "Billiard table",
  "piano": "Piano",
  "exercise equipment": "exercise equipment",
  "beach access": "beach access",
  "ski slope": "ski slope",
  "outdoor shower": "outdoor shower",
  "smoke detector": "smoke detector",
  "first aid kit": "first aid kit",
  "fire extinguisher": "fire extinguisher",



  "---- Home ----": "",
  "Our target market - text": <>
    <p>Balearic Apartments Rentals is aimed at all kinds of people who wish to <b>stay on the island of Mallorca, Menorca and Ibiza</b>.</p>
    <p>All types of homes and flats for seasonal rent. Stay in a house in Mallorca that allows you to feel like an integral part of the community or neighbourhood, wherever you are.</p>
    <p>Properties ready for <b>monthly rental</b> with everything you need to feel at home.</p>
  </>,
  "Our offer - text": <>
    <p><b>Safe rental</b> in <b>Balearic Islands</b>. We have different properties located in different parts of the islands.</p>
    <p><b>Rental homes</b> with charm, cozy and carefully decorated, with a high level of cleanliness upon delivery of the keys.</p>
    <p>Our goal is to provide well-equipped <b>homes for rent</b> with everything necessary to make possible a maximum experience of well-being in the <b>Balearic Islands</b>.</p>
  </>,
  "Rent easily - text": <>
    <p>Our mission is to systematically provide our clients with quality personal attention when renting our <b>houses in the Balearic Islands.</b></p>
    <p>Enjoy your stay and <b>rent easily</b> one of the selected properties of <b>Balearic Apartments.</b></p>
    <p>Book, enter and enjoy.</p>
  </>,


  "------- Rental details -------": "",
  "You must book for at least X days": days => `You must book for atleast ${days} days`,


  "---- Rental search ----": "",
  "rentals search section - mallorca": <>
    <p>Renting <b>apartments in Mallorca</b> can be difficult and <b>renting flats by months</b> is even more complicated.</p>
    <p>There are many reasons why you might want to <b>rent an apartment in Palma de Mallorca for specific seasons</b>.</p>
    <p>What do we offer you at Balearic Apartments Rentals?</p>
    <ol>
      <il>
        <b>We rent houses in Mallorca</b> for:
        <ul>
          <li>Professionals</li>
          <li>Companies</li>
          <li>Workers</li>
        </ul>
      </il>
      <il>
        <b>Apartment rental in Mallorca at €0</b> management fees for the tenant.
      </il>
      <il>
        <b>Apartment rental by months.</b>
      </il>
    </ol>
    <p><em>An opportunity you can't miss! Our specialty is always trying to offer you the best.</em></p>
    <p><em>End your odyssey and stop searching!</em></p>
    <p>Tourism and the <b>rental of apartments for months in Mallorca</b> is booming, with a strong growth of international visitors all looking for the same thing, a <b>apartment in Palma de Mallorca</b> .</p>
    <p><b>Rent a house in Mallorca</b> with Balearic Apartments Rentals is simple and easy.</p>
    <p><em>Register, reserve and dispose.</em></p>
    <p>Renting homes in Mallorca is possible, take a look at our property portfolio with:</p>
    <ul>
      <li>Careful decoration</li>
      <li>Ready to be rented</li>
      <li>Minimum reservation of 30 days</li>
    </ul>
    <br/>
    <p>OWNER INCREASE YOUR INCOME!</p>
    <p>The Balearic Islands are one of the most popular tourist destinations in Europe and <b>monthly apartment rentals</b> are in high demand.</p>
    <p>If you are an owner, you can increase your income by renting your property with Balearic Apartments Rentals.</p>
    <p>Just register, choose the modality with which you prefer to work with us and publish your own ads visible internationally. Simple and fast.</p>
  </>,
  "rentals search section - menorca": <>
    <p>Balearic Apartments Rentals offers you charming properties located in different areas of the Island of Menorca.</p>
    <p>What do we offer you?</p>
    <ol>
      <li>
        <b>We rent houses in Menorca</b> for:
        <ul>
          <li>Professionals</li>
          <li>Companies</li>
          <li>Workers</li>
        </ul>
      </li>
      <li>
        <b>Flat rental in Menorca</b> at €0 management fees for the tenant.
      </li>
      <li>
        <b>Apartment rental for months in Ciutadella, Maó...</b>
      </li>
    </ol>
    <p>Rent, come and enjoy the award-winning turquoise blue water beaches and coves in this little Mediterranean paradise.</p>
    <p><em>Balearic Apartments Rentals is the fastest and easiest option for your search.</em></p>
    <p>OWNER INCREASE YOUR INCOME!</p>
    <p>If you are an owner, you can increase your income by renting your property with Balearic Apartments Rentals.</p>
    <p>The Balearic Islands are one of the most popular tourist destinations in Europe and <b>monthly apartment rentals</b> are in high demand.</p>
    <p>Just register, choose the modality with which you prefer to work with us and publish your own ads visible internationally. Simple and fast.</p>
  </>,
  "rentals search section - ibiza": <>
    <p>Finding a <b>house in Ibiza for rent</b> is not an easy task.</p>
    <p>Balearic Apartments Rentals takes care of finding <b>apartments in Ibiza</b> to offer you. We want to be the fastest solution in your search.</p>
    <p>What do we offer you at Balearic Apartments Rentals?</p>
    <ol>
      <li>
        <b>We rent houses in Ibiza</b> for:
        <ul>
          <li>Professionals</li>
          <li>Companies</li>
          <li>Workers</li>
        </ul>
      </li>
      <li>
        <b>Apartment rental in Ibiza</b> at €0 management fees for the tenant.
      </li>
      <li>
        <b>Apartment rental by months.</b>
      </li>
      <li>
        <b>Easy rental</b> and safe.
      </li>
    </ol>
    <p>With us the <b>rental in Ibiza for workers</b> will not be an impossible mission.</p>
    <p>Come and discover the hidden beaches, crafts, sunsets and a different Ibiza where everyone fits.</p>
    <p>OWNER INCREASE YOUR INCOME!</p>
    <p>If you are an owner, you can increase your income by renting your property with Balearic Apartments Rentals.</p>
    <p><b>Renting an apartment in Ibiza, flat or studio</b> is extremely difficult due to its high demand and low supply, and <b>monthly apartment rentals</b> are in high demand. </p>
    <p>Just register, choose the modality with which you prefer to work with us and publish your own ads visible internationally. Simple and fast.</p>
  </>,


  "------- About us -------": "",
  "About us - text": <>
    <p>Balearic Apartments Rentals is dedicated to offering <b>monthly house rentals</b> with a “minimum 30-day reservation”. Our homes are equipped with the furniture and fixtures necessary for the use and enjoyment of the accommodation, without the provision of additional services during the reservation period.</p>
    <p>Our <b>property management</b> attends at all times to current legislation and especially to the legislation on leases, as established in Law 29/1994 of November 24, on urban leases, and without offering other services or tourist accommodation according to Law 8/2012, of July 19, on tourism in the Balearic Islands, modified by Law 6/2017, of July 31, regarding the marketing of tourist stays in homes. </p>
  </>,

  "Our target market - text 2": <>
    <p>Balearic Apartments Rentals is aimed at companies, workers, professionals and all kinds of people who want a <b>rental in the Balearic Islands</b> on the islands of <b>Majorca, Menorca and Ibiza.</b>< /p>
    <p>We have different types of <b>homes for rent in the Balearic Islands</b> where you can interact as a citizen from the beginning of your stay, because they have everything you need to feel at home. </p>
  </>,
  "Our offer - text 2": <>
    <p><b>House rental for months</b> in different parts of the islands.</p>
    <p><b>Apartments for months</b> cozy and carefully decorated. In addition, we guarantee that the rental of your <b>house in the Balearic Islands</b> meets a high level of cleanliness upon delivery of the keys.</p>
    <p>We provide you with a <b>rental house in the Balearic Islands for months.</b> Homes well equipped with everything you need and that enable a maximum experience of well-being.</p>
  </>,

  "What makes us different and unique? - text": <>
    <p>We are known for the quality and personal warmth we offer each tenant.</p>
    <p>We have <b>apartments for months</b> in the <b>Balearic Islands</b>, where all you have to do is book, enter and enjoy your stay.</p>
    <p>With us, any person, family, professional or company interested in <b>renting a house</b> or in a <b>temporary flat rental</b> will find a friendly, cordial and familiar treatment. </p>
    <p>“We enjoy making you feel as comfortable as in your own home with homes that have everything you need.”</p>
    <p><b>Rent easy!</b> With us <b>renting a house in the Balearic Islands</b> is not a stressful process, quite the opposite.</p>
    <p>Personalized attention, service and quality. <b>The real estate rental</b> that you have always wanted.</p>
    <p>Ask us for any clarification or additional information. Our team will be willing to assist you by phone or web at any time.</p>
  </>

}

export default translation

