import { useState, useEffect } from 'react'
import { getUser } from "../api/requests";

const defaultUser = {
  username: "",
  email: "",
  dni: "",
  dniImageURL: "",
  name: "",
  firstSurname: "",
  secondSurname: "",
  city: "",
  country: "",
  phone: "",
  holder: "",
  bank: "",
  iban: "",
  swift: "",
  active: true,
  doesNeedAnInvoice: false,
  doesWantAds: false,
}

const useUser = id => {
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    loadUser()
  }, [id])

  const loadUser = () => {
    if(!id) return
    setLoading(true)
    return getUser(id)
      .then(({data}) => setUser({...defaultUser, ...data}))
      .catch(e => {
        if (e.response && e.response.status === 401)
          setUser(defaultUser)
        else {
          setUser(null)
          console.log(e.message)
        }
      })
      .finally(() => setLoading(false))
  }

  return {
    user,
    setUser,
    loadUser,
    isLoading
  }
}

export default useUser;
