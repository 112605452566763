import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'actions';

import { MapWithGeocode } from '../shared/GoogleMap';


const RentalMap = ({ location }) => {
  const { isReloading } = useSelector(state => state.map)
  const dispatch = useDispatch()

  return (
    <MapWithGeocode
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB_INsMn0lH6xEeRNlsZyWFKdu3NsJXjKE&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} className="w-100" />}
      containerElement={<div style={{ height: `450px` }} className="w-100" />}
      mapElement={<div style={{ height: `100%` }} className="w-100" />}
      location={location}
      isReloading={isReloading}
      mapLoaded={() => dispatch(actions.reloadMapFinish())}
    />
  )
}


export default RentalMap;
