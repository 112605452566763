import { useState, useEffect } from 'react'
import { getArticles } from "../api/blogRequests";

const useArticles = params => {
  const [articles, setArticles] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getArticles({_sort: 'published_at:DESC', ...params})
      .then(({data}) =>
        setArticles(data.length > 0 ? data : null)
      )
      .catch(e => {
        setArticles(null)
        console.log(e.message)
      })
      .finally(() => setIsLoading(false))
  }, [params])

  return {
    articles,
    isLoading
  }
}

export default useArticles;
