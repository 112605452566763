import React, { useContext, useEffect } from "react";
import axiosService from "services/axios-service";
import authService from "services/auth-service";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as actions from "actions";

import BwmFileUpload from "../../componentsOld/shared/form/BwmFileUpload"; // TODO

import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import useUser from "../../hooks/useUser";
import RentalManageCard from "../shared/RentalManageCard";
import {Link} from "react-router-dom";


const axiosInstance = axiosService.getInstance();


const generatePassword = () => {
  const length = 8
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};


const Profile = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useContext(LanguageContext)

  const {
    userId,
    onSave = obj => null,
    editable = true,
    showBancData = true,
    canDeleteUser = true,
    canChangePass = true,
    isProfilePage = true,
    showRentals
  } = props

  const { user, setUser, isLoading, loadUser } = useUser(userId || 'me')
  const {
    _id: id,
    bank,
    city,
    country,
    dni,
    email,
    dniImageURL,
    firstSurname,
    holder,
    iban,
    name,
    phone,
    secondSurname,
    swift,
    username,
    // doesNeedAnInvoice,
    doesWantAds,
    rentals
  } = (user || {});

  const logout = () => {
    dispatch(actions.logout())
    history.push("/");
  };

  const validateUserData = user => {
    const { bank, city, country, dni, email, dniImageURL, firstSurname, holder, iban, name, phone, secondSurname, swift, username } = user;
    if(email && username)
      return {
        sesion: true,
        all: bank && dniImageURL && city && country && dni && email && firstSurname && holder && iban && name && phone && secondSurname && swift && username,
        booking: dniImageURL && city && country && dni && email && firstSurname && name && phone && secondSurname && username
      }

    if(isProfilePage) logout()
    else return { booking: false, all: false, sesion: false };
  };

  useEffect(() => {
    if(user) onSave(validateUserData(user));
  }, [user])



  const updateProfile = user => {
    toast.info(t("Updating profile") + "...");
    axiosInstance.patch("users/" + user._id, {
      ...user,
      userId
    }).then(({ data }) => {
      onSave(validateUserData(data));
      toast.success(t("Updated profile"));
    }).catch(() => {
      toast.error(t("Update failed"));
    });
  };

  const registerUser = user => {
    const password = generatePassword();
    return axiosInstance
      .post("/users/register", {
        ...user,
        role: "customer",
        password,
        passwordConfirmation: password
      })
      .then(() =>
        axiosInstance
          .post("/users/auth", { email: user.email, password })
          .then(res => res.data)
          .then(token => {
            authService.saveToken(token);
          })
      )
      .then(loadUser)
      .catch(console.log);
  };


  const deleteUser = event => {
    event.preventDefault();
    axiosInstance.delete("users/delete", user).then(({ data }) => {
      onSave(validateUserData(data).all);
      if (window.confirm(t("Are you sure you want to delete this account? You will lose all your data and you will not be able to recover them")))
        logout()
    });
  };

  const changePassword = event => {
    event.preventDefault();
    actions.sendEmailRestorePassword(email).then(() =>
      alert(t("An email has been sent with the necessary redirection to change the password"))
    )
  };

  const handleChange = (name, value) => {
    if (!editable) return
    setUser(user => ({...user, [name]: value}))
  }

  const handleInputChange = e => {
    const { name, value, checked, type } = e.target;
    handleChange(name, type === "checkbox" ? checked : value)
  };


  const handleSubmit = event => {
    event.preventDefault();
    if (user && !id) registerUser(user);
    else updateProfile(user);
  };


  const inputsDisabled = !editable || isLoading
  return (
    <Container fluid className="p-0">
      <Row tag="form" onSubmit={handleSubmit}>

        <Col xs={12} className="mb-3 px-4 mx-1">
          <p className="h2">{t("Profile")}</p>
        </Col>

        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="username">{t("Username")}</Label>
          <Input
            name="username"
            className="customInput"
            value={username}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="email">{t("Email")}</Label>
          <Input
            type="email"
            name="email"
            className="customInput"
            value={email}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>


        <Col xs={12} className="py-7 mt-n4">
          <div className="w-100 border-top border-ibiza"/>
        </Col>



        <Col xs={12} className="mb-3 px-4 mx-1">
          <p className="h2 text-primary">{t("User info")}</p>
        </Col>

        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="name">{t("Name")}</Label>
          <Input
            name="name"
            className="customInput"
            value={name}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="firstSurname">{t("FirstSurname")}</Label>
          <Input
            name="firstSurname"
            className="customInput"
            value={firstSurname}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="secondSurname">{t("SecondSurname")}</Label>
          <Input
            name="secondSurname"
            className="customInput"
            value={secondSurname}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="phone">{t("Phone")}</Label>
          <Input
            name="phone"
            className="customInput"
            value={phone}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="city">{t("City")}</Label>
          <Input
            name="city"
            className="customInput"
            value={city}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="country">{t("Country")}</Label>
          <Input
            name="country"
            className="customInput"
            value={country}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          <Label for="dni">{`DNI / NIE / ${t("Passport")}`}</Label>
          <Input
            name="dni"
            className="customInput"
            value={dni}
            onChange={handleInputChange}
            disabled={inputsDisabled}
            required
          />
        </Col>
        <Col xs={12} md={6} className="px-4 py-3">
          {editable &&
            <BwmFileUpload
              label={t("DNI / NIE / Passport Photocopy")}
              onChange={img => handleChange('dniImageURL', img)}
            />
          }
          {dniImageURL && (
            <img
              alt={t("DNI / NIE Photocopy")}
              style={{ maxWidth: "100%" }}
              src={dniImageURL}
            />
          )}
        </Col>


        {showBancData && <>
          <Col xs={12} className="py-7 mt-n4">
            <div className="w-100 border-top border-ibiza"/>
          </Col>


          <Col xs={12} className="mb-3 px-4 mx-1">
            <p className="h2 text-primary">{t("Payment details")}</p>
          </Col>

          <Col xs={12} md={6} className="px-4 py-3">
            <Label for="holder">{t("Holder")}</Label>
            <Input
              name="holder"
              className="customInput"
              value={holder}
              onChange={handleInputChange}
              disabled={inputsDisabled}
              required
            />
          </Col>
          <Col xs={12} md={6} className="px-4 py-3">
            <Label for="bank">{t("Bank")}</Label>
            <Input
              name="bank"
              className="customInput"
              value={bank}
              onChange={handleInputChange}
              disabled={inputsDisabled}
              required
            />
          </Col>
          <Col xs={12} md={6} className="px-4 py-3">
            <Label for="iban">{t("Iban")}</Label>
            <Input
              name="iban"
              className="customInput"
              value={iban}
              onChange={handleInputChange}
              disabled={inputsDisabled}
              required
            />
          </Col>
          <Col xs={12} md={6} className="px-4 py-3">
            <Label for="swift">{t("Bic / Swift")}</Label>
            <Input
              name="swift"
              className="customInput"
              value={swift}
              onChange={handleInputChange}
              disabled={inputsDisabled}
              required
            />
          </Col>
        </>}

        {/*<Col xs={12} className="px-4 pt-3 pb-5">*/}
        {/*  <Label check>*/}
        {/*    <Input*/}
        {/*      type="checkbox"*/}
        {/*      name="doesNeedAnInvoice"*/}
        {/*      checked={doesNeedAnInvoice}*/}
        {/*      onChange={handleInputChange}*/}
        {/*    />{' '}*/}
        {/*    {t("DoesNeedAnInvoice")}*/}
        {/*  </Label>*/}
        {/*</Col>*/}

        <Col xs={12} className="p-4 pt-5">
          <Label check>
            <Input
              type="checkbox"
              name="doesWantAds"
              checked={doesWantAds}
              onChange={handleInputChange}
              disabled={inputsDisabled}
            />{' '}
            {t("I would like to receive e-mails with web updates")}
          </Label>
        </Col>

        {canChangePass &&
          <Col xs="12" md="auto" className="p-2">
            <Button type="button" onClick={changePassword} color="success" outline className="py-2 px-5 customInput" disabled={inputsDisabled}>
              {t("Change Password")}
            </Button>
          </Col>
        }
        {canDeleteUser &&
          <Col xs="12" md="auto" className="p-2">
            <Button type="button" onClick={deleteUser} color="danger" outline className="py-2 px-5 customInput" disabled={inputsDisabled}>
              {t("Delete profile")}
            </Button>
          </Col>
        }
        {editable &&
          <Col xs="12" md="auto" className="p-2 ml-auto">
            <Button type="submit" color="primary" className="py-2 px-5 customInput" disabled={inputsDisabled}>
              {t("Update Profile")}
            </Button>
          </Col>
        }

      </Row>

      {showRentals &&
        <Row className="my-5">
          <Col xs={12} className="mb-3 px-4 mx-1">
            <p className="h2">{t("Rentals")}</p>
          </Col>

          <Col xs={12} className="px-4 py-3">
            <Row style={{gap: 10}}>
              {(rentals || []).map((rental, i) =>
                <RentalManageCard
                  key={i}
                  user={user}
                  rental={rental}
                  afterDelete={loadUser}
                />
              )}
            </Row>
            {!isLoading && !rentals?.length && (
              <div className="text-center">
                {t(`You dont have any rentals currenty created. If you want advertised your property, please follow this link`)}.
                <Link
                  rel="canonical"
                  style={{ marginLeft: "10px" }}
                  to="/rentals/new"
                >
                  <Button color="warning" size="lg" className="mt-4 text-white">
                    {t("Create Rental")}
                  </Button>
                </Link>
              </div>
            )}
          </Col>

        </Row>
      }
    </Container>
  );
}

export default Profile;
