import React from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import visa from 'icons/visa.jpg'
import mastercard from 'icons/mastercard.jpg'
import americanExpress from 'icons/americanExpress.jpg'
import {
  createOptions,
  formStyles,
  buttonStyles,
  paragraphStyle
} from "./styles";
import { withTranslation  } from "react-i18next";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";


class CheckoutForm extends React.Component {
  state = {
    success: false,
    error: undefined
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.client_secret !== this.props.client_secret) {
      if (this.props.client_secret) {
        const { stripe, elements, client_secret, t, history, needsConfirmation } = this.props;
        const cardElement = elements.getElement('card');
        if(needsConfirmation){
          stripe.confirmCardPayment(
            client_secret,
            {
              payment_method: { card: cardElement },
              return_url: (window.location.origin + "/bookings/manage")
            },
            // Disable the default next action handling.
            { handleActions: false }
          ).then(function ({ paymentIntent, ...other }) {
            console.log({ paymentIntent, ...other })
            const { next_action } = paymentIntent;
            if (next_action && next_action.redirect_to_url) {
              window.location.href = next_action.redirect_to_url.url
            } else {
              // toast.success(t("Booking has been succesfuly created!"), {
              //   // onClose: () => history.push("/bookings/manage")
              // });
              history.replace("/congrats")
            }
          });
        } else {
          // toast.success(t("Booking has been succesfuly created!"), {
          //   // onClose: () => history.push("/bookings/manage")
          // });
          history.replace("/congrats")
        }
      }
    }
  }


  handleSubmit = e => {
    const { stripe, setPaymentToken, elements } = this.props;
    e.preventDefault();

    if (stripe) {
      const cardElement = elements.getElement('card');

      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {},
      }).then(({ paymentMethod }) => {
        if(paymentMethod) {
          this.setState({ success: true });
          setPaymentToken(paymentMethod.id)
        } else toast.error('Invalid payment method')
      });


      // stripe.createToken().then(payload => {
      //   if (payload.error) {
      //     setPaymentToken(undefined);
      //     return this.setState({ error: payload.error.message });
      //   } else {
      //     this.setState({ success: true });
      //     setPaymentToken(payload.token.id);
      //   }
      // });


    } else {
      console.error("Stripe.js hasn't loaded yet!");
    }
  };

  render() {
    const { t } = this.props;
    const { error, success } = this.state;

    return (
      <form {...formStyles()} onSubmit={this.handleSubmit}>
        <CardElement {...createOptions()} />
        <p {...paragraphStyle()}>{t("You will be not charged yet")}</p>

        {error && (
          <div className="alert alert-danger alert-payment">{error}</div>
        )}
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            {success ? (
              <div className="alert alert-info" role="alert">
                {t("The credit card has been processed sucessfully")}
              </div>
            ) : (
              <Button
                color="primary"
                className="px-3"
                style={{
                  borderRadius: 9999,
                  fontSize: '1.2rem'
                }}
              >
                {t("Confirm")}
              </Button>
            )}
            <div>
              <img height="30px" src={visa} alt=""/>
              <img height="30px" src={mastercard} alt=""/>
              <img height="30px" src={americanExpress} alt=""/>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation ()(injectStripe(withRouter(CheckoutForm)));
