import React from "react";
import { Container, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import ForgotPasswordForm from "../components/loginRegister/ForgotPasswordForm";
import PageContainer from "../components/shared/PageContainer";


const LoginRegister = () => {
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth)

  if(auth.isAuth) return <Redirect to="/" />
  return (
    <PageContainer>
      <Container className="py-5 px-4 px-md-0">
        {/*<p className="mb-4 h1 text-uppercase" style={{lineHeight: 1}}>{t("Forgot your password?")}</p>*/}
        <Container fluid>
          <Row>
            <Col className="p-0 py-8 py-lg-5">
              <ForgotPasswordForm />
            </Col>
          </Row>
        </Container>
      </Container>
    </PageContainer>
  );
}

export default LoginRegister;
