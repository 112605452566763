import React, { useContext } from 'react';
import {FormGroup, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import ReactSelect, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable';
import Switch from "react-switch";
import ReactQuill from "react-quill";

import 'react-quill/dist/quill.snow.css';
import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";

export const CustomInput = ({label, name, symbol, description, style, ...props}) => {
  return (
    <FormGroup className="my-4" >
      <Label for={name}>{label}</Label>
      <InputGroup>
        {symbol && <InputGroupAddon addonType="prepend">{symbol}</InputGroupAddon>}
        <Input {...props} name={name} style={{...style, fontSize: '1.2rem'}} />
      </InputGroup>
      {description &&
        <div className="text-muted mt-1" style={{fontSize: '.9rem'}}>
          {description}
        </div>
      }
    </FormGroup>
  );
}

export const CustomSwitch = ({label, ...props}) => {
  return (
    <FormGroup className="d-flex justify-content-between my-3">
      <Label className="mr-2">{label}</Label>
      <Switch {...props} />
    </FormGroup>
  );
}

export const CustomSelect = ({ icon, label, required, value, isMulti, options, onChange, addSelectAll, isCreatable, ...props }) => {
  const { t } = useContext(LanguageContext);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {icon}
      </components.DropdownIndicator>
    );
  };

  const groupedOptions = (options || []).reduce((r, o) => [...r, ...(o?.options || [o])], [])

  const existsValue = Array.isArray(value) ? value.length > 0 : !!value;
  const Component = isCreatable ? CreatableSelect : ReactSelect;

  return (
    <FormGroup className="my-4">
      {!!label && <Label>{label}</Label>}
      <Component
        components={{ DropdownIndicator }}
        {...props}
        value={isMulti ?
          value && (value || []).map(v =>
            groupedOptions.find(({value}) => value === v)
          ||
            { value: v, label: v, isNew: true }
          )
        :
          value && options.find((o => o.value === value))
        }
        onChange={opts =>
          !!(addSelectAll && (opts || []).find(o => o.value === 'all')) ?
            onChange(groupedOptions.filter(o => !o?.isDisabled))
          :
            onChange(opts)
        }
        options={[...(addSelectAll ? [{ value: 'all', label: t('Add all') }] : []), ...options]}
        isMulti={isMulti}
      />
      {!props.isDisabled && !!required &&
        <input
          className="position-absolute w-100"
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, padding: 0, }}
          value={existsValue ? "Exists" : ""}
          onChange={() => null}
          required
        />
      }
    </FormGroup>
  );
}

export const CustomQuill = ({label, value, onChange, description, style, className, disabled, ...props}) => {
  return (
    <FormGroup className="my-4">
      <Label for={name}>{label}</Label>
      <InputGroup>
        <ReactQuill
          {...props}
          theme="snow"
          value={value}
          onChange={onChange}
          className={"w-100 mb-4 " + className}
          style={{...style, fontSize: '1.2rem'}}
          readOnly={disabled}
        />
      </InputGroup>
      {description &&
        <div className="text-muted mt-1" style={{fontSize: '.9rem'}}>
          {description}
        </div>
      }
    </FormGroup>
  );
}
