import React, { useState } from "react";
import { Container, Input, Button } from "reactstrap";
import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux'

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom";

import * as actions from "actions";


const ForgotPasswordForm = () => {
  // const dispatch = useDispatch()

  const { t } = useTranslation();
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [isLoading, setLoading] = useState(false)


  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    actions.sendEmailRestorePassword(email)
      .then(() => {
        history.push('/login')
        alert(t("An email has been sent with the necessary redirection to change the password"));
      })
      .catch(e =>
        alert(t("Invalid email"))
      )
      .finally(() => setLoading(false))
  }


  return (
    <Container fluid className="p-0">
      <p className="h1 text-primary text-uppercase mb-4">
        {t("Forgot your password?")}
        {/*{t("Recover password")}*/}
      </p>
      <form onSubmit={onSubmit} className="w-100">

        <Input
          type="text"
          name="email"
          className="mb-4 customInput"
          value={email}
          onChange={e => setEmail(e?.target?.value || null)}
          placeholder={t("Email")}
          required
        />

        <div className="text-right">
          <Button
            type="submit"
            color="primary"
            className="py-2 px-4"
            style={{
              borderRadius: 9999,
              fontSize: '1.2rem'
            }}
            disabled={isLoading}
          >
            {t("Submit")}
          </Button>
        </div>

      </form>
    </Container>
  );
}


export default ForgotPasswordForm;
