import React from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";

import LoginForm from "../components/loginRegister/LoginForm";
import RegisterForm from "../components/loginRegister/RegisterForm";
import PageContainer from "../components/shared/PageContainer";


const AfterSignup = () => {
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth)

  if(auth.isAuth) return <Redirect to="/" />
  return (
    <PageContainer>
      <Container className="py-9 px-4 px-md-0 text-center flex-center">
        <div style={{maxWidth: 800, lineHeight: 1}}>
          <i className="fas fa-check text-success mb-3" style={{fontSize: "8rem"}}/>
          <p className="mb-3 h2 text-uppercase text-primary" style={{lineHeight: 1}}>
            {t("Registration completed successfully")}
          </p>
          <p className="h4">{t("You will receive an account activation email shortly")}</p>
          <p className="h4 text-danger" style={{lineHeight: 1}}>
            {t("Remember to check your spam folder")}
          </p>

          <NavLink to="/login">
            <Button color="primary mt-3 text-uppercase">{t("Return")}</Button>
          </NavLink>
        </div>
      </Container>
    </PageContainer>
  );
}

export default AfterSignup;
