import React, {useContext, useMemo, useState} from "react";
import {Container, Col, Row, Input} from "reactstrap";
import {Link} from "react-router-dom";

import {LanguageContext} from "../hooks/wrappers/LanguageWrapper";

import useUserRentals from "../hooks/useUserRentals";

import PageContainer from "../components/shared/PageContainer";
import RentalManageCard from "../components/shared/RentalManageCard";
import authService from "../services/auth-service";
import usePagination from "../hooks/usePagination";
import normalize from "../utils/normalize";


const MyRentalsList = () => {
  const { t } = useContext(LanguageContext);
  const { userRentals = [], refresh, isLoading } = useUserRentals()
  const user = useMemo(() => authService.getUser(), [])
  const [search, setSearch] = useState()

  const filteredRentals = useMemo(() => {
    if(!search) return userRentals
    const normalizedSearch = normalize(search)
    return (userRentals || []).filter(r => {
      const searchableFields = [r.code, r?.city?.name, r?.city?.island, r?.title, r?.street, r?.user?.name, r?.user?.firstSurname, r?.user?.secondSurname].map(f => normalize(f)).join(' ')
      return searchableFields.includes(normalizedSearch)
    })
  }, [userRentals, search])




  const {
    pageItems: pageRentals,
    PageSelector
  } = usePagination(filteredRentals, { pageSize: 12 })
  console.log('filteredRentals', filteredRentals, pageRentals)

  return (
    <PageContainer>
      <Container className="py-5 px-5 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>
        <Row>
          <Col xs={12} className="mb-3 px-4 mx-1 d-flex justify-content-between">
            <p className="h2">{t("My Rentals")} {!!filteredRentals && `(${filteredRentals?.length})`}</p>
            <div>
              <Input
                type="text"
                className="customInput"
                value={search}
                onChange={e => setSearch(e?.target?.value || '')}
                placeholder={t('Search') + '...'}
              />
            </div>
          </Col>

          <Col xs={12} className="px-4 py-3">
            <Row className="justify-content-center" style={{gap: 10}}>
              {(pageRentals || []).map((rental, i) =>
                <RentalManageCard
                  key={i}
                  user={user}
                  rental={rental}
                  afterDelete={refresh}
                />
              )}
            </Row>
            {!isLoading && userRentals.length === 0 && (
              <div className="alert alert-warning">
                {t(`You dont have any rentals currenty created. If you want advertised your property, please follow this link`)}.
                <Link
                  rel="canonical"
                  style={{ marginLeft: "10px" }}
                  className="btn btn-bwm"
                  to="/rentals/new"
                >
                  {t("Create RentalDetails")}
                </Link>
              </div>
            )}
          </Col>
        </Row>

        <PageSelector />

      </Container>
    </PageContainer>
  );
}

export default MyRentalsList;
