import {useState, useEffect, useContext} from 'react'
import {
  getMe, getRental,
  getRentalByCode,
  updateRental as updateRentalRequest,
  createRental as createRentalRequest
} from "../api/requests";
import {LanguageContext} from "./wrappers/LanguageWrapper";
import * as actions from "../actions";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";



const useRental = ({id, code, defaultRental = {}}) => {
  const history = useHistory()
  const { t } = useContext(LanguageContext);
  const [rental, setRental] = useState(defaultRental)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if(id || code) refresh({id, code})
    else setLoading(false)
  }, [id, code])

  const refresh = () => {
    if(!(id || code)) return
    setLoading(true);
    (id ? getRental(id) : getRentalByCode(code))
      .then(({data}) => {
        const description = data?.description
        if(typeof description === 'string')
          data.description = { es: description }
        setRental({...defaultRental, ...data})
      })
      .catch(e => {
        setRental(null)
        toast.error(e.response.data.errors[0].detail)
      })
      .finally(() => setLoading(false))
  }


  const update = () => {
    if (!rental || isLoading) return;
    setLoading(true)
    return (rental?._id ? updateRental : createRental)(rental, t, history)
      .finally(() =>
        setLoading(false)
      )
      .catch(e => toast.error(e.response.data.errors[0].detail))
  }



  return {
    rental,
    setRental,
    refresh,
    update,
    isLoading
  }
}

const parseRental = rental => {
  delete rental.user
  delete rental.bookings
  if(rental?.city) rental.city = rental.city.cp
  if(!rental.description)
    rental.description = {}
  return rental
}

export const createRental = (rental, t, history) => {
  return getMe()
    .then(({ data }) => {
      const { dni, name, dniImageURL, firstSurname, secondSurname, city, country, phone, holder, bank, iban, swift } = data;
      if (dni && name && dniImageURL && firstSurname && secondSurname && city && country && phone && holder && bank && iban && swift) {
        createRentalRequest({...parseRental(rental), user: data})
          .then(({data}) => {
            toast.success(t("The rental has been succesfuly created"))
            history.push(`/rentals/${data._id}/edit`)
          })
          .catch(e => toast.error(e.response.data.errors[0].detail))
      } else alert("Necesitas tener toda la información de perfil completada")
    })
    .catch(() => {
      alert("Necesitas tener toda la información de perfil completada")
    })
}

export const updateRental = (rental, t) => {
  const newRental = parseRental(_.cloneDeep(rental))
  return updateRentalRequest(rental._id, newRental)
    .then(() => toast.success(t("El alquiler se ha actualizado correctamente")))
}


export default useRental;
