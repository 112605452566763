import React, { PureComponent } from 'react';
import { withTranslation  } from "react-i18next";

class PrivacityPolitic extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <h3 className="my-4">
          {t("Privacy Policy")}
        </h3>
        <p>
          {t("In accordance with Regulation (EU) 2016/79 of the European Parliament and of the Council," +
            " we provide you with a summary of the processing that takes place by means of this website.")}
        </p>
        <h5>
          {t("If you use the contact form from our website, you should know that")}
        </h5>
        <p>
          {t("The processing controller of your data is Balearic Apartments Rentals S.L.. Why do we have your data?" +
            " We need them to be able to respond to your requests for information and contact from our" +
            " website. Why are we allowed to have your data? We may use your contact data because we" +
            " have your consent (art. 6.1 of the Spanish General Data Protection Regulation. RGPD" +
            " according to its Spanish acronym). Will we give your data to anyone? No. What can you do" +
            " with respect to your data?  You may request access, rectification or cancellation of your data," +
            " by sending a request to Balearic Apartments Rentals S.L., as explained in other information (link to" +
            " website privacy policy) Additional information... your privacy is very important to us; please" +
            " consult additional information on our data processing below.")}
        </p>
        <h5>
          {t("If you register as a user")}:
        </h5>
        <p>
          {t("The processing controller of your data is Balearic Apartments Rentals S.L.. Why do we have your data?" +
            " We need them to be able to respond to your requests for information and contact from our" +
            " website. Why are we allowed to have your data? We may use your contact data because we" +
            " have your consent (art. 6.1 of the Spanish General Data Protection Regulation. RGPD" +
            " according to its Spanish acronym). Will we give your data to anyone? No. What can you do" +
            " with respect to your data?  You may request access, rectification or cancellation of your data," +
            " by sending a request to Balearic Apartments Rentals S.L., as explained in other information (link to" +
            " website privacy policy) Additional information... your privacy is very important to us; please" +
            " consult additional information on our data processing below.")}
        </p>
        <h4 className="mb-3">
          {t("More information on the privacy policy")}
        </h4>
        <h5 className="mb-3">
          {t("Users")}
        </h5>
        <p>
          <strong>
            {t("Who is the processing controller of your data?")}
          </strong>
        </p>
        <p>
          {t("Balearic Apartments Rentals S.L.. All contact information, as well as the location of our consultations may be found in our website")}
        </p>
        <p>
          <strong>
            {t("Contact person")}:{" "}
          </strong>
          {t("Balearic Apartments Rentals S.L.")}
        </p>
        <p>
          <strong>
            {t("Why do we collect and use your data?")}
          </strong>
        </p>
        <p>
          {t("As user, we use your data to enable us to provide the various professional real estate services available on our website. Without your data, we cannot assist you. We will preserve them for as long as you are registered in our database.")}
        </p>
        <p>
          <strong>
            {t("Why are we allowed to have your data? Lawfulness")}: {" "}
          </strong>
          {t("art. 6.1.b compliance with an agreement. We may use your data as necessary for compliance with any agreement to which you are a party.")}
        </p>
        <p>
          <strong>
            {t("Assignment recipients")}:{" "}
          </strong>
          {t("not anticipated.")}
        </p>
        <p>
          <strong>
            {t("Data subject rights")}:{" "}
          </strong>
          {t("You may exercise your rights of access, rectification, cancellation and opposition by sending a certified letter with the details of your request and photocopy of your National Identification Card to Balearic Apartments Rentals S.L., Calle de Saridakis 24, Apart. 506, postal code 07015, Palma de Mallorca - Illes Balears (Spain), or send your request together with a document in proof of your identity to info@balearicapartments-rentals.com. If you believe that anything is incorrect, you have the right to file a claim with the Spanish Data Protection Agency. We will be pleased to handle your request and do everything possible so that this is not necessary.")}
        </p>
        <h4 className="mb-4">
          {t("Web contact")}
        </h4>
        <p>
          <strong>
            {t("Who is the processing controller of your data?")}
          </strong>
        </p>
        <p>
          {t("Balearic Apartments Rentals S.L.. All contact information, as well as the location of our consultations may be found in our website")}
        </p>
        <p>
          <strong>
            {t("Contact person")}:{" "}
          </strong>
          {t("Balearic Apartments Rentals S.L.")}
        </p>
        <p>
          <strong>
            {t("Why do we collect and use your data?")}
          </strong>
        </p>
        <p>
          {t("As user, we use your data to enable us to provide the various professional real estate services available on our website. Without your data, we cannot assist you. We will preserve them for as long as you are registered in our database.")}
        </p>
        <p>
          <strong>
            {t("Why are we allowed to have your data? Lawfulness")}: {" "}
          </strong>
          {t("art. 6.1.b compliance with an agreement. We may use your data as necessary for compliance with any agreement to which you are a party.")}
        </p>
        <p>
          <strong>
            {t("Assignment recipients")}:{" "}
          </strong>
          {t("not anticipated.")}
        </p>
        <p>
          <strong>
            {t("Data subject rights")}:{" "}
          </strong>
          {t("You may exercise your rights of access, rectification, cancellation and opposition by sending a certified letter with the details of your request and photocopy of your National Identification Card to Balearic Apartments Rentals S.L., Calle de Saridakis 24, Apart. 506, postal code 07015, Palma de Mallorca - Illes Balears (Spain), or send your request together with a document in proof of your identity to info@balearicapartments-rentals.com. If you believe that anything is incorrect, you have the right to file a claim with the Spanish Data Protection Agency. We will be pleased to handle your request and do everything possible so that this is not necessary.")}
        </p>
      </div>
    );
  }
}

export default withTranslation ()(PrivacityPolitic);
