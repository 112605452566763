import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation  } from "react-i18next";
import { withRouter } from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import moment from "moment"

import { getArticles } from "../../api/blogRequests"

import Loading from "../shared/Loading"
import translate from "../utils/translateStrapi"
import ArticleCard from "../../components/shared/ArticleCard";

const blogUrl = process.env.REACT_APP_BLOG_API_URL


const Blog = ({lng, history}) => {
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState()

  useEffect(() => {
    setLoading(true)
    getArticles()
      .then(({ data }) =>
        setArticles(data.length > 0 ? data : null)
      )
      .catch(() => setArticles(null))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return loading ?
    <Loading/>
  :
    <Container fluid className="py-4" style={{ maxWidth: 1400 }}>
      <h2 className="d-block text-center my-4 text-primary" style={{fontWeight: 600, marginTop: -40}}>
        BLOG BALEARIC APARTMENTS RENTALS
      </h2>

      <Row className="justify-content-center">
        {(articles || [])
          .filter(a => a.published_at <= moment().toISOString() )
          .sort((a,b) => a.published_at < b.published_at ? 1 : -1)
          .map((article, i) => {
          return(
            <Col xs="auto" key={article.id} className="h-100 p-3">
              <ArticleCard key={i} article={article}/>
            </Col>
          )
        })}
      </Row>
    </Container>
}


const mapStateToProps = state => {};


export default withTranslation ()(connect(mapStateToProps)(withRouter(Blog)));
