import React from "react";
import BwmInput from "../shared/form/BwmInput";
import BwmFileUpload from "../shared/form/BwmFileUpload";
import { withTranslation  } from "react-i18next";

export default withTranslation ()(
  ({ onSubmit, onNameChange, onImageUpload, t }) => (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <BwmInput
          label={t("Name")}
          input={{
            onChange: onNameChange,
            name: "name"
          }}
          className="form-control"
          onChange={onNameChange}
        />
      </div>
      <div className="form-group">
        <BwmFileUpload
          test="aVer"
          label={t("DNI Photocopy")}
          className="form-control"
          onChange={onImageUpload}
        />
      </div>
    </form>
  )
);
