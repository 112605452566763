import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import en from "translations/en.js";
import es from "translations/es.js";
import de from "translations/de.js";
import ru from "translations/ru.js";

const resources = {
  en: { translation: en },
  es: { translation: es },
  de: { translation: de },
  ru: { translation: ru }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: Object.keys(resources),
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
