import React from "react";
import { Container, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import LoginForm from "../components/loginRegister/LoginForm";
import RegisterForm from "../components/loginRegister/RegisterForm";
import PageContainer from "../components/shared/PageContainer";


const LoginRegister = () => {
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth)

  if(auth.isAuth) return <Redirect to="/" />
  return (
    <PageContainer>
      <Container className="py-5 px-4 px-md-0">
        <p className="mb-4 h1 text-uppercase" style={{lineHeight: 1}}>{t("Login")} / {t("Register")}</p>
        <Container fluid>
          <Row>
            <Col xs={12} lg={6} className="p-0 py-8 py-lg-5 pr-lg-5 position-relative">
              <div className="position-absolute border-right border-ibiza h-100 d-none d-lg-block" style={{right: 0, top: 0}}/>
              <div className="position-absolute border-bottom border-ibiza w-100 d-lg-none" style={{bottom: 0}}/>
              <LoginForm />
            </Col>

            <Col xs={12} lg={6} className="p-0 py-8 py-lg-5 pl-lg-5">
              <RegisterForm />
            </Col>
          </Row>
        </Container>
      </Container>
    </PageContainer>
  );
}

export default LoginRegister;
