import React, { useEffect, useMemo } from "react";
import { Container, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, Redirect, useLocation } from "react-router-dom";

import PageContainer from "../components/shared/PageContainer";
import axiosService from "../services/axios-service";

const axiosInstance = axiosService.getInstance();

const AfterSignup = () => {
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth)

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) axiosInstance.patch("users/active", { token: ("Bearer " + token) })
  }, [token])

  if(auth.isAuth) return <Redirect to="/" />
  return (
    <PageContainer>
      <Container className="py-9 px-4 px-md-0 text-center flex-center">
        <div style={{maxWidth: 800, lineHeight: 1}}>
          <i className="fas fa-check text-success mb-3" style={{fontSize: "8rem"}}/>
          <p className="mb-4 h3" style={{lineHeight: 1}}>
            {t("¡Bienvenido!")}
          </p>

          <NavLink to="/login">
            <Button color="primary mt-3 text-uppercase">{t("Login")}</Button>
          </NavLink>
        </div>
      </Container>
    </PageContainer>
  );
}

export default AfterSignup;
