import React, { Component } from "react";
import { Link } from "react-router-dom";
import { pretifyDate, toUpperCase } from "utils/stuff";
import { withTranslation  } from "react-i18next";
import i18n from "i18n";
import {hidePayment} from "../../../api/requests";
import {toast} from "react-toastify";
import {Button} from "reactstrap";

class PaymentCard extends Component {

  state = {
    refound: (this.props.payment?.booking?.totalPrice / 100),
    cancelProces: false
  };

  changePercent = ({ target }) => {
    this.setState({ refound: target.value })
  };

  hidePayment = () => {
    const {payment, hidePaymentCB} = this.props
    hidePayment(payment)
      .then(() => {
        hidePaymentCB()
      })
      .catch(e => {
        toast.error(e?.response?.data?.errors[0]?.detail)
      })
  };

  render() {
    const { booking, payment, paymentBtns, t, cancel } = this.props;
    const { refound, cancelProces } = this.state;

    const dateFormat = i18n.language === "es" ? "DD/MM/YYYY" : "YYYY/MM/DD";
    const renderProfileButton = (userId) => {
      return (
        <Link
          rel="canonical"
          className="btn mx-2 btn-primary my-1"
          to={{
            pathname: `/user/${userId}`,
          }}
        >
          {t("Profile")}
        </Link>
      );
    };

    console.log(booking, payment)
    return (
      <div className="col-md-4 my-2">
        <div className="card text-center">
          <div className="card-header">
            {t("Booking Made By")}: {payment.fromUser.username}
            <br/>
            Necesita Factura:{" "}
            {(booking?.wantsInvoice !== undefined ? booking?.wantsInvoice : payment.fromUser.doesNeedAnInvoice) ?
              t("Yes") : t("No")}
          </div>
          <div className="card-block mt-5">
            {booking?.rental && (
              <div>
                <h4 className="card-title">
                  {booking?.rental?.title} - {toUpperCase(booking?.rental?.city)}
                </h4>
              </div>
            )}
            <p className="card-text booking-days">
              {pretifyDate(booking?.startAt, dateFormat)} -{" "}
              {pretifyDate(booking?.endAt, dateFormat)} | {booking?.days}{" "}
              {t("days")}
            </p>
            <p className="card-text booking-price">
              <span>{t("Price")}: </span>
              <span className="booking-price-value">
              {payment?.booking?.totalPrice ? (payment.booking.totalPrice / 100).toFixed(2) : '---'} €
            </span>
            </p>
            <p className="card-text payment-status">
              {t("Status")}: {t(payment?.status)}
            </p>
            <div className="w-100">
              {booking?.rental && (
                <Link
                  rel="canonical"
                  className="btn btn-outline-primary my-1"
                  to={`/rentals/${booking?.rental?.code}`}
                >
                  {t("Go to Rental")}
                </Link>
              )}
              {renderProfileButton(booking?.user)}
              {payment?.status === 'declined' &&
                <Button color="danger" onClick={this.hidePayment} ><i className="fas fa-eye-slash"/></Button>
              }
              {(payment?.status === "charged") &&
              <React.Fragment>
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                  {cancelProces ?
                    <button onClick={() => this.setState({ cancelProces: false })}
                                          className="btn btn-outline-primary my-1">
                      {t("Hide cancellation process")} ↑
                    </button> :
                    <button onClick={() => this.setState({ cancelProces: true })}
                            className="btn btn-outline-danger my-1">
                      {t("Open cancellation process")} ↓
                    </button>}

                  {cancelProces &&
                  <div className="w-100 my-1">
                    <label htmlFor="refound">{t("Amount to be returned")}</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">€</div>
                      </div>
                      <input step="any" id="refound" onChange={this.changePercent} value={refound} min={0}
                             max={(payment?.booking?.totalPrice / 100)} className="form-control" type="number"/>
                    </div>
                    <button onClick={() => cancel(refound)} className="btn btn-danger my-1">
                      {t("Cancel booking")}
                    </button>
                  </div>
                  }
                </div>
              </React.Fragment
              >}
            </div>
          </div>
          <div className="card-footer text-muted">
            {t("Created")} {pretifyDate(booking?.createdAt, dateFormat)}
            {payment?.status === "pending" && paymentBtns && paymentBtns(payment)}
          </div>
        </div>
      </div>

    );
  }

}

export default withTranslation ()(PaymentCard);
