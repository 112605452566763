import React, {useContext, useMemo, useState} from "react";
import {Container, Col, Row, Button} from "reactstrap";
import _ from 'lodash'

import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import useUsers from "../../hooks/useUsers";
import { sendMessage } from "../../api/requests";

import {CustomInput, CustomQuill, CustomSelect} from "../shared/FormInput";
import normalize from "../../utils/normalize";
import useUser from "../../hooks/useUser";
import {toast} from "react-toastify";

import defaultTemplate from "../utils/templates/default"

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const templates = ['default']

const emptyMessage = {
  title: "",
  body: "",
  users: [],
  emails: [],
  template: templates[0]
}

const MessagesList = ({ afterSend }) => {
  const { t } = useContext(LanguageContext);
  const [message, setMessage] = useState(emptyMessage)
  const { title, body, users, emails, template } = message
  const [isLoading, setLoading] = useState(false)
  const { users: allUsers, isLoading: isLoadingUsers } = useUsers()

  const usersOptions = useMemo(() => {
    return Object.entries((allUsers || []).reduce((r, { _id, doesWantAds, name, firstSurname, secondSurname, email, country, rentals }) => {
      const option = {
        value: _id,
        label: `${name ? name : ""}${firstSurname ? " "+firstSurname : ""}${secondSurname ? " "+secondSurname+" " : ""} - ${email}${rentals?.length ? ` (rentals: ${rentals.length})`: ""}`,
        hasRentals: !!rentals?.length,
        isDisabled: !doesWantAds || !email
      }
      const normalizedCountry = (country || "").trim().toUpperCase()
      r[normalizedCountry || 'other'] = (r?.[normalizedCountry || 'other'] || []).concat([option])
      return r
    }, {})).map(([country, options]) => ({ label: country, options }))
  }, [allUsers])


  const handleInputChange = (name, value) =>
    setMessage(rental => {
      const clone = _.cloneDeep(rental)
      _.set(clone, name, value)
      return clone
    })

  const handleInputEvent = e => {
    const {name, value, checked, type} = e.target
    handleInputChange(name, type === "checkbox" ? checked : value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    return sendMessage(message)
      .then(() => {
        setMessage(emptyMessage)
        afterSend()
        toast.success(t("Email sent correctly"))
      })
      .catch(e => {
        console.log('e.response.data', e.response.data)
        if (e.response.data.error) toast.error(e.response.data.error)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container fluid tag="form" onSubmit={handleSubmit}>
      <Row className="h2 text-primary">
        {t('Send email')}
      </Row>

      <Row>
        <Col xs={12} md={7} className="mb-n2">

          {!!body &&
            <div
              title="Email preview"
              dangerouslySetInnerHTML={{__html: defaultTemplate(body)}}
              // style={{ width: '200%', transform: "scale(50%) translate(-50%, -50%)"}}
            />
          }

          <CustomInput
            label={t("Title")}
            name="title"
            value={title}
            onChange={handleInputEvent}
            disabled={isLoading}
            required
          />
          <CustomQuill
            label={t("Body")}
            onChange={v => handleInputChange('body', v)}
            value={body || ""}
            formats={['header', 'bold', 'italic', 'underline', 'strike', 'link']}
            modules={{
              toolbar: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['link', "//image"]
              ]
            }}
            style={{height: '10rem'}}
            disabled={isLoading}
            required
          />

        </Col>
        <Col xs={12} md={5} className="d-flex flex-column justify-content-between">
          <div>
            <CustomSelect
              label={t("Template")}
              value={template}
              options={templates.map(t => ({ label: t, value: t}))}
              onChange={({value}) => handleInputChange('template', value)}
              style={{zIndex: 9999}}
              disabled={true}
            />

            <CustomSelect
              label={t("Users")}
              value={(emails || []).concat(users || [])}
              onChange={opts => {
                const newEmails = (opts || []).reduce((r, o) => {
                  if(o?.__isNew__ || o?.isNew) {
                    if(!validateEmail(o.value)) return toast.error("Invalid email")
                    r.emails.push(o.value)
                  }
                  else r.users.push(o.value)
                  return r
                }, { users: [], emails: [] })
                setMessage(m => ({...m, ...newEmails}))
              }

              }
              options={usersOptions}
              style={{zIndex: 9999}}
              closeMenuOnSelect={false}
              disabled={isLoading || isLoadingUsers}
              required
              addSelectAll
              isMulti
              isCreatable
            />
          </div>
          <Button type="submit" color="primary" className="w-100">
            {t("Send")}
          </Button>
        </Col>
      </Row>

      <Row>
      </Row>

    </Container>
  )
}


export default MessagesList;
