import getDatesInRange from "./getDatesInRange";

const DATE_FORMAT = 'YYYY/MM/DD'
const calculateRentalPrice = (rental, from, to) => {
  if(!from || !to) return
  const dates = getDatesInRange(from, to, DATE_FORMAT)
  return dates.reduce((r, d) => {
    let price = rental.datePrices?.[d]
    if(price !== undefined) r += price
    else r += rental.dailyRate
    return r
  }, 0)
}

export default  calculateRentalPrice
