import React from "react";
import {Container} from "reactstrap";

import MainSection from "../components/home/MainSection"
import HighlitedRentals from "../components/home/HighlitedRentals";
import Separation from "../components/home/Separation";
import ProductExplanation from "../components/home/ProductExplanation";
import Islands from "../components/home/Islands";
import UserTypes from "../components/home/UserTypes";
import CancelationPolicy from "../components/home/CancelationPolicy";
import LatestArticles from "../components/home/LatestArticles"
import Recomendations from "../components/home/Recomendations"
import ContactForm from "../components/shared/ContactForm";
import Footer from "../components/shared/Footer";


const Home = () => {
  return (
    <Container fluid className="p-0">
      <MainSection />
      <HighlitedRentals />
      <Separation />
      <ProductExplanation />
      <Islands />
      <UserTypes />
      <CancelationPolicy />
      <LatestArticles />
      <Recomendations />
      <Container>
        <ContactForm />
      </Container>
      <Footer />
    </Container>
  );
}

export default Home;
