import { useState, useEffect } from 'react'
import { getRentals } from "../api/requests";

const useRentals = ({ city = 'all', island = 'all'}) => {
  const [rentals, setRentals] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getRentals({city, island})
      .then(({data}) => setRentals(data))
      .catch(e => {
        setRentals(null)
        console.log(e.message)
      })
      .finally(() => setIsLoading(false))
  }, [city, island])

  return {
    rentals,
    isLoading
  }
}

export default useRentals;
