import React, {useContext} from "react";
import {Col, Container, Row} from "reactstrap";

import bgImg from "../../media/home/recomendation-bg.svg"
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";


const Recomendations = () => {
  const { t } = useContext(LanguageContext);
  return (
    <Container fluid>
      <Row className="d-flex justify-content-around align-items-center text-center" style={styles.bg}>
        <p className="text-primary w-100 px-5 h2 text-uppercase" style={{fontSize: '2.4rem'}}>{t('Testimonials')}</p>
        <Col xs="12" lg="unset">
          <p className="font-weight-bold mb-3">Camila</p>
          <p>{t('The truth is that Balearic apartment rentals have been super professional with us, they welcomed us, came to see us off and were always attentive to our needs, their apartments are beautiful and super well located.')}</p>
        </Col>
        <hr className="bg-menorca d-none d-lg-block mx-4" style={{width: 1, height: 200}}/>
        <hr className="bg-menorca d-lg-none" style={{width: 200, height: 1}}/>
        <Col xs="12" lg="unset">
          <p className="font-weight-bold mb-3">Bharat</p>
          <p>{t('We find Balearic apartment rentals a very good option, very well cooperative and a wonderful host. They made us feel like home.')}</p>
        </Col>
      </Row>
    </Container>
  );
}

const styles = {
  bg: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    gap: '2rem',
    fontSize: '1.3rem',
    padding: '7rem 5rem'
  },
}

export default Recomendations;
