import React from "react";
import ReactCrop from "react-image-crop";
import * as actions from "actions";
import { withTranslation  } from "react-i18next";
import {Button, FormGroup, Input, Label} from "reactstrap";

class BwmFileUpload extends React.Component {
  constructor() {
    super();

    this.setupReader();

    this.state = {
      selectedFile: undefined,
      imageBase64: "",
      initialImageBase64: "",
      croppedImage: {},
      pending: false,
      status: "INIT",
      crop: {}
    };

    this.onChange = this.onChange.bind(this);
  }

  setupReader() {
    this.reader = new FileReader();

    this.reader.addEventListener("load", event => {
      const { initialImageBase64 } = this.state;

      const imageBase64 = event.target.result;

      if (initialImageBase64) {
        this.setState({ imageBase64 });
      } else {
        this.setState({ imageBase64, initialImageBase64: imageBase64 });
      }
    });
  }

  resetToDefaultState(status) {
    this.setState({
      pending: false,
      status,
      selectedFile: undefined,
      croppedImage: {},
      initialImageBase64: "",
      imageBase64: ""
    });
  }

  onChange(event) {
    const selectedFile = event.target.files[0];
    console.log("onChange");
    console.log(selectedFile);

    if (selectedFile) {
      this.setState({
        selectedFile,
        initialImageBase64: "",
        crop: {
          x: 0,
          y: 0,
          height: 100,
          width: 100
        }
      });
      this.reader.readAsDataURL(selectedFile);
    }
  }

  onCropChange(crop) {
    console.log(crop);
    this.setState({ crop });
  }

  onImageLoaded(image) {
    console.log("onImageLoaded");
    console.log(image);
    const { selectedFile } = this.state;
    // image.toString();
    return getCroppedImg(
      image,
      {
        x: 0,
        y: 0,
        width:  image.naturalWidth,
        height:  image.naturalHeight
      },
      selectedFile.name
    ).then(croppedImage => this.setState({ croppedImage }));
  }

  async onCropCompleted(crop, pixelCrop) {
    const { selectedFile, initialImageBase64 } = this.state;

    if (selectedFile && (pixelCrop.height > 0 && pixelCrop.width > 0)) {
      const img = new Image();
      img.src = initialImageBase64;

      const croppedImage = await getCroppedImg(
        img,
        pixelCrop,
        selectedFile.name
      );

      console.log("onCropCompleted");
      console.log(croppedImage);

      this.setState({ croppedImage });

      this.reader.readAsDataURL(croppedImage);
    }
  }

  onError() {
    this.setState({ pending: false, status: "FAIL" });
  }

  onSuccess(uploadedImage) {
    const {
      onChange = () => {
      }
    } = this.props.input || this.props;

    this.resetToDefaultState("OK");

    onChange(uploadedImage);
  }

  uploadImage() {
    const { croppedImage } = this.state;
    console.log(croppedImage);

    if (croppedImage) {
      this.setState({ pending: true, status: "INIT" });
      actions.uploadImage(croppedImage).then(
        uploadedImage => {
          this.onSuccess(uploadedImage);
        },
        error => {
          this.onError(error);
        }
      );
    }
  }

  renderSpinningCircle() {
    const { pending } = this.state;

    if (pending) {
      return (
        <div className="img-loading-overlay">
          <div className="img-spinning-circle"/>
        </div>
      );
    }
  }

  renderImageStatus(t) {
    const { status } = this.state;

    if (status === "OK") {
      return (
        <div className="alert alert-success">
          {t("Image Uploaded Succesfuly!")}{" "}
        </div>
      );
    }

    if (status === "FAIL") {
      return (
        <div className="alert alert-danger"> {t("Image Upload Failed!")} </div>
      );
    }
  }

  render() {
    const { selectedFile, imageBase64, crop, initialImageBase64 } = this.state;
    const { t, label, test } = this.props;

    return (
      <div className="img-upload-container">
        <label>{label}</label>
        <br/>
        <FormGroup className="m-0">
          <Label className="py-2 px-5 customInput btn btn-outline-primary">
            {t("Select an image")}
            <Input type="file" accept=".jpg, .png, .jpeg" onChange={this.onChange}/>
          </Label>
        </FormGroup>

        {selectedFile && (
          <Button
            type="button"
            color="success"
            className="py-1 px-5 customInput"
            disabled={!selectedFile}
            onClick={() => this.uploadImage()}
          >
            {t("Upload Image")}
          </Button>
        )}

        {initialImageBase64 && (
          <ReactCrop
            style={{ marginTop: 10 }}
            src={initialImageBase64}
            crop={crop}
            onChange={crop => this.onCropChange(crop)}
            onImageLoaded={image => this.onImageLoaded(image)}
            onComplete={(crop, pixelCrop) =>
              this.onCropCompleted(crop, pixelCrop)
            }
          />
        )}

        {imageBase64 && (
          <div className="img-preview-container">
            <div
              className="img-preview"
              style={{ backgroundImage: "url(" + imageBase64 + ")" }}
            />

            {this.renderSpinningCircle()}
          </div>
        )}

        {this.renderImageStatus(t)}
      </div>
    );
  }
}

function getCroppedImg(image, pixelCrop, fileName) {
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) =>  {
    canvas.toBlob(file => {
      if(file){
        file.name = fileName;
        resolve(file);
      }
    }, "image/jpeg");
  });
}

export default withTranslation ()(BwmFileUpload);
