import React, {useContext} from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import SeparatedSection from "../shared/SeparatedSection";
import checkIcon from "../../media/home/icons/check.svg"
import {LanguageContext} from "../../hooks/wrappers/LanguageWrapper";



const UserTypes = () => {
  const { t } = useContext(LanguageContext);
  const history = useHistory()

  const listItems = [
    "Manage your leases pursuant to Spanish Urban Leases Law (LAU in its Spanish initials)",
    "Posting your apartments is free of charge",
    "Advertise your property throughout the world",
    "Personal and individual management of your properties and bookings",
    "Lease payments guaranteed",
    "Transparent management",
    "Personalised advice and assistance from our team"
  ]

  return (
    <SeparatedSection
      first={<>
        <div className="mb-4 text-uppercase">
          <p className="mb-5 text-ibiza d-block" style={{fontSize: '2.1rem'}}>{t('Are you an owner?')}</p>
          <h2 className="m-0 text-primary">{t('Post your properties completely free of charge.')}</h2>
        </div>

        <p className="mb-5">
          <ul className="p-0" style={{listStyle: 'none', fontSize: '1.2rem'}}>
            {listItems.map((text, i) =>
              <li key={i} className="d-flex mb-2">
                <span className="mr-3">·</span>
                <p className="mb-0">{t(text)}</p>
              </li>
            )}
          </ul>
        </p>

        <Button
          color="primary"
          onClick={() => history.push('/login')}
          className="w-100 py-2"
          style={{
            borderRadius: 9999,
            fontSize: '1.5rem'
        }}
        >
          {t('Login or SignUp')}
        </Button>
      </>}

      second={<>
        <div className="mb-4 text-uppercase">
          <p className="mb-4 text-ibiza d-block" style={{fontSize: '2.1rem'}}>{t('Are you a tenant?')}</p>
          <img height="150rem" src={checkIcon} alt="keys_icon" loading="lazy"/>
          <h2 className="mt-3 m-0 text-primary">{t('Rent easily')}</h2>
        </div>
        <p style={{ fontSize: '1.5rem', lineHeight: 1.2 }}>
          {t('Rent easily - text')}
        </p>
      </>}

    />
  );
}


export default UserTypes;
