import React from "react";
import { Container } from "reactstrap";

import PageContainer from "../components/shared/PageContainer";
import ContactForm from "../components/shared/ContactForm";

const Contact = () => {
  return (
    <PageContainer>
      <Container className="py-5 px-2 px-md-0">
        <ContactForm />
      </Container>
    </PageContainer>
  );
}

export default Contact;
