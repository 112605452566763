import React from "react";
import { withTranslation  } from "react-i18next";
import { withRouter } from "react-router-dom";
import BwmInput from "../shared/form/BwmInput";
import BwmFileUpload from "../shared/form/BwmFileUpload";
import axiosService from "services/axios-service";
import authService from "services/auth-service";
import * as actions from "actions";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

const axiosInstance = axiosService.getInstance();

class Profile extends React.Component {
  state = {
    isAnon: false,
    err: {},
    user: {
      username: "",
      email: "",
      dni: "",
      dniImageURL: "",
      name: "",
      firstSurname: "",
      secondSurname: "",
      active: true,
      city: "",
      country: "",
      phone: "",
      holder: "",
      bank: "",
      iban: "",
      swift: "",
      doesNeedAnInvoice: false,
      doesWantAds: false,

    }
  };

  componentDidMount() {
    const { userId } = this.props;
    this.getCurrentUser(userId).then(() => {
      const {
        onSave = () => {
        }
      } = this.props;
      const { user } = this.state;
      onSave(this.isProfileOk(user));
    });
  }


  mustIShow = (myKey) => {
    if (this.props.bancData) {
      return true
    } else if ((myKey === "holder" || myKey === "bank" || myKey === "iban" || myKey === "swift")) {
      return false
    } else {
      return true
    }
  };

  getLabel = (myKey, t) => {
    if (myKey === "dni") {
      return ("DNI / NIE / " + t("Passport"));
    } else if (myKey === "swift") {
      return ("Bic / Swift");
    } else {
      return (t(myKey.charAt(0).toUpperCase() + myKey.slice(1)));
    }
  };

  getCurrentUser = (userId = "me") => {
    const { user } = this.state;

    return axiosInstance
      .get("users/" + userId)
      .then(({ data }) => {
        this.user = data;
        return data;
      })
      .then(data =>
        Object.keys(user).reduce((reducer, key) => {
          reducer[key] = typeof data[key] !== "undefined" ? data[key] : "";
          return reducer;
        }, {})
      )
      .then(user => {
        // onSave(this.isProfileOk(user).all);
        this.setState({ user, isAnon: false });
      })
      .catch(({ response }) => {
        if (response) {
          if (response.status === 401) {
            this.setState({ isAnon: true });
          }
        }
      });
  };

  updateProfile = user => {
    const {
      onSave = () => {
      },
      t
    } = this.props;
    const { userId } = this.props;
    toast.info(t("Updating profile") + "...");
    axiosInstance.patch("users/" + this.user._id, {
      ...user,
      userId,
      doesNeedAnInvoice: !!user.doesNeedAnInvoice,
      doesWantAds: !!user.doesWantAds
    }).then(({ data }) => {
      if (typeof onSave === "function") {
        onSave(this.isProfileOk(data));
      }
      toast.success(t("Updated profile"));
    }).catch(() => {
      toast.error(t("Update failed"));
    });
  };

  registerUser = user => {
    const password = this.generatePassword();

    return axiosInstance
      .post("/users/register", {
        ...user,
        role: "customer",
        password,
        passwordConfirmation: password
      })
      .then(() =>
        axiosInstance
          .post("/users/auth", { email: user.email, password })
          .then(res => res.data)
          .then(token => {
            authService.saveToken(token);
          })
      )
      .then(() => this.getCurrentUser())
      .catch(console.log);
  };

  generatePassword = () => {
    const length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  handleChange = event => {
    const { editable } = this.props;
    const { name, value } = event.target;
    if (editable) {
      this.setState({ user: { ...this.state.user, [name]: value } });
    }
  };

  handleCheckboxChange = event => {
    const { name, value, checked, type } = event.target;
    const parsedValue = type === "checkbox" ? checked : value;
    this.setState({ user: { ...this.state.user, [name]: parsedValue } });
  };

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.state.user !== prevProps.user) {
  //     const {
  //       onSave = () => {
  //       }
  //     } = this.props;
  //     const { user } = this.state;
  //     onSave(this.isProfileOk(user));
  //   }
  // }

  isProfileOk = user => {
    const { bank, city, country, dni, email, dniImageURL, firstSurname, holder, iban, name, phone, secondSurname, swift, username } = user;
    const check = this.check;
    if (check(bank) && check(dniImageURL) && check(city) && check(country) && check(dni) && check(email) && check(firstSurname) && check(holder)
      && check(iban) && check(name) && check(phone) && check(secondSurname) && check(swift) && check(username)) {
      return { booking: true, all: true, sesion: true };
    }

    if (check(city) && check(dniImageURL) && check(country) && check(dni) && check(email) && check(firstSurname) &&
      check(name) && check(phone) && check(secondSurname) && check(username)) {
      return { booking: true, all: false, sesion: true };
    }

    console.log("COMPROBANDO SESION")
    console.log(email)
    console.log(username)
    if (check(email) && check(username)) {
      console.log("ACIERTO")
      return { booking: false, all: false, sesion: true };
    }
    console.log("FALLO")

    const { logout, history } = this.props;
    if (logout) {
      logout();
      history.replace("/login")
    } else {
      return { booking: false, all: false, sesion: false };
    }
  };

  check = (some) => {
    return some !== "" && some !== null && some !== undefined;
  };

  handleSubmit = event => {
    const { user } = this.state;
    event.preventDefault();
    if (this.state.isAnon) {
      this.registerUser(user);
    } else {
      this.updateProfile(user);
    }
  };

  onImageUpload = dniImageURL => {

    this.setState({ user: { ...this.state.user, dniImageURL } });

    //   if (!this.state.isAnon) {
    //     this.updateProfile({ dniImageURL });
    //   }
  };

  deleteUser = (event) => {
    event.preventDefault();
    const { user } = this.state;
    const {
      onSave = () => {
      },
      history,
      t
    } = this.props;
    axiosInstance.delete("users/delete", user).then(({ data }) => {
      if (typeof onSave === "function") {
        onSave(this.isProfileOk(data).all);
      }
      if (window.confirm(t("Are you sure you want to delete this account? You will lose all your data and you will not be able to recover them"))) {
        history.push("/logout");
      }
    });
  };

  changePass = (event) => {
    const { t } = this.props;
    event.preventDefault();
    actions.sendEmailRestorePassword(this.state.user.email).then(() => {
      alert(t("An email has been sent with the necessary redirection to change the password"));
    });
  };


  static defaultProps = {
    bancData: true,
    deleteUser: true,
    changePass: true,
    editable: true
  };

  render() {
    const { t, header, deleteUser, changePass, editable } = this.props;
    const { user } = this.state;
    return (
      <div>
        {header ? header() : <h1>{t("Profile")}</h1>}
        <div className="row">
          <div className="col-12">
            <form onSubmit={this.handleSubmit} className="row">
              {Object.keys(user)
                .filter(key => !["doesNeedAnInvoice", "doesWantAds", "dniImageURL", "active"].includes(key))
                .map((key, index) => (
                  <React.Fragment key={index}>
                    {(this.mustIShow(key) && key === "holder") &&
                    <div style={{ fontSize: "30px" }} className="col-12 mt-5 mb-4">
                      {this.mustIShow(key) && t("Payment details")}
                    </div>}
                    {this.mustIShow(key) &&
                    <React.Fragment>
                      <div className={"col-12 col-md-6"}>
                        <BwmInput
                          input={{
                            value: this.state.user[key],
                            onChange: this.handleChange,
                            name: key,
                            required: true
                          }}
                          type="text"
                          label={this.getLabel(key, t)}
                          className="form-control"
                        />
                        {key === "dni" && (
                          <React.Fragment>
                            {editable &&
                            <BwmFileUpload
                              label={t("DNI / NIE / Passport Photocopy")}
                              onChange={this.onImageUpload}
                            />
                            }
                            {user.dniImageURL && (
                              <img
                                className="mb-3"
                                alt={t("DNI / NIE Photocopy")}
                                style={{ maxWidth: "100%" }}
                                src={user.dniImageURL}
                              />
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </React.Fragment>
                    }
                  </React.Fragment>
                ))}
              {/*<div className="w-100 px-3">*/}
              {/*  <BwmInput*/}
              {/*    input={{*/}
              {/*      checked: this.state.user.doesNeedAnInvoice,*/}
              {/*      onChange: this.handleCheckboxChange,*/}
              {/*      name: "doesNeedAnInvoice"*/}
              {/*    }}*/}
              {/*    type="checkbox"*/}
              {/*    label={t(*/}
              {/*      "doesNeedAnInvoice".charAt(0).toUpperCase() +*/}
              {/*      "doesNeedAnInvoice".slice(1)*/}
              {/*    )}*/}
              {/*    className="form-control"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="w-100 px-3">
                <BwmInput
                  input={{
                    checked: this.state.user.doesWantAds,
                    onChange: this.handleCheckboxChange,
                    name: "doesWantAds"
                  }}
                  type="checkbox"
                  label={t(
                    "doesWantAds".charAt(0).toUpperCase() + "doesWantAds".slice(1)
                  )}
                  className="form-control"
                />
              </div>
              {editable &&
                <Button
                  color="success"
                  className="mx-3 py-2 px-4"
                  style={{
                    borderRadius: 9999,
                    fontSize: '1.2rem'
                  }}
                >
                  {t("Update Profile")}
                </Button>
              }
              {changePass &&
                <Button
                  color="primary" outline
                  className="mx-3 py-2 px-4"
                  onClick={this.changePass}
                  style={{
                    borderRadius: 9999,
                    fontSize: '1.2rem'
                  }}
                >
                  {t("Change Password")}
                </Button>
              }
              {deleteUser &&
                <Button
                  color="danger"
                  className="mx-3 py-2 px-4"
                  onClick={this.deleteUser}
                  style={{
                    borderRadius: 9999,
                    fontSize: '1.2rem'
                  }}
                >
                  {t("Delete profile")}
                </Button>
              }
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation ()(withRouter(Profile));
