import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { withTranslation  } from "react-i18next";
import { BookingCard, PaymentCard } from "./index";

import * as actions from "actions";

class BookingManage extends React.Component {
  state = {
    pendingPayments: []
  };

  componentDidMount() {
    this.props.dispatch(actions.fetchUserBookings());
    this.getPendingPayments();
  }

  getPendingPayments() {
    actions
      .getPendingPayments()
      .then(pendingPayments => this.setState({ pendingPayments }))
      .catch(err => console.error(err));
  }

  updatePayment(payment) {
    actions
      .updatePayment(payment)
      .then(status => {
        toast.success("Reserva aceptada y pago realizado correctamente");
        this.getPendingPayments();
      })
      .catch(err => {
        toast.error("Hemos encontrado un probleema. Por favor, pongase en contacto con nosotros pra solucionarlo");
        console.error(err)
      });
  }

  declinePayment(payment) {
    actions
      .declinePayment(payment)
      .then(status => {
        toast.info("Reserva rechazada")
        this.getPendingPayments();
      })
      .catch(err => console.error(err));
  }

  renderBookings(bookings) {
    return bookings.map((booking, index) => (
      <BookingCard booking={booking} key={index}/>
    ));
  }

  renderPayments(payments) {
    return payments.map((payment, index) => (
      <PaymentCard
        booking={payment.booking}
        payment={payment}
        paymentBtns={this.renderPaymentButtons}
        key={index}
        cancel={(refound) => this.cancelPayment(payment._id, refound)}
        hidePaymentCB={() => {
          const pendingPaymentsCopy = [...this.state.pendingPayments]
          const pIndex = pendingPaymentsCopy.findIndex(p => p._id === payment._id)
          pendingPaymentsCopy[pIndex].hidden = true
          this.setState({ pendingPayments: pendingPaymentsCopy })
        }}
      />
    ));
  }

  cancelPayment = (idPayment, refound) => {
    actions
      .cancelPayment(idPayment, refound)
      .then(status => {
        toast.info("Reserva rechazada");
        this.getPendingPayments();
      })
      .catch(err => console.error(err));
  };

  renderPaymentButtons = payment => {
    const { t } = this.props;
    return (
      <div>
        <button
          onClick={() => {
            this.updatePayment(payment)
          }}
          className="btn btn-success"
        >
          {t("Accept")}
        </button>
        <button
          onClick={() => {
            this.declinePayment(payment)
          }}
          className="btn btn-danger"
        >
          {t("Decline")}
        </button>
      </div>
    );
  };

  render() {
    const {
      t,
      userBookings: { data: bookings, isFetching }
    } = this.props;
    const { pendingPayments } = this.state;
    const visiblePendingPayments = pendingPayments.filter(p => !p.hidden)

    return (
      <React.Fragment>
        <section id="userBookings">
          <h1 className="page-title">{t("My Bookings")}</h1>
          <div className="row">{this.renderBookings(bookings)}</div>
          {!isFetching && bookings.length === 0 && (
            <div className="alert alert-warning">
              {t(
                "You have no bookings created go to rentals section and book your place today"
              )}
              <Link
                rel="canonical"
                style={{ marginLeft: "10px" }}
                className="btn btn-bwm"
                to="/"
              >
                {t("Available Rentals")}
              </Link>
            </div>
          )}
        </section>
        <section id="pendingBookings">
          <h1 className="page-title">{t("Pending Bookings")}</h1>
          <div className="row">{this.renderPayments(visiblePendingPayments)}</div>
          {!isFetching && visiblePendingPayments.length === 0 && (
            <div className="alert alert-warning">
              {t("You have no pending bookings currently...")}
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userBookings: state.userBookings
  };
}

export default withTranslation ()(connect(mapStateToProps)(BookingManage));
