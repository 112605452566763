
const translate = (text, lang) => {
  const translations = text.translate
  if(translations && translations.length > 0){
    let translation =
      translations.find(translation => translation.language && translation.language.lang === lang) ||
      translations.find(translation => translation.language && translation.language.lang === 'es') ||
      translations[0]
    return translation.text || translation.content
  } else return null
}

export default translate