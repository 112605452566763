import React, { useContext, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IslandContext } from "../../hooks/wrappers/IslandWrapper";
import { contact } from "../../actions";


const defaultData = {
  name: "",
  lastName: "",
  email: "",
  phone: "",
  message: ""
}

const ContactForm = ({className}) => {
  const { t } = useTranslation();
  const { island } = useContext(IslandContext)
  const [data, setData] = useState(defaultData)
  const {
    name,
    lastName,
    email,
    phone,
    message
  } = data

  const handleInputChange = e => {
    const { name, value, checked, type } = e.target;
    setData({ ...data, [name]: type === "checkbox" ? checked : value })
  }

  const handleSubmit = event => {
    event.preventDefault();

    contact({ ...data, island })
      .then(() => {
        alert(t("Your message has been sent successfully"))
        setData(defaultData)
      }).catch(() =>
        alert("¡Ups!")
      )
  };

  return (
    <Container fluid className={"py-8 " + className}>
      <Row className="mb-4">
        <Col tag="h3" className="text-uppercase text-primary text-center"  style={{fontSize: '2.4rem'}}>
          {t('Contact us')}
        </Col>
      </Row>
      <Row tag="form" onSubmit={handleSubmit} className="w-100 m-0">

        <Col xs={12} lg={6}>
          <Input
            type="text"
            name="name"
            className="mb-4 customInput"
            value={name}
            onChange={handleInputChange}
            placeholder={t("First Name")+ " *"}
            required
          />
        </Col>

        <Col xs={12} lg={6}>
          <Input
            type="text"
            name="lastName"
            className="mb-4 customInput"
            value={lastName}
            onChange={handleInputChange}
            placeholder={t("Last Name")+ " *"}
            required
          />
        </Col>

        <Col xs={12} lg={6}>
          <Input
            type="email"
            name="email"
            className="mb-4 customInput"
            value={email}
            onChange={handleInputChange}
            placeholder={t("Email Address")+ " *"}
            required
          />
        </Col>

        <Col xs={12} lg={6}>
          <Input
            type="tel"
            name="phone"
            className="mb-4 customInput"
            value={phone}
            onChange={handleInputChange}
            placeholder={t("Phone")+ " *"}
            required
          />
        </Col>

        <Col xs={12}>
          <Input
            type="textarea"
            rows="7"
            name="message"
            className="mb-5 customInput py-3"
            style={{borderRadius: 22}}
            value={message}
            onChange={handleInputChange}
            placeholder={t("Enter your massage for us here. We will get back to as soon as possible.")}
            required
          />
        </Col>

        <Col xs={12}>
          <FormGroup check>
            <Label check className="mb-4">
              <Input type="checkbox" required/>{' '}
              <Link to="/privacy" className="text-dark">
                <u>{t("I have read and accept the privacy policy")}</u>
              </Link>
            </Label>
          </FormGroup>
          <div>
            <Button
              type="submit"
              color="primary"
              className="py-2 px-5"
              style={{
                borderRadius: 9999,
                fontSize: '1.2rem'
              }}
            >
              {t("Submit")}
            </Button>
          </div>
        </Col>

      </Row>
    </Container>
  );
}


export default ContactForm;
