
import wifiIcon from '../media/amenities/wifi.svg'
import acIcon from '../media/amenities/air-conditioning.svg'
import microwaveIcon from '../media/amenities/microwave.svg'
import ovenIcon from '../media/amenities/oven.svg'
import fridgeIcon from '../media/amenities/fridge.svg'
import dryerIcon from '../media/amenities/drying-machine.svg'
import poolIcon from '../media/amenities/swimming-pool.svg'
import saunaIcon from '../media/amenities/heating.svg'
import terraceIcon from '../media/amenities/terrace.svg'
import bathtubIcon from '../media/amenities/bathtub.svg'
import parkingIcon from '../media/amenities/parking.svg'
import barbecueIcon from '../media/amenities/bbq.svg'
import towelIcon from '../media/amenities/towel.svg'
import sheetsIcon from '../media/amenities/bed-sheets.svg'
import cutleryIcon from '../media/amenities/cultery.svg'
import washingMachineIcon from '../media/amenities/washing-machine.svg'
import petsIcon from '../media/amenities/pets.svg'
import fanIcon from '../media/amenities/fan.svg'
import heatingIcon from '../media/amenities/heating.svg'


import tvIcon from '../media/amenities/tv.svg'
import workingSpaceIcon from '../media/amenities/workingSpace.svg'
import fireplaceIcon from '../media/amenities/fireplace.svg'
import outdoorDiningIcon from '../media/amenities/outdoorDining.svg'
import billiardIcon from '../media/amenities/billiard.svg'
import pianoIcon from '../media/amenities/piano.svg'
import dumbbellsIcon from '../media/amenities/dumbbells.svg'
import beachIcon from '../media/amenities/beach.svg'
import skiIcon from '../media/amenities/ski.svg'
import showerIcon from '../media/amenities/shower.svg'
import smokeDetectorIcon from '../media/amenities/smokeDetector.svg'
import firsAidKitIcon from '../media/amenities/firsAidKit.svg'
import fireExtinguisherIcon from '../media/amenities/fireExtinguisher.svg'


const assets = {
  'wifi': wifiIcon,
  'air conditioner': acIcon,
  'microwave': microwaveIcon,
  'oven': ovenIcon,
  'fridge': fridgeIcon,
  'dryer': dryerIcon,
  'pool': poolIcon,
  'sauna': saunaIcon,
  'terrace': terraceIcon,
  'bathtub': bathtubIcon,
  'parking': parkingIcon,
  'barbecue': barbecueIcon,
  'towel': towelIcon,
  'sheets': sheetsIcon,
  'cutlery': cutleryIcon,
  'washing machine': washingMachineIcon,
  'animals': petsIcon,
  'fans': fanIcon,
  'heating': heatingIcon,

  'tv': tvIcon,
  'working space': workingSpaceIcon,
  'fireplace': fireplaceIcon,
  'outdoor dining': outdoorDiningIcon,
  'billiard table': billiardIcon,
  'piano': pianoIcon,
  'exercise equipment': dumbbellsIcon,
  'beach access': beachIcon,
  'ski slope': skiIcon,
  'outdoor shower': showerIcon,
  'smoke detector': smokeDetectorIcon,
  'first aid kit': firsAidKitIcon,
  'fire extinguisher': fireExtinguisherIcon
}

export default assets;
