import { useState, useEffect } from 'react'
import { getRentalBookings } from "../api/requests";

const useRentalBookings = (rentalId) => {
  const [rentalBookings, setRentalBookings] = useState()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if(rentalId) refresh()
  }, [rentalId])

  const refresh = () => {
    setLoading(true)
    getRentalBookings(rentalId)
      .then(({data}) => setRentalBookings(data))
      .catch(e => {
        setRentalBookings(null)
        console.log(e.message)
      })
      .finally(() => setLoading(false))
  }

  return {
    rentalBookings,
    refresh,
    isLoading
  }
}

export default useRentalBookings;
