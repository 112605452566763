let categories = [
  "house",
  "attic",
  "duplex attic",
  "studio",
  "apartment",
  "ground floor",
  "villa",
  "attached villa",
  "estate",
  "loft"
]

export default categories