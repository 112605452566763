import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from "react-range";

const LabeledTwoThumbs = ({ min = 0, max = 1000, step = 0.1, values, setValues }) => {
  const [curValues, setCurValues] = useState(values)

  useEffect(() => {
    setCurValues([values[0], max])
    setValues([values[0], max])
  }, [max])

  return <div style={styles.rangeContainer}>
    <Range
      values={curValues}
      {...{min, max, step}}
      onChange={(values) => setCurValues(values)}
      onFinalChange={(values) => setValues(values)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{ ...props.style, ...styles.trackDiv }}
        >
          <div ref={props.ref} style={styles.track(curValues, min, max)}>
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props, isDragged }) => (
        <div {...props} style={{ ...props.style, ...styles.thumbDiv }}>
          <div style={styles.thumbValue} className="">
            {curValues[index].toFixed() + "€"}
          </div>
          <div style={styles.thumb(isDragged)}/>
        </div>
      )}
    />
  </div>
}


const styles = {
  rangeContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 20,
    padding: "0px 15px"
  },
  trackDiv: {
    height: '36px',
    display: 'flex',
    width: '100%'
  },
  track: (values, min, max) => ({
    height: '5px',
    width: '100%',
    borderRadius: '4px',
    background: getTrackBackground({
      values,
      colors: ['#ccc', '#00B3D8', '#ccc'],
      min,
      max
    }),
    alignSelf: 'center'
  }),
  thumbDiv: {
    height: '30px',
    width: '25px',
    borderRadius: '4px',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 6px #AAA'
  },
  thumbValue: {
    position: 'absolute',
    top: '-28px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: '#00B3D8'
  },
  thumb: (isDragged) => ({
    height: '16px',
    width: '5px',
    backgroundColor: isDragged ? '#00B3D8' : '#CCC'
  })
}

export default LabeledTwoThumbs;
