import React from "react";
import { Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import bgImg from "../../media/home/fondo-negro.svg"
import logoMallorca from "../../media/brand/Logo_Mallorca.svg";
import logoIbiza from "../../media/brand/Logo_Ibiza.svg";
import logoMenorca from "../../media/brand/Logo_Menorca.svg";

const Islands = () => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Row className="flex-center" style={styles.bg}>
        <h2 className="text-primary w-100 text-center px-3 text-uppercase">{t('Our locations')}</h2>
        <a rel="canonical" href="/mallorca" className="col-auto my-4">
          <img style={styles.images} src={logoMallorca} alt="logo_home_Mallorca" loading="lazy"/>
        </a>
        <a rel="canonical" href="/ibiza" className="col-auto my-4">
          <img style={styles.images} src={logoIbiza} alt="logo_home_Ibiza" loading="lazy"/>
        </a>
        <a rel="canonical" href="/menorca" className="col-auto my-4">
          <img style={styles.images} src={logoMenorca} alt="logo_home_Menorca" loading="lazy"/>
        </a>
      </Row>
    </Container>
  );
}

const styles = {
  bg: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    gap: '2rem',
    padding: '7rem 3rem'
  },
  images: {
    width: '100%',
    // maxWidth: 300
  }
}

export default Islands;
