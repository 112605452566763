import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';


const Separation = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="py-4 text-center bg-ibiza josefine">
      <Row className="px-3 px-lg-5 mx-lg-3 justify-content-center">
        <Col xs="12" className="px-0 my-4 flex-center">
          <h2 className="text-uppercase">{t('Stay in our properties')}</h2>
        </Col>
        <Col xs="12" lg="5" className="my-4 mx-lg-3 flex-center">
          <p className="h2 text-uppercase" style={{fontSize: '2.5rem'}}>
            <b style={{fontSize: '10rem', lineHeight: .7}}>0
              <span style={{fontSize: '6rem'}}>€</span>
            </b><br/>
            {t('commission in 2 easy steps')}
          </p>
        </Col>
        <Col xs="12" lg="5" className="my-4 mx-lg-3 flex-center">
          <p className="h2 text-uppercase" style={{fontSize: '2.5rem'}}>
            {t('Yes, with us...')}<br/>
            <b>{t('You don\'t have to pay commissions!')}</b>
          </p>
        </Col>
      </Row>
    </Container>
  );
}


export default Separation;
