import React, {useContext, useState, useMemo} from "react";
import { Input, Table } from "reactstrap";
import Loading from "react-loading";

import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";
import usePagination from "../../hooks/usePagination";
import normalize from "../../utils/normalize";
import defaultTemplate from "../utils/templates/default";


const MessagesList = ({messages, isLoading}) => {
  const { t } = useContext(LanguageContext);
  const [search, setSearch] = useState()
  const [openMessage, setOpenMessage] = useState()

  const filteredMessages = useMemo(() => {
    if(!search) return messages
    const normalizedSearch = normalize(search)
    return (messages || []).filter(m => {
      const searchableFields = [m?.title, m?.sentBy?.username, ...(m?.sentTo || []), m?.type]
        .map(f => normalize(f)).join(' ')
      return searchableFields.includes(normalizedSearch)
    })
  }, [messages, search])

  const {
    pageItems,
    PageSelector
  } = usePagination(filteredMessages)

  return (
    <div>
      <div className="d-flex justify-content-between">
        <p className="h2">
          {t('Emails history')} <span className="font-weight-light">({filteredMessages?.length || 0})</span>
        </p>
        <div>
          <Input
            type="text"
            className="customInput"
            value={search}
            onChange={e => setSearch(e?.target?.value || '')}
            placeholder={t('Search') + '...'}
          />
        </div>
      </div>

      <div className="overflow-auto">
        <Table>
          <thead style={{fontSize: '1.5rem'}}>
            <tr>
              <th>{t('Title')}</th>
              {/*<th>{t('Type')}</th>*/}
              <th>{t('Sent by')}</th>
              <th>{t('Sent to')}</th>
              <th className="text-center">{t('Body')}</th>
              <th className="text-right">{t('Date')}</th>
            </tr>
          </thead>
          <tbody style={{fontSize: '1.2rem'}}>
            {(!isLoading && !!filteredMessages?.length) &&
              pageItems.map((message, i) =>
                <MessageRow key={i} message={message} openMessage={() => setOpenMessage(message?.body)}/>)
            }
          </tbody>
        </Table>
      </div>

      {isLoading ?
        <Loading color="black" className="mx-auto" type="bars"/> :
      !filteredMessages?.length &&
        <div className="w-100 text-center pt-4">{t('No entries found')}</div>
      }

      <PageSelector />


      {openMessage &&
        <div
          className="vh-100 w-100 fixed-top"
          style={{paddingTop: 130}}
          onClick={() => setOpenMessage(null)}
        >
          <div
            className="mx-auto mt-3"
            style={{
              position: 'relative',
              maxWidth: '90%', width: 900,
              zIndex: 3999 // Navbar is 4000
            }}
            onClick={e => e.stopPropagation()}
          >
            <i
              className="fas fa-times text-danger position-absolute"
              style={{cursor: 'pointer', top: 15, right: 30}}
              onClick={() => setOpenMessage(null)}
            />
            <div
              style={{ maxHeight: '70vh', overflowY: 'scroll' }}
              title="Email preview"
              dangerouslySetInnerHTML={{__html: defaultTemplate(openMessage)}}
              // style={{ width: '200%', transform: "scale(50%) translate(-50%, -50%)"}}
            />
          </div>
        </div>
        // <div
        //   style={{
        //     position: 'fixed',
        //     top: 150, right: '50%',
        //     transform: 'translateX(50%)',
        //     maxWidth: '90%', width: 900,
        //     zIndex: 3999 // Navbar is 4000
        //   }}
        // >
        //   <div className="position-relative">
        //     <i
        //       className="fas fa-times text-danger position-absolute"
        //       style={{cursor: 'pointer', top: 15, right: 30}}
        //       onClick={() => setOpenMessage(null)}
        //     />
        //     <div
        //       style={{ maxHeight: '70vh', overflowY: 'scroll' }}
        //       title="Email preview"
        //       dangerouslySetInnerHTML={{__html: defaultTemplate(openMessage)}}
        //       // style={{ width: '200%', transform: "scale(50%) translate(-50%, -50%)"}}
        //     />
        //   </div>
        // </div>
      }
    </div>
  )
}


const MessageRow = ({message, openMessage}) => {
  const sentTo = message?.sentTo || []
  const emailsString = sentTo.slice(0, 2).join(', ') + ", ..."
  const emails = sentTo?.length > 3 ? emailsString : sentTo.join(', ')


  return (
    <tr>
      <td className="font-weight-bold">{message?.title}</td>
      {/*<td>{message?.type}</td>*/}
      <td>{message?.sentBy?.username}</td>
      {/*<td title={emails} className="overflow-hidden text-nowrap" style={{textOverflow: 'ellipsis', maxWidth: 400}}>*/}
      <td title={emailsString} style={{maxWidth: 400}}>
        {emails}
      </td>
      <td className="text-center"><i className="fas fa-envelope text-primary p-1" style={{cursor: 'pointer'}} onClick={openMessage}/></td>
      <td className="text-right">{!!message?.createdAt && new Date(message?.createdAt).toLocaleString().split(',')[0]}</td>
    </tr>
  )
}



export default MessagesList;
