import React, {createContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import es from '../../translations/es'
import en from '../../translations/en'
import de from '../../translations/de'
import ru from '../../translations/ru'

export const dictionary = { es, en, de, ru }

const languages = Object.keys(dictionary)
const defaultLang = 'en'


export const LanguageContext = createContext();

const LanguageWrapper = ({ children }) => {
  const { i18n } = useTranslation();


  const [lang, setLang] = useState(i18n.language || defaultLang)


  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])


  const t = key => (
    dictionary[lang]?.[key] ||
    dictionary[defaultLang]?.[key] ||
    key
  )


  return (
    <LanguageContext.Provider
      value={{
        lang,
        changeLang: setLang,
        languages,
        t
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}


export default LanguageWrapper;
