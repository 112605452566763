import React, { PureComponent } from 'react';
import { withTranslation  } from "react-i18next";

class Legal extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid">
        <h3 className="my-4">
          {t("Legal Disclaimer")}
        </h3>
        <p>
          {t("In compliance with the general duty of disclosure established in article 10 of the Spanish Information" +
            " Society Services and E-Commerce Law, the users of this website are informed that the owner of the " +
            "website is Balearic Apartments Rentals S.L., B56502446, C/ Saridakis 24, Apart. 506, 07015 Palma.")}
        </p>
        <h5>{t("Conditions of website use and safety")}</h5>
        <p>
          {t("Users access the website on their own behalf and at their own risk. We cannot guarantee the speed," +
            " uninterrupted access or the absence of viruses on the website. Neither may we be held liable for any" +
            " damages deriving from the use of this website, or for any action performed on the basis of information" +
            " provided on it.")}
        </p>
        <p>
          {t("There is no guarantee of an absence of viruses or other detrimental elements that could cause damage" +
            " or alterations to the computer system, electronic documents or to the files of the user of this" +
            " website. Therefore, there is no liability for any harm and loss that such elements could cause to" +
            " the user or third parties.")}
        </p>
        <h5>
          {t("Intellectual property and copyrights")}
        </h5>
        <p>
          {t("All rights are reserved, including but not limited to any of the copyrights, registered trademarks and " +
            "any other industrial or intellectual property right on this website and its contents." +
            " The use of the rights and properties of this website requires prior and explicit consent" +
            " in writing. No implicit or explicit licence is offered for the use, commercially or otherwise," +
            " of the website without express and prior authorisation.")}
        </p>
        <p>
          {t("We are particularly careful with all photographs of properties used on our web, whose use we in no" +
            " case authorise without prior acceptance in writing.")}
        </p>
        <h5>
          {t("Privacy Policy")}
        </h5>
        <p>
          {t("In this website we will collect your data")}:
        </p>
        <p>
          {t("In the contact form. In this case, we use your data to respond to your request for information on our " +
            "professional real estate services or to establish contact with you in keeping with your indications.")}
        </p>
        <p>
          {t("If you join as a user. Your data enable us to render our services as real estate professionals.")}
        </p>
        <p>
          {t("You may exercise your rights of access, rectification, cancellation and opposition by sending" +
            " a certified letter with the details of your request and photocopy of your National" +
            " Identification Card to C/ Saridakis 24, Apart. 506, 07015 Palma.")}
        </p>
      </div>
    );
  }
}

export default withTranslation ()(Legal);
