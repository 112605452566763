import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

import { IslandContext } from "../../hooks/wrappers/IslandWrapper";


import balearicLogo from "../../media/brand/Logo_Balearic_simple.svg"


const socialNetworks = [
  {
    link: 'https://www.instagram.com/b_a_rentals/',
    icon: <i className="fab fa-instagram-square mx-2" style={{ color: "#eb3187", fontSize: 35 }}/>
  },
  {
    link: 'https://wa.me/34640208483',
    icon: <i className="fab fa-whatsapp-square mx-2" style={{ color: "#008B7D", fontSize: 35 }}/>
  },
  {
    link: 'https://m.facebook.com/Balearic-Apartments-Rentals-409063762856048/',
    icon: <i className="fab fa-facebook-square mx-2" style={{ color: "#3A5CA9", fontSize: 35 }}/>
  },
  // {
  //   link: 'https://twitter.com/B_A_RENTALS',
  //   icon: <i className="fab fa-twitter-square mx-2" style={{ color: "#1A97F0", fontSize: 35 }}/>
  // },
  {
    link: 'https://www.youtube.com/@balearic_apartments_rentals',
    icon: <i className="fab fa-youtube-square mx-2" style={{ color: "#e95950", fontSize: 35 }}/>
  },
  {
    link: 'https://t.me/balearicapartmentsrentals',
    icon: <div className="rounded mx-2" style={{ backgroundColor: "#2AABEE", padding: "3px 5px 3px 4px" }}>
      <i className="fab fa-telegram-plane text-dark" style={{ fontSize: 25 }}/>
    </div>
  }
]


const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation()

  const { islandProps } = useContext(IslandContext)
  const { email } = islandProps

  ReactGA.pageview(location.pathname);

  return (
    <Container fluid tag="footer" className="bg-dark text-white text-center text-lg-left">

      <Row className="py-5 px-2 px-lg-5">

        <Col xs={12} lg={4}>
          <div className="flex-center" style={{cursor: 'pointer'}} onClick={() => window.location = "/"}>
            <img
              style={{ height: 120, maxWidth: '100%' }}
              src={balearicLogo}
              alt="balearic apartment rentals - logo"
              loading="lazy"
            />
          </div>
          <div className="flex-center mt-4">
            {socialNetworks.map(({link, icon}) =>
              <a
                className="p-1"
                rel="nofollow noopener noreferrer"
                href={link}
                target="_blank"
              >{icon}</a>
            )}
          </div>
        </Col>

        <Col xs={12} lg={4} className="d-flex justify-content-center my-5 my-lg-0">
          <div>
            <p className="mb-3">
              <b className="h5">Balearic Apartments Rentals S.L.</b><br/>
              C/ De Saridakis, 24 -Apart.506<br/>
              07015, Palma de Mallorca<br/>
              Illes Balears<br/>
              CIF: B56502446
            </p>
            <a href={"mailto:" + email} className="text-white mb-3 d-block" rel="noopener noreferrer">
              <u>{email}</u>
            </a>
            <a href="tel:+34640208483" className="text-white mb-3 d-block" rel="noopener noreferrer">
              <b>+34 <span style={{fontSize: '1.8rem'}}>640 20 84 83</span></b>
            </a>
          </div>
        </Col>

        <Col xs={12} lg={4} className="d-flex justify-content-center" style={{fontSize: '1.1rem'}}>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column text-uppercase mb-3">
              <Link className="mb-3 text-white" to="/about-us">
                {t("About us")}
              </Link>
              <Link className="mb-3 text-white" to="/contact">
                {t("Contact")}
              </Link>
              <Link className="mb-3 text-white" to="/blog">
                {t("Blog")}
              </Link>
            </div>
            <div className="d-flex flex-column">
              <b className="text-primary mb-3">AYUDA</b>
              <Link className="mb-3 text-white" to="/blog/article/62f0235f441e9b10241d5c20">
                {t("Help for tenants")}
              </Link>
              <Link className="text-white" to="/blog/article/62f01d3c441e9b10241d5bfe">
                {t("Help to owners")}
              </Link>
            </div>
          </div>
        </Col>

      </Row>

      <Row className="border-top border-white justify-content-between py-3 px-4 px-md-6 px-lg-8 px-xl-8">
        <Col xs={12} lg="8" className="d-flex flex-column flex-lg-row align-items-center justify-content-end">
          <Link className="text-white px-2 py-2" to={{ pathname: "/legal" }}>
            {t("Legal Advise")}
          </Link>
          <span className="d-none d-lg-block">·</span>
          <Link className="text-white px-2 py-2" to={{ pathname: "/privacy" }}>
            {t("Privacy Policy")}
          </Link>
          <span className="d-none d-lg-block">·</span>
          <Link className="text-white px-2 py-2" to={{ pathname: "/cookies" }}>
            {t("Cookies")}
          </Link>
        </Col>
        <Col xs={12} lg="auto" className="text-lg-right py-2 order-lg-first mt-3 mt-lg-0">
          @{(new Date()).getFullYear()} BalearicApartments-Rentals
        </Col>
      </Row>

    </Container>
  );
}

export default Footer;
