import React from "react";
import RegisterForm from "./RegisterForm";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// import check from 'icons/checked.svg'
import getModalPrivacityPolitic from "../shared/getModalPrivacityPolitic";
import * as actions from "actions";
import { withTranslation  } from "react-i18next";
// import imgRegister from 'icons/wallpapers/register.jpg'
// import ImgContainer from "../shared/ImgContainer";

class Register extends React.Component {
  state = {
    errors: [],
    redirect: false,
    isVisiblePrivacity: false,
    isAcceptedPrivacity: false,
  };

  togleAcceptPrivacity = () => {
    this.setState({
      isAcceptedPrivacity: !this.state.isAcceptedPrivacity
    });
  };

  toggleVisibleOfPrivacity = () => {
    this.setState({
      isVisiblePrivacity: !this.state.isVisiblePrivacity
    });
  };

  // checks = [
  //   "Manage your leases pursuant to Spanish Urban Leases Law (LAU in its Spanish initials)",
  //   "Posting your apartments is free of charge",
  //   "Advertise your property throughout the world",
  //   "Personal and individual management of your properties and bookings",
  //   "Lease payments guaranteed",
  //   "Transparent management",
  //   "Personalised advice and assistance from our team"
  // ];

  registerUser = userData => {
    const { t, to } = this.props;
    const { action } = this.props;
    if (this.state.isAcceptedPrivacity) {
      toast.info(t("Sending confirmation email"));
      console.log({ t, to, action })
      // actions
      //   .register({ ...userData, role: "customer", redirectTo: to })
      //   .then(() => {
      //     toast.success(t("You will receive an account activation email shortly"));
      //     try {
      //       action();
      //     } catch (e) {
      //
      //     }
      //     this.setState({ redirect: true })
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     toast.error(t("This email already exists"));
      //   });
    } else {
      alert(t("Please accept privacy policies"))
    }
  };

  static defaultProps = {
    full: true,
    redirectTo: true,
    to: null
  };

  render() {
    const { t, full, redirectTo } = this.props;
    const { errors, redirect, isVisiblePrivacity, isAcceptedPrivacity } = this.state;

    if (redirect && redirectTo) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { successRegister: true } }}
        />
      );
    }

    return (
      <section id="register">
        <div className="bwm-form">
          <div className="row mb-5 justify-content-center">
            <div className={full ? "col-md-5" : "col-12"}>
              <div className="bg-white" style={{width: '100%', padding: "50px 20px 100px"}}>
                <h1>{t("Register")}</h1>
                <RegisterForm submitCb={this.registerUser} errors={errors} isAcceptedPrivacity={isAcceptedPrivacity}
                              togleAcceptPrivacity={this.togleAcceptPrivacity}
                              toggleVisibleOfPrivacity={this.toggleVisibleOfPrivacity}/>
                {getModalPrivacityPolitic(t, isVisiblePrivacity, this.toggleVisibleOfPrivacity)}
              </div>
            </div>
            {/* {full &&
            <div style={{ minHeight: "800px" }} className="d-md-block col-12 col-md-7 ml-auto mt-4 mb-5 mt-md-0">
              <ImgContainer img={imgRegister} className="rounded">
                <div className="row flex-column p-4 bg-semiblack text-light">
                  <h2 style={{ fontSize: (t("ietf") === "ru" ? "25px" : "42px"), letterSpacing: "1px" }}
                      className="text-center mb-3">
                    {t("Complete our simple registration process and access a wide range of benefits")}
                  </h2>
                  {this.checks.map((text, key) =>
                    <p key={key} className="d-flex w-100">
                      <img className="mr-2" height="20px" src={check} alt=""/>
                      {t(text)}
                    </p>
                  )}
                </div>
              </ImgContainer>
            </div>} */}

          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation ()(withRouter(Register));
