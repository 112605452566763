import React, { PureComponent } from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation  } from "react-i18next";
import { Redirect } from "react-router-dom";
import user from './../../icons/user.svg'
import axiosService from "services/axios-service";

const axiosInstance = axiosService.getInstance();

class RestorePassw extends PureComponent {

  state = {
    token: "",
    active: false,
    red: "/"
  };

  componentWillMount() {
    const { location } = this.props;
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    const token = searchParams.get("token");
    const redirect = searchParams.get("redirect");
    this.setState({ token, red: redirect });

    axiosInstance.patch("users/active", { token: ("Bearer " + token) }).then(({ data }) => {
      this.setState({active: true})
    });

  }
  render() {
    const { active, token, red } = this.state;
    console.log(token)
    if (token.length <= 0) {
      return <Redirect to={red}/>
    }
    if (active){
      return <Redirect to={red}/>
    }
    return (
      <div className="row justify-content-center">
        <img height="50px" width="50px" src={user} alt=""/>
      </div>
    );
  }
}

export default withTranslation ()(withRouter(RestorePassw));
