import React, {useContext, useState} from "react";
import {Button, Card, CardBody, CardFooter, CardTitle} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import Modal from 'react-responsive-modal';
import {toast} from "react-toastify";
import moment from "moment";

import { deleteRental as deleteRentalRequest } from "../../api/requests";
import { LanguageContext } from "../../hooks/wrappers/LanguageWrapper";

import {textColorFromIsland} from "./RentalCard";
import {pretifyDate} from "../../utils/stuff";


const RentalManageCard = ({ rental, user, afterDelete }) => {
  const history = useHistory();
  const { t } = useContext(LanguageContext);
  const { _id, code, title, createdAt, city, published, bookings, user: rentalUser } = rental
  const [bookingsModalIsOpen, setBookingsModalIsOpen] = useState(false)

  const confirmDelete = () => {
    if(confirm(t("Do you confirm?"))) deleteRental()
  }

  const deleteRental = () => {
    toast.info(t("Eliminating rent..."));
    deleteRentalRequest(_id)
      .then(() => afterDelete())
      .catch(e => toast.error(e.response.data.errors[0].detail))
  }


  const hasPerms = ["super", "admin"].includes(user.role) || user.userId === rental.user._id
  return (
    <Card className="border border-top-0 border-light mw-100" style={{width: 300}}>
      <Link to={`/rentals/${code}`}>
        <CardTitle className='py-3 px-3 bg-dark text-uppercase m-0' style={{fontSize: '1rem'}}>
          <div className={`font-weight-bold mb-2 text-right ${published ? 'text-success' : 'text-warning'}`}>
            {t(published ? 'Published' : 'Unpublished')}
          </div>

          <div className="mb-2 text-white font-weight-bold" style={{fontSize: '1.2rem'}}>{title}</div>

          {city &&
            <div className={`${textColorFromIsland(city?.island)}`} style={{fontSize: '1rem'}}>
              {city.name}, {city.island}
            </div>
          }
        </CardTitle>
      </Link>
      <CardBody className="p-0 d-flex flex-wrap">

        {!!bookings?.length && <>
          <Button color="bwm" className="w-100 rounded-0" onClick={() => setBookingsModalIsOpen(true)}>
            Bookings
          </Button>
          <BookingsModal isOpen={bookingsModalIsOpen} setOpen={setBookingsModalIsOpen} bookings={bookings} />
        </>}

        <Button color="info" className="flex-grow-1 rounded-0" onClick={() => history.push(`/rentals/${_id}/edit`)}>
          {t("Edit")}
        </Button>

        {hasPerms &&
          <Button color="danger" onClick={() => confirmDelete()} className="flex-grow-1 rounded-0">
            {t("Delete")}
          </Button>
        }

      </CardBody>
      <CardFooter className="text-muted text-lowercase" style={{fontSize: '.9rem'}}>
        {t("Created") + " "}
        <span className="text-capitalize font-weight-bold">{moment(createdAt).format("DD/MM/YYYY")}</span>
        <br/>
        {!!rentalUser?.name &&
          <div>
            {t("by") + " "}
            {hasPerms ?
              <Link className="text-capitalize font-weight-bold" to={{ pathname: `/user/${rentalUser?._id}`}}>
                {rentalUser?.name} {rentalUser?.firstSurname} {rentalUser?.secondSurname}
              </Link>
              :
              <span className="text-capitalize font-weight-bold">{rentalUser?.name} {rentalUser?.firstSurname} {rentalUser?.secondSurname}</span>
            }
          </div>
        }
      </CardFooter>
    </Card>
  );
}

const BookingsModal = ({ isOpen, setOpen, bookings }) =>
  <Modal open={isOpen} onClose={() => setOpen(false)} little className="rental-booking-portal">
    <h4 className='modal-title title'>Made Bookings</h4>
    <div className='modal-body bookings-inner-container'>
      {bookings.map((booking, index) =>
        <React.Fragment key={index}>
          <p><span>Date:</span> {pretifyDate(booking.startAt)} - {pretifyDate(booking.endAt)}</p>
          <p><span>Guests:</span> {booking.guests}</p>
          <p><span>Total Price:</span> {booking.totalPrice} $</p>
          { index + 1 !== bookings.length &&
            <hr></hr>
          }
        </React.Fragment>
      )}
    </div>
    <div className='modal-footer'>
      <button type='button' onClick={() => setOpen(false)} className='btn btn-bwm'>Cancel</button>
    </div>
  </Modal>



export default RentalManageCard;
