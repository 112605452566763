import React from "react";
import { Link } from "react-router-dom";
import { pretifyDate } from "utils/stuff";
import { useTranslation, withTranslation } from "react-i18next";
import getRentalDescription from "../../utils/getRentalDescription";

export function BookingCard({ booking, t }) {
  const { i18n } = useTranslation();
  const language = i18n.language
  const dateFormat = language === "es" ? "DD/MM/YYYY" : "YYYY/MM/DD";
  let finalDescription = getRentalDescription(booking.rental.description, language)
  return (
    <div className="col-md-4">
      <div className="card text-center">
        <div className="card-header">
          {booking.rental ? booking.rental.title : t("Deleted RentalDetails")}
        </div>
        <div className="card-block">
          {booking.rental && (
            <p className="card-text booking-desc" style={{ marginBottom: 10 }}>
              {finalDescription}
            </p>
          )}
          {booking.rental && (
            <Link
              rel="canonical"
              className="btn btn-outline-primary mb-3"
              to={`/rentals/${booking.rental.code}`}
            >
              {t("Go to Rental")}
            </Link>
          )}
        </div>
        <div className="card-footer text-muted">
          {t("Created")} {pretifyDate(booking.createdAt, dateFormat)}
        </div>
      </div>
    </div>
  );
}

export default withTranslation ()(BookingCard);
