import React from "react";
import { Container, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, Navigation } from "swiper";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

import useRental from "../hooks/useRental";

import Loading from "../components/shared/Loading"
import PageContainer from "../components/shared/PageContainer";
import RentalMap from "../components/rentalDetails/RentalMap";
import ContactForm from "../components/shared/ContactForm";
import Assets from "../componentsOld/rental/Assets";

import getRentalDescription from "../componentsOld/utils/getRentalDescription"; // TODO

import ownerIcon from "../media/rentalPage/owner.svg";
import personIcon from "../media/rentalPage/person.svg";
import roomIcon from "../media/rentalPage/room.svg";
import calendarIcon from "../media/rentalPage/calendar.svg";
import AvailabilityForm from "../components/rentalDetails/AvailabilityForm";



const RentalDetails = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { code } = useParams();
  const { rental, isLoading } = useRental({code})
  const {
    title,
    assets,
    shared,
    category,
    user,
    city,
    country,
    street,
    minDays,
    maxDays,
    guests,
    bedrooms,
    description
  } = (rental || {})

  let langDescription = description && getRentalDescription(description, i18n.language)

  // if(!isLoading && !rental) return <Redirect to={'/'} />
  return (
    <PageContainer>
      {isLoading ?
        <Loading className="vh-100" />
      :
        <>
          <Container fluid className="pt-5 pb-3 px-4 d-flex justify-content-between align-items-end" style={{maxWidth: 1400}}>
            <div>
              <h1 className="text-primary text-uppercase mb-1" style={{ fontSize: '1.4rem'}}>
                {t('rental')} {t(category)} {t('in')} {city?.name}
              </h1>
              <h2 className="text-uppercase h1 mb-1">
                {t('rental')} {t(category)} {t('in')} {city?.name} {shared && t("shared")}
              </h2>
              {city &&
                <h3 className="m-0" style={{fontSize: '1.2rem', lineHeight: 1 }}>
                  {title}
                </h3>
              }
            </div>
            <div className="flex-center">
              <span style={{ fontSize: '1.1rem'}}>{user?.username}</span>
              <img src={ownerIcon} height="30rem" className="ml-2" alt="owner"/>
            </div>
          </Container>

          <Swiper
            centeredSlides
            slidesPerView="1"
            spaceBetween={10}
            pagination={{
              type: "fraction",
              // clickable: true,
            }}
            navigation
            modules={[Pagination, Navigation]}
            className="mySwiper"
            style={{height: 400}}
          >
            {rental?.image.map((image, index) => {
              const img = image?.image || image
              const name = image?.name || ""
              return (
                <SwiperSlide key={index} style={{width: 'auto', maxWidth: '100vw'}}>
                  <img src={img} style={{maxHeight: 400, objectFit: 'contain', objectPosition: 'center'}} title={name} alt={name} loading="lazy"/>
                </SwiperSlide>
              )
            })}
          </Swiper>


          <Container fluid className="p-0 px-lg-4" style={{fontSize: '1.2rem', maxWidth: 1400}}>
            <Row className="m-0">

              <Col className="px-4 py-5 pl-lg-0 pr-lg-3">
                <div className="d-flex flex-wrap" style={{gap: 20}}>
                  <div>
                    <img src={roomIcon} height="25rem" className="mr-2" alt="roomsIcon"/>
                    {bedrooms} {t("bedrooms")}
                  </div>
                  <div>
                    <img src={personIcon} height="25rem" className="mr-2" alt="maxPeopleIcon"/>
                    {guests} {t("guests")}
                  </div>
                  {(minDays || maxDays) &&
                    <div className="w-100 mt-1">
                      <img src={calendarIcon} height="25rem" className="mr-2" alt="calendarIcon"/>
                      {minDays &&
                        `${t("a minimum of")} ${minDays} ${t("days")} ${maxDays ? t("and") : ""}`}{" "}
                      {maxDays &&
                        `${t("a maximum of")} ${maxDays} ${t("days")}`}
                    </div>
                  }
                </div>

                {langDescription && <>
                  <h2 className="text-uppercase text-primary h1 mt-5">
                    {t('Description')}
                  </h2>
                  <p>
                    {langDescription}
                  </p>
                </>}

                {!!assets?.length && <>
                  <h2 className="text-uppercase text-primary h1 mt-5">
                    {t('Services')}
                  </h2>
                  <Assets assets={assets} />
                </>}
              </Col>

              <Col xs="12" lg="auto" className="py-5 px-4 px-lg-3" style={{backgroundColor: '#F2F2F2'}}>
                <AvailabilityForm rental={rental} />
              </Col>

            </Row>
          </Container>


          {city &&
            <Container fluid className="p-0">
              <RentalMap location={`España, ${city.island}, ${city.name}, ${city?.cp || ''}, ${street}`}/>
            </Container>
          }

          <Container>
            <ContactForm />
          </Container>
        </>
      }
    </PageContainer>
  );
}

export default RentalDetails;
