const template = (body) => {
  return `
    <div
      style="
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align:center;
        padding: 20px 5%;
        background-image: url(https://marton-bwm.s3.eu-west-1.amazonaws.com/893ff2a806efa874bc4a7cf0fcc08565)
      "
    >
      <img
        src="https://marton-bwm.s3.eu-west-1.amazonaws.com/1cf6b3823820f50738dd2db3e7f70496"
        style="
          width: 250px; max-width: 50%;
          margin-bottom: 20px;
        "
      />

      <div style="text-align: left; background: white; padding: 10px 20px; margin-bottom: 20px">
        ${body}
      </div>

      <a href="http://www.BALEARICAPARTMENTS-RENTALS.com" target="_blank" style="font-weight: 700; font-size: 1.3rem; text-decoration: none; color: black">
        www.BALEARICAPARTMENTS-RENTALS.com
      </a>
    </div>
  `;
}

export default template
