import React from "react";
import { withTranslation  } from "react-i18next";

function BwmResError({ errors, t }) {
  const translateError = (error, t) => {
    const reason = error.detail.split("Path `" + error.title + "`")[1];

    if (reason) {
      const capitalTitle =
        error.title.charAt(0).toUpperCase() + error.title.slice(1);
      return `${t("The field")} ${t(capitalTitle)} ${t(reason.trim())}`;
    }
  };

  return (
    errors.length > 0 && (
      <div className="alert alert-danger bwm-res-errors w-100">
        {errors.map((error, index) => (
          <p key={index}> {translateError(error, t)} </p>
        ))}
      </div>
    )
  );
}

export default withTranslation ()(BwmResError);
