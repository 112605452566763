import React from "react";
import { Container } from "reactstrap";

import PageContainer from "../components/shared/PageContainer";
import ProfileForm from "../components/profile/ProfileForm";

const Profile = () => {
  return (
    <PageContainer>
      <Container className="py-5 px-5 mx-auto" style={{fontSize: '1.4rem', lineHeight: 1.1, maxWidth: 1500}}>
        <ProfileForm />
      </Container>
    </PageContainer>
  );
}

export default Profile;
