import React from "react";
import { Field, reduxForm } from "redux-form";
import BwmInput from "../shared/form/BwmInput";
import BwmResError from "../shared/form/BwmResError";
import { withTranslation  } from "react-i18next";

const RegisterForm = ({
                        handleSubmit,
                        pristine,
                        submitting,
                        submitCb,
                        valid,
                        errors,
                        isAcceptedPrivacity = false,
                        togleAcceptPrivacity,
                        toggleVisibleOfPrivacity,
                        t
                      }) => {
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field
        name="username"
        type="text"
        label={t("Username")}
        className="form-control"
        component={BwmInput}
      />
      <Field
        name="email"
        type="email"
        label={t("Email")}
        className="form-control"
        component={BwmInput}
      />
      <Field
        name="password"
        type="password"
        label={t("Password")}
        className="form-control py-2 px-4"
        style={{
          borderRadius: 9999,
          fontSize: '1.2rem'
        }}

        component={BwmInput}
      />
      <Field
        name="passwordConfirmation"
        type="password"
        label={t("Password Confirmation")}
        className="form-control"
        component={BwmInput}
      />
      <div className="form-check mb-3">
        <input onChange={togleAcceptPrivacity}
               checked={isAcceptedPrivacity}
               type="checkbox" className="form-check-input"/>
        <label onClick={toggleVisibleOfPrivacity}
               style={{ cursor: "pointer" }}
               className="form-check-label"
        >{t("I have read and accept the privacy policy")}</label>
      </div>
      <button
        className="btn btn-outline-primary btn-form ml-auto"
        type="submit"
        disabled={!valid || pristine || submitting}
      >
        {t("Register Me")}
      </button>
      <BwmResError errors={errors}/>
    </form>
  );
};

const validate = values => {
  const errors = {};

  if (values.username && values.username.length < 4) {
    errors.username = "Username min length is 4 characters!";
  }

  if (!values.email) {
    errors.email = "Please enter email!";
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = "Please enter password confirmation!";
  }

  if (values.password !== values.passwordConfirmation) {
    errors.password = "Passwords must be the same";
  }

  return errors;
};

export default withTranslation ()(
  reduxForm({
    form: "registerForm",
    validate
  })(RegisterForm)
);
